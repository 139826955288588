import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DraggableList from '../PubblicaLibro/DraggableList';
import axiosInstance from '../../api/interceptor';
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import { saveAs } from 'file-saver';

import { printAndDownloadArticolo, printAndDownloadLibro } from "../PdfGeneration/PDFPrint"


function ModaleUltimePubblicazioni(props) {
  const { openModalUltimePubblicazioni, toggleModalUltimePubblicazioni, selectedPubblication, toggleSpinner, toggleToast } = props;

  const [order, setOrder] = React.useState([]);

  // LIMITA TITOLO A XXX CARATTERI
  function formatTitle(title, limitChar) {
    return title.length > limitChar ? title.substr(0, limitChar) + '...' : title;
  }

  // METODO PER CONVERTIRE HTML DA DB IN STRINGA SENZA TAG
  function stripHtml(html) {
    // Creare un nuovo div
    var temporalDivElement = document.createElement("div");
    // Innestare nel div il contenuto HTML dal db
    temporalDivElement.innerHTML = html;
    // Estrarre il contenuto testuale
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  // CONVERTE DATA DA YYYY-MM-DD a DD/MM/YYYY
  function convertDate(date) {
    return date.split('-').reverse().join('/');
  }

  // GENERAZIONE CONTENUTI

  // ARTICOLO
  function contenutiArticolo() {
    if (selectedPubblication && selectedPubblication.isArticolo) {
      return (<>
        <div className="row margin-top-30">
          <div className="col-md-12 color-main-text" style={{ fontSize: "14px" }}>
            {convertDate(selectedPubblication.dataPubblicazione)}
          </div>
        </div>

        <div className="row margin-top-15">
          <div className="col-md-12 color-main-color text-uppercase">
            <h5 className="mb-0" style={{ fontSize: "22px", fontFamily: "Raleway-Black" }}>{selectedPubblication.titoloArticolo}</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-2 titolo-item" style={{ fontSize: "16px", fontFamily: "OpenSans-Regular" }}>
            <span className="color-azure font-weight-bold">{selectedPubblication.libroNome}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-2 titolo-item" style={{ fontSize: "14px", fontFamily: "OpenSans-Regular" }}>
            <span className="color-azure font-italic">{selectedPubblication.categoriaArticoloRes.nomeCategoria} </span>
            <span className="font-italic">| {selectedPubblication.nomeAutoreArticolo}</span>
          </div>
        </div>

        <div className="row margin-top-15 custom-overflow" style={{ height: '200px', maxHeight: '430px' }}>
          <div className="col-md-12" style={{ fontSize: "14px", fontFamily: "OpenSans-Regular" }}>
            <span className="color-main-text my-3">{formatTitle(stripHtml(selectedPubblication.boxTestoPrimoCompilato.contenutoBoxTesto), 250)}</span>
          </div>
        </div>
      </>)
    }
  }

  // LIBRO
  function contenutilibro() {
    if (selectedPubblication && !selectedPubblication.isArticolo) {
      return (<>

        <div className="row padding-top-40">
          <div className="col-12 margin-bottom-20">
            <h4 className="text-uppercase">
              <i className="fas fa-list color-azure mr-2" /> indice
                </h4>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-12 custom-overflow margin-bottom-40">
            <DraggableList
              items={selectedPubblication.articoliLibro}
              setOrder={setOrder}
              libroPubblicato={true}
            />
          </div>
        </div>

      </>)
    }
  }

  async function printArticolo(id, titolo) {
    const template = selectedPubblication.isArticolo ? props.selectedPubblication.templateModelloLibro : props.selectedPubblication.modelloLibroRes.template
    if (template === "IVASS") {
      await printArticoloPDF()
    }
    else {
      await printArticoloDOC(id, titolo)
    }
  }

  // STAMPARE PUBBLICAZIONE
  async function printArticoloDOC(id, titolo) {
    toggleSpinner();
    let url = selectedPubblication.isArticolo ? `/print/articolo/${id}` : `/print/libro/${id}`;
    await axiosInstance.get(url, { responseType: 'blob' })
      .then(res => {
        let title = titolo.toLowerCase().replace(/ /g, '_'); // creiamo il titolo per il file docx
        saveAs(res.data, `${title}.docx`) // salviamo il file
      })
      .catch(messaggio => {
        toggleToast('e', messaggio);
      })
      .finally(() => {
        toggleSpinner()
      })
  }

  async function printArticoloPDF() {
    toggleSpinner();

    if (selectedPubblication.isArticolo) {
      await printAndDownloadArticolo(selectedPubblication)
        .then(() => { toggleSpinner() })
    }
    else {
      await printAndDownloadLibro(selectedPubblication)
        .then(() => { toggleSpinner() })
    }
  }

  return (
    <Modal size="xl" id="modale-componi-articolo" isOpen={openModalUltimePubblicazioni} toggle={toggleModalUltimePubblicazioni}>
      <ModalHeader toggle={toggleModalUltimePubblicazioni} />
      <ModalBody>
        <div className="row" style={{ height: "700px" }}>
          {/* COPERTINA */}

          {
            !selectedPubblication
              ? <div id="copertina" className="col-md-6" />
              : <div id="copertina" className="col-md-6 d-flex flex-column"
                style={{ backgroundImage: `url("data:image/png;base64, ${selectedPubblication.immagineAnteprima}")`, backgroundSize: "100% 100%" }}>

                <div className="d-flex justify-content-end h-25 padding-right-10 text-right">
                  <p className="margin-top-60 w-75 font-italic" style={{ fontFamily: "OpenSans-Regular", fontSize: '18px', color: '#97a9c9' }} />
                </div>

                <div className="d-flex flex-column h-75 margin-left-40" style={{ maxWidth: '200px', color: '#97a9c9' }}>
                  <span className="text-uppercase font-weight-bold" style={{ fontFamily: "Raleway-Black", fontSize: '28px' }}>{selectedPubblication.nomeModelloLibro}</span>
                  <p className="mt-2 font-italic" style={{ fontFamily: "OpenSans-Regular", fontSize: '18px' }}>{!selectedPubblication.isArticolo ? selectedPubblication.nomeLibro : selectedPubblication.libroNome}</p>
                </div>
              </div>
          }

          {/* CONTENUTO */}
          <div id="contenuto" className="col-md-6">

            {
              !selectedPubblication
                ? (<div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>)
                : selectedPubblication.isArticolo ? contenutiArticolo() : contenutilibro()
            }

            {/* AZIONI */}
            <div style={{ position: 'absolute', bottom: '20px', width: '80%' }}>
              <div className="row justify-content-between">
                <div className="col-md-4">
                  <div className="d-flex action-container justify-content-center cursor-pointer" onClick={toggleModalUltimePubblicazioni}>
                    <i className="fas fa-times-circle color-rosso mr-1" aria-label="Modifica" /><small className="font-italic">Chiudi</small>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex action-container justify-content-center cursor-pointer"
                    onClick={() => printArticolo(selectedPubblication.id, (selectedPubblication.isArticolo ? selectedPubblication.titoloArticolo : selectedPubblication.nomeLibro))}>
                    <i className="fas fa-file-download color-rosso mr-1" aria-label="download" /><small className="font-italic">Download</small>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="d-flex action-container justify-content-center cursor-pointer">
                  <i className="fas fa-print color-rosso mr-1" aria-label="print" /><small className="font-italic">Stampa</small>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
}

export default connect(null, { toggleSpinner, toggleToast })(ModaleUltimePubblicazioni);