import { GET_CATEGORIE, ADD_CATEGORIE } from "../actions/categorie";

const initialState = [];

const categorie = (state=initialState, action) => {
  switch(action.type) {
    case GET_CATEGORIE:
      return action.payload;
    case ADD_CATEGORIE:
      return [...state, action.payload]
    default:
      return state
  }
}

export default categorie;