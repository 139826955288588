import React from 'react';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Banner from "../Banner/Banner";
import {getRoutes} from "../../routing/utils";

function Layout(props) {
    const {user} = props;

    return (
        <div id="layout-container">
            <Header/>
            <Banner/>
            <div id="main-container">
                {getRoutes(user)}
            </div>
            <Footer/>
        </div>
    );
}

export default Layout;
