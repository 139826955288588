import React from 'react';
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import Pagination from "../../components/Pagination/Pagination";
import FormModello from "./form/FormModello";
import axiosInstance from "../../api/interceptor";
import Swal from 'sweetalert2';
import TooltipWarning from "./components/Tooltip/Tooltip";
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';

const bootstrapSpinner = (<div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>)

const copertineResource = `/copertine`;
const modelliResource = id => `/modellilibro/${id}`;
const categorieResource = '/categorie/elenco';

const header = [
  { value: 'copertina', displayValue: 'Copertina' },
  { value: 'nomeModelloLibro', displayValue: 'Nome del modello' },
  { value: 'categorieAssegnate', displayValue: 'Categorie assegnate' },
  { value: 'azioni', displayValue: 'Azioni' },
]

const actions = {
  setState: 'setState',
  setModelli: 'setModelli',
  setCurrentPage: 'setCurrentPage',
  setTotalItems: 'setTotalItems',
  setTotalPages: 'setTotalPages',
  setItemsPerPage: 'setItemsPerPage',
  setSortField: 'setSortField',
}

const initialState = {
  modelli: [],
  modelliPerPage: 10,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  sortField: 'nomeModelloLibro',
  sortDirection: 'desc'
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.setState: {
      const { modelli, totalItems, totalPages } = action.value;
      return {
        ...state,
        modelli,
        totalItems,
        totalPages
      }
    }
    case actions.setItemsPerPage: {
      return {
        ...state,
        modelliPerPage:
          action.value,
        currentPage: 0
      }
    }
    case actions.setCurrentPage: {
      return {
        ...state, currentPage: action.value
      }
    }
    case actions.setSortField: {
      return {
        ...state,
        sortField: action.field,
        sortDirection: action.direction
      }
    }
    default:
      return state;
  }
}

const GestioneModelliLibro = props => {

  const [collapsed, setCollapse] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [selectedModello, setSelectedModello] = React.useState(null);
  const [copertine, setCopertine] = React.useState(null);

  const [categorieDisponibili, setCategorieDisponibili] = React.useState(false);

  // Gestione visiva del loading di modelli di libri
  const [msgModelliLibri, setMsgModelliLibri] = React.useState(bootstrapSpinner);

  //  TOOLTIP
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const handleTooltip = () => setTooltipOpen(!tooltipOpen);
  //  TOOLTIP

  const { toggleToast, toggleSpinner } = props;

  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState)
  const paginate = pageNumber => dispatch({ type: actions.setCurrentPage, value: pageNumber });
  const setModelliPerPage = upp => dispatch({ type: actions.setItemsPerPage, value: upp });

  const getSortParams = () => {
    let params = `/modellilibro`;
    const {
      modelliPerPage, currentPage, sortField, sortDirection
    } = state;
    params += `?size=${modelliPerPage}&page=${currentPage}&sort=${sortField},${sortDirection}`;
    return params;
  }

  const setState = values => {
    const { content, totalElements, totalPages } = values;
    dispatch({
      type: actions.setState,
      value: {
        modelli: content,
        totalItems: totalElements,
        totalPages: totalPages,
      }
    })
  }

  /**
   * Metodo delete
   * @param id
   * @returns {Promise<void>}
   */
  const deleteModelloLibroById = async (id, nomeModello) => {
    toggleSpinner();
    await axiosInstance.delete(modelliResource(id))
      .then((r) => {
        if (r && r.status === 200) {
          setUpdate(!update)
          toggleToast('s', `Modello "${nomeModello}" eliminato con successo.`)
        }
      })
      .catch(e => {
        e ?
          toggleToast('e', e) :
          console.log(e)
      })
      .finally(() => toggleSpinner())
  }

  const confirmDelete = (id, titolo) => {
    Swal.fire({
      title: "Attenzione!",
      text: `Stai cancellando il modello di libro "${titolo}"`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText:
        `<i class="fas fa-check" style="font-family: 'Font Awesome 5 Free'" aria-label="Conferma"/>
            <span style="font-family: 'OpenSans-Regular'">Conferma</span>`,
      confirmButtonAriaLabel: 'Conferma',
      cancelButtonText:
        `<i class="fa fa-times" style="font-family: 'Font Awesome 5 Free'" aria-label="Annulla"/>
            <span style="font-family: OpenSans-Regular">Annulla</span>`,
      cancelButtonAriaLabel: 'Annulla',
      preConfirm: () => deleteModelloLibroById(id, titolo)
    })
  }

  /**
   * Get all categorie
   * @returns {Promise<void>}
   */
  const getCategorieData = async () => {
    await axiosInstance.get(categorieResource)
      .then((r) => {
        if (r && r.status === 200) {
          //  Filtro le categorie disponibili e torno un booleano se esistono
          setCategorieDisponibili(r.data.filter(c => !c.categoriaConModello).length > 0)
        }
      })
      .catch(({ messaggio }) => toggleToast('e', messaggio))
  }

  /**
   * Get all copertine
   * @returns {Promise<void>}
   */
  const getCopertine = async () => {
    await axiosInstance.get(copertineResource)
      .then(({ status, data }) => {
        if (status && status === 200) setCopertine(data)
      })
      .catch(({ messaggio }) => toggleToast('e', messaggio))
  }

  /**
   * Get all modelliLibro
   * @returns {Promise<void>}
   */
  const getModelliLibro = async () => {
    await axiosInstance.get(getSortParams())
      .then(({ status, data }) => {
        if (status && status === 200) setState(data)
      })
      .catch(({ messaggio }) => toggleToast('e', messaggio))
  }

  /**
   * Elenco di get che servono all'inizializzazione del componente
   * @returns {Promise<void>}
   */
  const getData = async () => {
    toggleSpinner();
    await Promise.all([
      getCopertine(),
      getModelliLibro(),
      getCategorieData()
    ]).finally(() => {
      setMsgModelliLibri('Nessun modello di libro')
      toggleSpinner()
    })
  }

  React.useEffect(() => {
    getData()
  }, [state.modelliPerPage, state.currentPage, state.sortField, state.sortDirection, update]); // eslint-disable-line

  const setSelectedModelloAndCollapse = (modello, collapseMenu) => {
    setSelectedModello(modello);
    if (collapseMenu) setCollapse(!collapsed);
  }

  const sortIcon = field => {
    if (field !== "nomeModelloLibro") return
    return <i
      className={`fas ${
        field !== state.sortField ? 'fa-sort' :
          state.sortField && state.sortDirection === 'asc' ?
            'fa-caret-up' : 'fa-caret-down'
        } cursor-pointer`}
      onClick={() => {
        dispatch({
          type: actions.setSortField,
          field: field,
          direction: field !== state.sortField ? 'asc' :
            state.sortDirection === 'asc' ? 'desc' : 'asc'
        })
      }}
    />
  }

  const handlePagetitle = () => {
    return collapsed && selectedModello ? "modifica modello" :
      collapsed && !selectedModello ? "crea modello" : "gestione modelli"
  }

  const tooltipBtnId = "idUsedByTooltip";

  // Verfica se tutte le copertine sono associate
  function checkCopertineAssociate() {
    if(copertine) return copertine.every(el => el.copertinaAssociata)
  }

  return (
    <div id="gestione-modelli" className="container">
      <BackToPrevious goBack={() => props.history.goBack()} />
      {
        collapsed ?
          <FormModello
            selectedModello={selectedModello}
            removeSelectedModello={() => setSelectedModello(null)}
            toggleForm={() => setCollapse(!collapsed)}
            updateTabella={() => setUpdate(!update)}
            copertine={copertine}
          /> :
          <>
            <div className="title-gestione-modelli">
              <div className="title">
                <i className="fas fa-th" />
                {handlePagetitle()}
                <div
                  id={tooltipBtnId}//ID usato dal tooltip come "targetId"
                  className={`btn btn-sm btn-azure float-right padding-right-40 padding-right-40`}
                  style={categorieDisponibili && !checkCopertineAssociate() ? null : { opacity: 0.5 }}
                  onClick={() => {
                    if (!categorieDisponibili || checkCopertineAssociate()) return;
                    if (selectedModello) setSelectedModello(null);
                    setCollapse(!collapsed);
                  }}>
                  <i className="fas fa-plus" /> Crea nuovo modello</div>
              </div>
              {
                (!categorieDisponibili || checkCopertineAssociate()) && (
                  <TooltipWarning
                    targetId={tooltipBtnId}
                    open={tooltipOpen}
                    toggle={handleTooltip}
                    placement="left"
                    text={checkCopertineAssociate() ? "Non si possono più creare nuovi modelli, il numero limite di 5 modelli è stato raggiunto" : "Crea prima una categoria, non ci sono categorie disponibili per un nuovo modello di libro"}
                  />)
              }
            </div>
            {
              state.modelli && state.modelli.length > 0 ?
                <>
                  <table
                    id="tabella-modelli"
                    className="table table-sm table-striped margin-top-30">
                    <thead className="bg-main-color text-center color-bianco">
                      <tr>
                        {header && header.map((h, i) => {
                          return <th key={i}>{h.displayValue.toUpperCase()} {sortIcon(h.value)}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {
                        React.Children.toArray(state.modelli.map(modello => {
                          const { categorie, nomeModelloLibro, copertina: { immagine } } = modello;
                          return <tr>
                            {/*COPERTINA*/}
                            <td className="align-middle">
                              <img src={`data:image/png;base64, ${immagine}`} alt="Immagine di copertina" />
                            </td>
                            {/*NOME*/}
                            <td className="align-middle">
                              {nomeModelloLibro}
                            </td>
                            {/*CATEGORIE ASSEGNATE*/}
                            <td className="align-middle mw-500px">
                              {
                                categorie && categorie.length > 0 ?
                                  categorie.map((cat, i) => {
                                    return (
                                      <span
                                        key={i}
                                        style={categorie.length > 3 ? { fontSize: '14px' } : null}
                                      >
                                        {`${cat.nomeCategoria} ${i !== categorie.length - 1 ? ' - ' : ''}`}
                                      </span>
                                    )
                                  }) : "Nessuna"
                              }
                            </td>
                            <td className="align-middle">
                              <div className="row d-flex justify-content-center">
                                <div className="col-3 padding-8">
                                  <i className="fas fa-pen color-main-color cursor-pointer mr-2"
                                    aria-label="Modifica"
                                    onClick={() => {
                                      if (collapsed) {
                                        setSelectedModello(modello)
                                      } else setSelectedModelloAndCollapse(modello, true)
                                    }}
                                  />
                                </div>
                                <div className="col-3 padding-8">
                                  <i className="fas fa-times color-rosso cursor-pointer"
                                    aria-label="Cancella"
                                    onClick={() => {
                                      confirmDelete(modello.id, nomeModelloLibro)
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        }))}
                    </tbody>
                  </table>
                  <Pagination
                    itemsPerPage={state.modelliPerPage}
                    totalItems={state.totalItems}
                    currentPage={state.currentPage}
                    totalPages={state.totalPages}
                    setItemsPerPage={setModelliPerPage}
                    paginate={paginate}
                  />
                </> : <div className="d-flex justify-content-center align-items-center my-5">
                  {msgModelliLibri}
                </div>
            }
          </>
      }
    </div>
  );
};

function mdtp(dispatch) {
  return {
    toggleToast: (s, m) => dispatch(toggleToast(s, m)),
    toggleSpinner: () => dispatch(toggleSpinner())
  }
}

export default connect(null, mdtp)(GestioneModelliLibro);
