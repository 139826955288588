import axiosInstance from "../../api/interceptor";
import { toggleSpinner, toggleToast } from "./general";

import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT
} from '../actions/auth';

export const authStart = () => {
  return {
    type: AUTH_START,
    isLoading: true
  };
};

/**
 *
 * @param user
 * @returns {{type: string, error: null, user: *}}
 */
export const authSuccess = (user) => {
  return {
    type: AUTH_SUCCESS,
    user,
    error: null,
    isLoading: false
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error,
    isLoading: false
  };
};

export const logout = () => {
  sessionStorage.removeItem('expiration');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('path');
  return {
    type: AUTH_LOGOUT
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

// TODO: da cancellare quando ci saranno le chiamate BE
/*
export const tempAuth = user => {
    return dispatch => {
        dispatch(authStart());
        dispatch(toggleSpinner());

        const oreExpiration = 12;
        const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
        sessionStorage.setItem('token', btoa(JSON.stringify(user)));
        sessionStorage.setItem('expiration', expirationDate);
        dispatch(toggleSpinner());
        dispatch(authSuccess(user));
    }
};
*/

export const auth = (email, password, isSignup) => {
  return dispatch => {
    dispatch(authStart());
    dispatch(toggleSpinner());

    const authData = {
      username: email,
      password
    };

    axiosInstance.get('/auth/me', { auth: authData })
      .then(({ data }) => {
        const oreExpiration = 12;
        const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);

        const token = btoa(email + ':' + password);
        sessionStorage.setItem('token', btoa(JSON.stringify({ ...data, token })));
        sessionStorage.setItem('expiration', expirationDate);
        dispatch(toggleSpinner());
        dispatch(authSuccess(data));
      })
      .catch(e => {
        const { messaggio } = e;
        dispatch(authFail(messaggio));
        dispatch(toggleSpinner());
        /**
         * toggleToast: mostra un toast con un determinato messaggio.
         * Per veder come funziona guardate la funzione toggleToast prensente in questo file src/store/actionCreators/general.js
         * @param tipo: @string definisce il tipo di toast da lanciare: 'e' = errore => toast rosso, 's' = successo => toast verde
         * @param messaggio: @string definisce il messaggio mostrato nel toast
         */
        dispatch(toggleToast('e', messaggio || 'Se hai già attivato il tuo account controlla le credenziali'));
      });
  };
};

export const authCheckState = () => {
  authStart();

  return dispatch => {
    const token = sessionStorage.getItem('token') ? JSON.parse(atob(sessionStorage.getItem('token'))).token : null;
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(sessionStorage.getItem('expiration'));
      if (expirationDate.getTime() <= new Date().getTime()) {
        dispatch(logout());
      } else {
        const user = atob(sessionStorage.getItem('token'));
        dispatch(authSuccess(JSON.parse(user)));
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
      }
    }
  };
};
