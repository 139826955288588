import React from 'react';
import Carousel from 'nuka-carousel'
import { toggleSpinner, toggleToast } from '../../store/actionCreators/general';
import axiosInstance from '../../api/interceptor';
import { connect } from 'react-redux';


const CustomSlider = ({ data, toggleModalUltimePubblicazioni, setSelectedPubblication, toggleSpinner, toggleToast }) => {

  // GET INFO SULLA PUBBLICAZIONE DAL DB
  async function getPubblicazione(item) {
    toggleSpinner();
    let url = !item.nomeAutoreArticolo ? `/libri/${item.idPubblicazione}` : `/articoli/${item.idPubblicazione}`;
    await axiosInstance.get(url)
      .then(res => {
        if (res && res.status === 200) {
          setSelectedPubblication(
            {
              ...res.data,
              isArticolo: item.nomeAutoreArticolo ? true : false,
              immagineAnteprima: item.immagineCopertinaLibro,
              nomeAutoreArticolo: item.nomeAutoreArticolo,
              nomeModelloLibro: item.nomeModelloLibro
            });
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => {
        toggleSpinner()
      })
  }

  // FETCH DELLA PUBBLICAZIONE E APERTURA DELLA MODALE COI DETTAGLI
  function vediPubblicazione(pubblicazione) {
    getPubblicazione(pubblicazione);
    toggleModalUltimePubblicazioni()
  }

  // CONVERTE DATA DA YYYY-MM-DD a DD/MM/YYYY
  function convertDate(date) {
    return date.split('-').reverse().join('/');
  }

  // LIMITA TITOLO A XXX CARATTERI
  function formatTitle(title, limitChar) {
    return title.length > limitChar ? title.substr(0, limitChar) + '...' : title;
  }

  // METODO PER CONVERTIRE HTML DA DB IN STRINGA SENZA TAG
  function stripHtml(html) {
    // Creare un nuovo div
    var temporalDivElement = document.createElement("div");
    // Innestare nel div il contenuto HTML dal db
    temporalDivElement.innerHTML = html;
    // Estrarre il contenuto testuale
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  return (
    <Carousel
      autoplay
      wrapAround
      renderCenterLeftControls={({ previousSlide }) => (
        <button aria-label="previous" type="button" style={{border: '0px', background: 'rgba(0, 0, 0, 0.4)', padding: '10px', cursor: 'pointer'}} onClick={previousSlide}><i className="fas fa-chevron-left color-bianco font-weight-bolder"></i></button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button aria-label="previous" type="button" style={{border: '0px', background: 'rgba(0, 0, 0, 0.4)', padding: '10px', cursor: 'pointer'}} onClick={nextSlide}><i className="fas fa-chevron-right color-bianco font-weight-bolder"></i></button>
      )}
    >

      {
        React.Children.toArray(data.map(el => (
          <div className={`container-slider ${el.coloreCopertinaLibro}`}>
            <div className="container d-flex align-items-center">
              <div className="left-content">
                <p>{convertDate(el.dataPubblicazione)}</p>
                <h1 className="color-banner-input">{el.titoloPubblicazione}</h1>
                <div className="titolo-item">
                  <span className="titolo">{el.nomeCategoriaArticolo ? el.nomeCategoriaArticolo : el.nomeModelloLibro}</span>
                  { !el.nomeAutoreArticolo ? null : <span className="autore">{el.nomeAutoreArticolo}</span> }
                </div>
                <p>{!el.boxTestoArticolo ? null : formatTitle(stripHtml(el.boxTestoArticolo), 150)}</p>
                <div className="go-to cursor-pointer" onClick={() => vediPubblicazione(el)}>Vedi pubblicazione {'>'}</div>
              </div>
            </div>
          </div>
        )))
      }

    </Carousel>
  )
}

export default connect(null, { toggleSpinner, toggleToast })(CustomSlider);