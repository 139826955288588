import React from 'react';

const BackToPrevious = props => {
  return (
    <div className="row">
      <div className="col-md-6">
        <small className="font-italic mb-4 text-secondary float-left mt-4 go-to color-main-color cursor-pointer"
          onClick={props.goBack}>
          <i className="fas fa-chevron-circle-left" /> Torna alla pagina precedente
          </small>
      </div>
    </div>
  );
}

export default BackToPrevious;