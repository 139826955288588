import axiosInstance from "../../api/interceptor";
import PDFGenerator from "./PDFGenerator"
import download from "downloadjs";
import moment from 'moment';
import 'moment/locale/it';

function getSemaforo(livelliImportanza, semaforoId) {
    const livello = livelliImportanza.find(l => l.id === semaforoId)
    return livello.label.replaceAll(" ", "")
}

function getIsCovid(tematiche, tematicaId) {
    const tematica = tematiche.filter(t => t.label === "COVID-19");
    return (tematica !== undefined) && (tematicaId.includes(tematica[0]?.id));
}

function cleanText(testo) {
    testo = testo.replaceAll("<p>&nbsp;</p>", "")
    testo = testo.replaceAll("<p><br></p>", "")
    testo = testo.replaceAll("</p>", "</p>|||para|||")
    var paragraphs = testo.split("|||para|||")

    var cleanParagraphs = paragraphs.map(p => {
        var tempDiv = document.createElement("div");
        tempDiv.innerHTML = p;
        return tempDiv.textContent;
    })
    cleanParagraphs = cleanParagraphs.filter(p => p && p.trim() !== "")

    return cleanParagraphs.join("\n\n");
}

async function getCorrelati(listaSlim, listaIdArticoli) {
    if (listaIdArticoli.length === 0) return []

    let correlati = listaSlim
        .filter(a => listaIdArticoli.includes(a.idArticolo))
        .map(a => ({ titoloArticolo: a.titoloArticolo, idArticolo: a.idArticolo }))

    const promises = correlati.map(c => {
        return axiosInstance.get("/articoli/" + c.idArticolo)
    })

    correlati = await Promise.all(promises).then(responses => {
        return responses.map(r => {
            const data = r.data
            return {
                idArticolo: data.id,
                titoloArticolo: data.titoloArticolo,
                isIVASS: data.templateModelloLibro === "IVASS"
            }
        })
    })

    return correlati;
}

async function convertiLibro(libroOriginale) {
    var libroConvertito = {
        titolo: libroOriginale.nomeLibro,
        sottoTitolo: "",
        articoli: []
    }

    const tematiche = await axiosInstance.get('/tematiche-articolo').then(r => r.data)
    const livelliImportanza = await axiosInstance.get('/livelli-importanza-articolo').then(r => r.data)
    const listaArticoliSlim = await axiosInstance.get('/articoli/slim').then(r => r.data)

    libroConvertito.articoli = await Promise.all(libroOriginale.articoliLibro.map(async articolo => {
        return {
            idArticolo: articolo.id,
            titolo: articolo.titoloArticolo,
            statoAggiornamento: !articolo.statoAggiornamento ? '' : articolo.statoAggiornamento.id === "NEW" ? "Nuovo" : "Aggiornato",
            categoria: articolo.categoriaArticoloRes.nomeCategoria,
            semaforo: getSemaforo(livelliImportanza, articolo.livelloImportanzaArticoloId),
            isCovid: getIsCovid(tematiche, articolo.tematicaArticoloId),
            contenuto: cleanText(articolo.boxTesto[0].contenutoBoxTesto),
            contenuto_ivass: cleanText(articolo.boxTesto[1].contenutoBoxTesto),
            correlati: await getCorrelati(listaArticoliSlim, articolo.listaArticoliStessoLibro),
            criticita: await getCorrelati(listaArticoliSlim, articolo.listaArticoliAltriLibri),
            referenti: articolo.referenti,
            dataAggiornamento: moment(articolo.dataAggiornamento).format("LL"),
            dataCommentiAnia: !articolo.dataCommentiAnia ? "" : `- ${moment(articolo.dataCommentiAnia).format("LL")}`,
            dataCommentiIvass: !articolo.dataCommentiIvass ? "" : `- ${moment(articolo.dataCommentiIvass).format("LL")}`
        }
    })).then(articoliConvertiti => { return articoliConvertiti })

    return libroConvertito
}

export async function convertiArticolo(articolo) {
    const tematiche = await axiosInstance.get('/tematiche-articolo').then(r => r.data)
    const livelliImportanza = await axiosInstance.get('/livelli-importanza-articolo').then(r => r.data)
    const listaArticoliSlim = await axiosInstance.get('/articoli/slim').then(r => r.data)

    return {
        idArticolo: articolo.id,
        titolo: articolo.titoloArticolo,
        statoAggiornamento: !articolo.statoAggiornamento ? '' : articolo.statoAggiornamento.id === "NEW" ? "Nuovo" : "Aggiornato",
        categoria: articolo.categoriaArticoloRes.nomeCategoria,
        semaforo: getSemaforo(livelliImportanza, articolo.livelloImportanzaArticoloId),
        isCovid: getIsCovid(tematiche, articolo.tematicaArticoloId),
        contenuto: cleanText(articolo.boxTesto[0].contenutoBoxTesto),
        contenuto_ivass: cleanText(articolo.boxTesto[1].contenutoBoxTesto),
        correlati: getCorrelati(listaArticoliSlim, articolo.listaArticoliStessoLibro),
        criticita: getCorrelati(listaArticoliSlim, articolo.listaArticoliAltriLibri),
        referenti: articolo.referenti,
        dataAggiornamento: moment(articolo.dataAggiornamento).format("LL"),
        dataCommentiAnia: !articolo.dataCommentiAnia ? "" : `- ${moment(articolo.dataCommentiAnia).format("LL")}`,
        dataCommentiIvass: !articolo.dataCommentiIvass ? "" : `- ${moment(articolo.dataCommentiIvass).format("LL")}`
    }
}

export async function printAndDownloadLibro(selectedLibro) {
    const libroConvertito = await convertiLibro(selectedLibro)

    const pdfGenerator = new PDFGenerator(libroConvertito)
    await pdfGenerator.initializePDFDocument()
    await pdfGenerator.createCopertina()
    await pdfGenerator.createLegenda()
    await pdfGenerator.createArticoli()
    await pdfGenerator.createPuntiChiusi()
    await pdfGenerator.createIndice()
    await pdfGenerator.createDistribuzione()

    const pdfBytes = await pdfGenerator.getPdfBytes()
    download(pdfBytes, selectedLibro.nomeLibro + ".pdf", "application/pdf");
}

export async function printAndDownloadArticolo(articolo) {
    const articoloConvertito = await convertiArticolo(articolo)
    const pdfGenerator = new PDFGenerator({})
    await pdfGenerator.initializePDFDocument()
    await pdfGenerator.createSingleArticoloPage(articoloConvertito)

    const pdfBytes = await pdfGenerator.getPdfBytes()
    download(pdfBytes, articolo.titoloArticolo + ".pdf", "application/pdf");
}