import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import logo_ania from "../../assets/img/logo/logo.png";
import axiosInstance from "../../api/interceptor";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";

const activationURL = "/auth/password-reset-token"

const AccountActivation = props => {

  const {
    match: {params: {token}},
    toggleSpinner,
    toggleToast
  } = props;

  const [error, setError] = React.useState('');

  const [success, setSuccess] = React.useState(false);

  const [p1Visible, setVisibleP1] = React.useState(false);
  const [p2Visible, setVisibleP2] = React.useState(false);

  const [form, setForm] = React.useState({
    password1: {
      value: '',
      error: false,
    },
    password2: {
      value: '',
      error: false
    }
  });

  const handleChange = e => {
    const {name, value} = e.target;
    setForm(state => ({
      ...state,
      [name]: {
        value: value,
        error: value === ""
      }
    }))
  }

  const getErrorMessage = () => <small style={{
    color: 'red',
    fontSize: '12px',
  }}>{error}</small>

  const checkValidity = () => {
    const {password1, password2} = form;
    let error;
    if (password1.value === "") {
      error = true;
      setForm(state => ({
        ...state,
        password1: {
          value: state.password1.value,
          error: true
        }
      }))
      setError('Campo obbligatorio')
    }
    if (password2.value === "") {
      error = true;
      setForm(state => ({
        ...state,
        password2: {
          value: state.password2.value,
          error: true
        }
      }))
      setError('Campo obbligatorio')
    }
    if (!error) {
      if (password1.value !== password2.value) {
        setForm(state => ({
          ...state,
          password2: {
            value: state.password2.value,
            error: true
          }
        }))
        setError('I valori devono essere uguali')
      } else {
        handleSubmit()
      }
    }
  }

  const handleSubmit = () => {
    toggleSpinner();
    axiosInstance.post(activationURL, {
      token,
      newPassword: form.password2.value
    })
      .then(r => {
        if (r && r.status === 200) {
          setSuccess(true)
        }
      })
      .catch(e => toggleToast('e', 'La password non rispetta i criteri di validazione. Si prega di reimpostarla'))
      .finally(() => toggleSpinner())
  }

  return (
    <div id="account-activation-container">
      <div className="form">
        <img src={logo_ania} alt="logo_ania"/>
        {
          !success ?
            <>
              <div className="form-group">
                <label htmlFor="Password">Password</label>
                <div className="input-group">
                  <input
                    name="password1"
                    type={!p1Visible ? "password" : "text"}
                    className={!form.password1.error ? "form-control" : "form-control not-valid"}
                    onChange={handleChange}
                    value={form.password1.value}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text color-main-color">
                      {
                        !p1Visible ?
                          <i className="fas fa-eye-slash"
                             onClick={() => setVisibleP1(true)}
                          /> :
                          <i className="fas fa-eye"
                             onClick={() => setVisibleP1(false)}
                          />
                      }
                    </span>
                  </div>
                </div>
                {form.password1.error ? getErrorMessage() : null}
              </div>

              <div className="form-group">
                <label htmlFor="Conferma password">Conferma password</label>
                <div className="input-group">
                  <input
                    name="password2"
                    type={!p2Visible ? "password" : "text"}
                    className={!form.password2.error ? "form-control" : "form-control not-valid"}
                    onChange={handleChange}
                    value={form.password2.value}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text color-main-color">
                      {
                        !p2Visible ?
                          <i className="fas fa-eye-slash"
                             onClick={() => setVisibleP2(true)}
                          /> :
                          <i className="fas fa-eye"
                             onClick={() => setVisibleP2(false)}
                          />
                      }
                    </span>
                  </div>
                </div>
                {form.password2.error ? getErrorMessage() : null}
              </div>
              <p className="form-message">
                La password deve essere composta da almeno 8 caratteri e contenere almeno una
                lettera maiuscola, una minuscola, un numero e un carattere speciale (~!@#$%^{'&'}*()_\\-).
              </p>
              <button
                onClick={checkValidity}
                className="btn btn-azure btn-block"
              >CONFERMA
              </button>
            </> :
            <>
              <div className="d-flex justify-content-center align-items-center my-5">
                <i className="fas fa-check-circle fa-5x"/>
              </div>
              <div className="d-flex justify-content-center align-items-center text-center">
                <p>Account attivato con successo! <br/> Procedi con il <Link to="/login">login.</Link></p>
              </div>
            </>
        }

      </div>
    </div>

  );
};

function mapDispatchToProps(dispatch) {
  return {
    toggleToast: (s, m) => dispatch(toggleToast(s, m)),
    toggleSpinner: () => dispatch(toggleSpinner())
  }
}

export default connect(null, mapDispatchToProps)(AccountActivation);
