export const statoPubblicazioneLibro = {
  PUBBLICATO: 'PUBBLICATO',
  IN_LAVORAZIONE: 'IN_LAVORAZIONE'
}
export const statoPubblicazioneArticolo = {
  BOZZA: 'BOZZA',
  ATTESA_PUBBLICAZIONE: 'ATTESA_PUBBLICAZIONE',
  PUBBLICATO: 'PUBBLICATO',
}

export const tipoUtente = {
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER'
};

export const enumImportanza = {
  UI: 'b06ce495-2486-4cb1-adc5-28322bc533ec', //'Urgente Importante',
  UNI: 'd2971126-2747-4cec-aff6-22f2bd2b3300', //'Urgente Non Importante',
  NUI: 'bad396aa-5f07-4200-b186-bc4be98a3e95', //'Non Urgente Importante',
  NUNI: '9ff23487-a997-4a3f-95da-db66e246f5a1', //'Non Urgente Non Importante',
  C: '75939501-fb86-4538-993b-8ed75494a12d' //'Chiuso'
};

export const enumTemplate = {
  CRITICITA_PROPOSTE: 'CRITICITA_PROPOSTE',
  IVASS: 'IVASS'
};