import React, {useState} from 'react';
import ForgotPasswordMailSenderForm from "./forms/ForgotPasswordMailSenderForm";
import LoginForm from "./forms/LoginForm";

const LoginContainer = () => {
  const [formType, setFormType] = useState('login');

  return (
    <div id="login-container">
      <div className="form padding-35">
        {
          formType === 'login' ?
            <LoginForm setFormType={setFormType}/> :
            <ForgotPasswordMailSenderForm setFormType={setFormType}/>
        }
      </div>
    </div>
  );
};

export default LoginContainer;
