import { FILTER_ARTICOLI } from "../actions/filterArticoliParams";

const initialState = '';

const filterArticoliParams = (state=initialState, action) => {
  switch(action.type) {
    case FILTER_ARTICOLI:
      return action.payload;
    default:
      return state
  }
}

export default filterArticoliParams;