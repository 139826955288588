import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { scrollTop } from '../../routing/utils';
import CustomSlider from './CustomSlider';
import axiosInstance from '../../api/interceptor';
import ModaleUltimePubblicazioni from './ModaleUltimePubblicazioni';
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from '../../store/actionCreators/general';
import { getCategorie } from '../../store/actionCreators/categorie';
import { setFilterArticoliParams } from '../../store/actionCreators/filterArticoliParams';

// DatePicker and multiselect imports
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { addMonths } from "date-fns";
import it from 'date-fns/locale/it';
import MultiSelect from "@khanacademy/react-multi-select";

// Assets imports
import logo from '../../assets/img/logoAnia.svg';

// Conversione data a YYYY-MM-DD
function convertDate(str) {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function Banner(props) {
  const { categorie, getCategorie, setFilterArticoliParams, filterArticoliParams, toggleSpinner, toggleToast } = props;

  registerLocale('it', it); // settare italiano come locale per configurare Datepicker

  const history = useHistory();

  // Parola Chiave e Nome Autore States
  const [parolaChiave, setParolaChiave] = useState('');
  const [nomeAutore, setNomeAutore] = useState('')

  // DatePickers States
  const [startDatePubblicazione, setStartDatePubblicazione] = useState(null);
  const [endDatePubblicazione, setEndDatePubblicazione] = useState(null);
  const [startDateAggiornamento, setStartDateAggiornamento] = useState(null);
  const [endDateAggiornamento, setEndDateAggiornamento] = useState(null);

  // Checkboxes States
  const [checkboxesState, setCheckboxesState] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  // Resetta tutti i filtri di ricerca
  function resettaFiltri() {
    setParolaChiave('');
    setNomeAutore('');
    setStartDatePubblicazione(null);
    setEndDatePubblicazione(null);
    setStartDateAggiornamento(null);
    setEndDateAggiornamento(null);
    setSelectedCheckboxes([]);
  }

  // Costruzione della query di ricerca a partire dei dati della form
  function getSortParams() {
    let params = `&parolaChiave=${parolaChiave}&autoreNome=${nomeAutore}&categoriaIds=${selectedCheckboxes}&dataPubblicazioneDa=${!startDatePubblicazione ? '' : convertDate(startDatePubblicazione)}&dataPubblicazioneA=${!endDatePubblicazione ? '' : convertDate(endDatePubblicazione)}&dataAggiornamentoDa=${!startDateAggiornamento ? '' : convertDate(startDateAggiornamento)}&dataAggiornamentoA=${!endDateAggiornamento ? '' : convertDate(endDateAggiornamento)}`;
    return params.replace(' ', '%20');
  }

  // All'atto della submit la stringa con la query è salvato nel reducer di redux
  function handleSubmit() {
    setFilterArticoliParams(getSortParams())
  }
  // Fetch delle categorie
  useEffect(() => {
    getCategorie()
  }, []); // eslint-disable-line

  // Settare le checkboxes per il filtro di categorie
  useEffect(() => {
    setCheckboxesState(categorie.filter(c => !c.secondLevelCategory).map(c => ({ value: c.id, label: c.titoloCategoria })))
  }, [categorie]);

   // Fetch delle ultime 5 pubblicazioni
   const [data, setData] = useState([]);

   async function getUltimePubblicazioni() {
    toggleSpinner();
    await axiosInstance.get("/libri/ultimePubblicazioni")
      .then(res => {
        if (res && res.status === 200) {
          setData(res.data);
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => {
        toggleSpinner()
      })
  }

  // State modale per visualizzare le ultime 5 pubblicazioni
  const [selectedPubblication, setSelectedPubblication] = useState(null);
  const [openModalUltimePubblicazioni, setOpenModalUltimePubblicazioni] = useState(false);
  const toggleModalUltimePubblicazioni = () => setOpenModalUltimePubblicazioni(!openModalUltimePubblicazioni);

  useEffect(() => {
    getUltimePubblicazioni()
  }, []) // eslint-disable-line

  // Settings per decidere quale sezione visualizzare, Slider o Filtri di ricerca
  const [content, setContent] = useState('c');

  const chekContent = path => ['ultime-pubblicazioni'].includes(path.replace('/', '')) ? setContent('f') : setContent('c');

  useEffect(() => {
    chekContent(history.location.pathname);
    history.listen((location, action) => {
      const { pathname } = location;
      if (!pathname.includes('login')) sessionStorage.setItem('path', pathname);
      chekContent(pathname);
      if (!pathname.includes('ultime-pubblicazioni')) {
        setFilterArticoliParams('');
        resettaFiltri()
      }; // reset dei filtri di ricerca se si abbandona la pagina
      if(pathname === '/') getUltimePubblicazioni() // getch delle ultime pubblicazioni quando si torna alla home
      scrollTop();
    });
  }, [history, setFilterArticoliParams]); // eslint-disable-line

  // SELEZIONANDO UNA CATEGORIA DALLA HOME O UNA PAROLA CHIAVE DAL HEADER LE MEDESIME SARANNO PRESELEZIONATE IN ULTIME PUBBLICAZIONI
  useEffect(() => {
    // L'azione va eseguita una sola volta quando si arriva sulla schermata col banner dei filtri, altrimenti falserebbe future ricerche
    if(filterArticoliParams && content !== 'c') {
      if(filterArticoliParams.includes('categoriaIds=')) setSelectedCheckboxes([...selectedCheckboxes, filterArticoliParams.replace('&categoriaIds=', '')]);
      if(filterArticoliParams.includes('&parolaChiave=')) setParolaChiave(filterArticoliParams.replace('&parolaChiave=', ''));
    }
  }, [content]) // eslint-disable-line


  return (
    <div id="banner-container">
      {
        content === 'c'

        /* SLIDER SECTION - SE NON CI SONO DATI CI SARA' UN BANNER STATICO */
          ?
           data
            ? <CustomSlider
                data={data}
                toggleModalUltimePubblicazioni={toggleModalUltimePubblicazioni}
                setSelectedPubblication={setSelectedPubblication} />
            : <div id="filtri">
                <div className="container">
                  <div className="left-content">
                    <img src={logo} style={{filter: 'brightness(0) invert(1)'}} alt="Logo Ania" />
                  </div>
                </div>
              </div>

          /* FILTRI DI RICERCA SECTION */
          : <div id="filtri">
            <div className="container">
              <div className="title">
                <i className="fas fa-th" />cerca
              </div>

              <div id="input-container" className="margin-top-35">
                <div>
                  <div className="input-container">
                    <label htmlFor="parola-chiave">Cerca</label>
                    <input id="parola-chiave" type="text" placeholder="Cerca per parola chiave"
                      value={parolaChiave} onChange={e => setParolaChiave(e.target.value)} />
                  </div>

                  <div className="input-container">
                    <div className="row">
                      <label htmlFor="data-pubblicazione" className="col-md-12">Data pubblicazione</label>
                      <label className="col-md-1 pr-0 mt-1">dal</label>
                      <span className="col-md-5 pr-0">
                        <DatePicker
                          placeholderText="gg/mm/aaaa"
                          selected={startDatePubblicazione}
                          onChange={date => setStartDatePubblicazione(date)}
                          selectsStart
                          startDate={startDatePubblicazione}
                          endDate={endDatePubblicazione}
                          maxDate={addMonths(new Date(), 0)}
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat="dd/MM/yyyy"
                          locale="it"
                          className="text-center"
                        />
                      </span>
                      <label className="col-md-1 pl-1 pr-1 mt-1 text-center">al</label>
                      <span className="col-md-5 pl-0">
                        <DatePicker
                          placeholderText="gg/mm/aaaa"
                          selected={endDatePubblicazione}
                          onChange={date => setEndDatePubblicazione(date)}
                          selectsEnd
                          startDate={startDatePubblicazione}
                          endDate={endDatePubblicazione}
                          minDate={startDatePubblicazione}
                          maxDate={addMonths(new Date(), 0)}
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat="dd/MM/yyyy"
                          locale="it"
                          className="text-center"
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="input-container">
                    <label htmlFor="nome-autore">Autore della pubblicazione</label>
                    <input id="nome-autore" type="text" placeholder="Cognome autore"
                      value={nomeAutore} onChange={e => setNomeAutore(e.target.value)} />
                  </div>

                  <div className="input-container">
                    <div className="row">
                      <label htmlFor="data-pubblicazione" className="col-md-12">Data aggiornamento</label>
                      <label className="col-md-1 pr-0 mt-1">dal</label>
                      <span className="col-md-5 pr-0">
                        <DatePicker
                          placeholderText="gg/mm/aaaa"
                          selected={startDateAggiornamento}
                          onChange={date => setStartDateAggiornamento(date)}
                          selectsStart
                          startDate={startDateAggiornamento}
                          endDate={endDateAggiornamento}
                          maxDate={addMonths(new Date(), 0)}
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat="dd/MM/yyyy"
                          locale="it"
                          className="text-center"
                        />
                      </span>
                      <label className="col-md-1 pl-1 pr-1 mt-1 text-center">al</label>
                      <span className="col-md-5 pl-0">
                        <DatePicker
                          placeholderText="gg/mm/aaaa"
                          selected={endDateAggiornamento}
                          onChange={date => setEndDateAggiornamento(date)}
                          selectsEnd
                          startDate={startDateAggiornamento}
                          endDate={endDateAggiornamento}
                          minDate={startDateAggiornamento}
                          maxDate={addMonths(new Date(), 0)}
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat="dd/MM/yyyy"
                          locale="it"
                          className="text-center"
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="input-container">
                    <label className="w-100">Categoria</label>

                    <MultiSelect
                      options={checkboxesState}
                      selected={selectedCheckboxes}
                      onSelectedChanged={selected => setSelectedCheckboxes(selected)}
                      overrideStrings={{
                        selectSomeItems: "Seleziona categorie",
                        allItemsAreSelected: "Tutte le categorie sono selezionate",
                        selectAll: "Seleziona tutto",
                        search: "Cerca",
                      }}
                      disableSearch
                    />

                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 reset d-flex justify-content-center align-items-center" onClick={resettaFiltri}>
                  <i className="fas fa-times color-azure cursor-pointer" />
                    <span className="margin-left-10 cursor-pointer"><i><u>Resetta i filtri</u></i></span>
                </div>
              </div>
              <div className="row">
                <div className="btn-container col-md-10 d-flex justify-content-center margin-top-15" onClick={handleSubmit}>
                  <div className="btn btn-dark-blue text-uppercase"><i className="fas fa-filter" />filtra risultati</div>
                </div>
              </div>

            </div>
          </div>
      }
      {/* MODALE PER VISUALIZZARE LE ULTIME 5 PUBBLICAZIONI */}
      <ModaleUltimePubblicazioni
        openModalUltimePubblicazioni={openModalUltimePubblicazioni}
        toggleModalUltimePubblicazioni={toggleModalUltimePubblicazioni}
        selectedPubblication={selectedPubblication}
      />
    </div>
  );
}
// Reducer contenente le categorie, fare il fetch dal DB con getCategorie()
function mstp(state) {
  return {
    categorie: state.categorie,
    filterArticoliParams: state.filterArticoliParams
  }
}

export default connect(mstp, { getCategorie, setFilterArticoliParams, toggleSpinner, toggleToast })(Banner);
