import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap';
import axiosInstance from '../../api/interceptor';
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import { statoPubblicazioneArticolo, tipoUtente } from '../../assets/utils/enum';
import download from '../../assets/img/down-arrow.png';
import view from '../../assets/img/view.png';
import { saveAs } from 'file-saver';

import { printAndDownloadArticolo } from "../PdfGeneration/PDFPrint"

// LIMITA TITOLO A XXX CARATTERI
function formatTitle(title, limitChar) {
  return title.length > limitChar ? title.substr(0, limitChar) + '...' : title;
}

// METODO PER CONVERTIRE HTML DA DB IN STRINGA SENZA TAG
function stripHtml(html) {
  // Creare un nuovo div
  var temporalDivElement = document.createElement("div");
  // Innestare nel div il contenuto HTML dal db
  temporalDivElement.innerHTML = html;
  // Estrarre il contenuto testuale
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

// CONVERTE DATA DA YYYY-MM-DD a DD/MM/YYYY
function convertDate(date) {
  return date.split('-').reverse().join('/');
}


function Articolo(props) {
  // TOGGLE PER MODAL
  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);

  // TOGGLE PER TOOLTIPS
  const [tooltipDownload, setTooltipDownload] = React.useState(false);
  const toggleToolTipDownload = () => setTooltipDownload(!tooltipDownload);

  const [tooltipView, setTooltipView] = React.useState(false);
  const toggleToolTipView = () => setTooltipView(!tooltipView);

  // FETCH DATI UTENTE DA SESSION STORAGE
  const currentUser = JSON.parse(atob(sessionStorage.getItem('token')));

  // AGGIORNARE LO STATO DI UN ARTICOLO
  function statoArticolo(id, stato) {
    props.updateStatusArticolo(id, stato);
    toggleModal();
  }

  // ELIMINARE UN ARTICOLO
  function eliminaArticolo(id, titolo) {
    props.confirmDelete(id, titolo);
    toggleModal();
  }

  // STAMPARE ARTICOLO
  async function printArticolo(id, titolo) {
    props.toggleSpinner();
    await axiosInstance.get(`/print/articolo/${id}`, { responseType: 'blob' })
      .then(res => {
        let title = titolo.toLowerCase().replace(/ /g, '_'); // creiamo il titolo per il file docx
        saveAs(res.data, `${title}.docx`) // salviamo il file
      })
      .catch(messaggio => {
        props.toggleToast('e', messaggio);
      })
      .finally(() => {
        props.toggleSpinner()
      })
  }

  async function printArticoloPDF(id, titolo) {
    props.toggleSpinner();
    await printAndDownloadArticolo(props)
      .then(() => {props.toggleSpinner()});
  }

  // const [url, setUrl] = React.useState(null);

  // async function stampaArticolo(id, titolo) {
  //   props.toggleSpinner();
  //   await axiosInstance.get(`/print/articolo/${id}`, {responseType: 'blob'})
  //     .then(res => {
  //       setUrl(URL.createObjectURL(res.data)) // CONVERSIONE BLOB IN URL
  //     })
  //     .catch(messaggio => {
  //       props.toggleToast('e', messaggio);
  //     })
  //     .finally(() => {
  //       props.toggleSpinner()
  //     })
  // }


  // CAMBIARE I BUTTONS DELLA MODALE IN BASE A RUOLO UTENTE E STATO ARTICOLO
  function setModalButtons() {
    if (currentUser.ruoloUtente === tipoUtente.ADMIN && props.statoArticolo !== statoPubblicazioneArticolo.PUBBLICATO) {
      return (
        <div className="col-md-12 margin-top-30 d-flex justify-content-center align-items-center">
          { /* BUTTON PUBBLICA SI VEDE SOLO PER ARTICOLI MANDATI IN APPROVAZIONE */
            props.statoArticolo === statoPubblicazioneArticolo.ATTESA_PUBBLICAZIONE
              ? <button className="btn btn-azure btn-sm mr-3" onClick={() => statoArticolo(props.id, statoPubblicazioneArticolo.PUBBLICATO)}>Pubblica</button>
              : null
          }
          <button className="btn outline-azure btn-sm" onClick={() => eliminaArticolo(props.id, props.titoloArticolo)}>Elimina</button>
        </div>
      );
    }
    else if (currentUser.ruoloUtente === tipoUtente.EDITOR && props.statoArticolo !== statoPubblicazioneArticolo.PUBBLICATO) {
      return (
        <div className="col-md-12 margin-top-30 d-flex justify-content-center align-items-center">
          <Link to={{ // passiamo al componente l'oggetto contenente le informazioni dell'articolo, che nel nostro caso corrisponde a props
            pathname: '/nuovo-articolo/',
            modifyArticleProps: props
          }}>
            <button className="btn btn-azure btn-sm mr-3">Modifica</button>
          </Link>
          <button className="btn outline-azure btn-sm" onClick={() => eliminaArticolo(props.id, props.titoloArticolo)}>Elimina</button>
        </div>
      );
    }
    else {
      return null
    }
  }

  // CAMBIARE LA PRIMA ICONA DELLA MODALE IN BASE A RUOLO UTENTE E STATO ARTICOLO
  function setModalSecondIcon() {
    if ((currentUser.ruoloUtente === tipoUtente.EDITOR && props.statoArticolo === statoPubblicazioneArticolo.ATTESA_PUBBLICAZIONE) ||
      (currentUser.ruoloUtente === tipoUtente.ADMIN && !props.libroPubblicato && props.statoArticolo === statoPubblicazioneArticolo.PUBBLICATO && props.revisioneArticoli)) {
      return (
        <div className="d-flex action-container justify-content-center cursor-pointer" onClick={() => statoArticolo(props.id, statoPubblicazioneArticolo.BOZZA)}>
          <i className="fas fa-edit color-rosso mr-1" aria-label="Riporta in bozza" /><small className="font-italic">Riporta in bozza</small>
        </div>)
    }
    else if (currentUser.ruoloUtente === tipoUtente.ADMIN && props.statoArticolo !== statoPubblicazioneArticolo.PUBBLICATO) {
      return (
        <div className="d-flex action-container justify-content-center cursor-pointer" style={{ marginTop: '-2px' }}>
          <Link className="text-decoration-none" to={{ // passiamo al componente l'oggetto contenente le informazioni dell'articolo, che nel nostro caso corrisponde a props
            pathname: '/nuovo-articolo/',
            modifyArticleProps: props
          }}>
            <i className="fas fa-edit color-rosso mr-1" aria-label="Modifica" /><small className="font-italic text-dark">Modifica</small>
          </Link>
        </div>)
    }
    else {
      return null
    }
  }

  // SETTA FLAG DELLA COPERTINA IN BASE ALLO STATUS
  function setFlag() {
    if (props.statoArticolo === statoPubblicazioneArticolo.PUBBLICATO) {
      return <i className="far fa-check-circle color-verde ml-1 mr-1 mt-3 mb-1 font-weight-bold" aria-label="Pubblicato" />
    }
    else if (props.statoArticolo === statoPubblicazioneArticolo.ATTESA_PUBBLICAZIONE) {
      return <i className="far fa-hourglass color-bianco ml-1 mr-1 mt-3 mb-1" aria-label="Attesa pubblicazione" />
    }
    else {
      return <i className="fas fa-edit color-bianco ml-1 mr-1 mt-3 mb-1" aria-label="bozza" />
    }
  }

  // MODALITA' COMPONI: VERIFICA SE ARTICOLO E' SELEZIONATO O MENO
  const [articleSelected, setArticleSelected] = React.useState(false)

  // se siamo in modalità COMPONI (props.componi) avviamo il metodo che verifica se l'articolo è nell'array dei selezionati
  React.useEffect(() => {
    if (props.componi) setArticleSelected(props.checkArticoloSelezionato)
  }, [props.componi, props.selezioneArticolo, props.checkArticoloSelezionato]);


  return (
    <div className={`articolo ${props.componi && articleSelected ? 'highlight-selected-componi' : ''} 
                              ${props.disabled ? 'disabled-element' : ''} `}
      style={{ backgroundImage: `url("data:image/png;base64, ${props.immagineAnteprima}")`, backgroundSize: "100% 100%" }}>

      <div className="d-flex flex-column w-100">
        <div className="text-right pr-3 color-main-color font-weight-bold">{convertDate(!props.dataPubblicazione ? props.dataAggiornamento : props.dataPubblicazione)}</div>

        { /* FLAGS CON STATO VISIBILI SOLO SU REVISIONE/I MIEI ARTICOLI */
          props.seeFlags &&
          <div className="d-flex justify-content-start" style={{ marginTop: '37.5px' }}>
            <div className="text-center" style={{ backgroundColor: '#005A8E', width: '55px', height: '45px' }}>
              {setFlag()}
            </div>
          </div>
        }
      </div>

      

      { /* PULSANTE PER SELEZIONARE/DESELEZIONARE L'ARTICOLO NELLA MODALITA' COMPONI */
        !props.componi ? null :
          <div className=" d-flex flex-column justify-content-center align-items-center h-50 w-100">
            <button className={`btn ${!articleSelected ? 'btn-azure' : 'btn-dark-blue'} sm text-uppercase`} onClick={props.selezioneArticolo}>
              {!articleSelected ? 'seleziona' : 'deseleziona'}
            </button>
          </div>
      }

      <div className="actions-articolo">

        <div className="details">

          <div className="row align-items-center mr-0 ml-0 w-100" style={{ height: "52px", overflow: "hidden" }}>
            <div className="col-md-12 px-0 py-1">
              <h6 className="titolo">{formatTitle(props.titoloArticolo, window.outerWidth > 1368 ? 65 : 55)}</h6>
            </div>
          </div>

          <div className="row justify-content-center align-items-center mr-0 ml-0 w-100" style={{ height: "40px", overflow: "hidden" }}>
            <div className="col-md-12 p-0">
              <p className="hashtag">
                <span>{!props.categoriaArticoloRes.nomeCategoriaMadre ? null : `#${props.categoriaArticoloRes.nomeCategoriaMadre} `}</span>
                <span>{`#${props.categoriaArticoloRes.nomeCategoria}`}</span>
              </p>
            </div>
          </div>

          <div className="color-azure mt-1" style={{ height: "17px", overflow: "hidden" }}>{`${props.autore.nome[0]}. ${props.autore.cognome}`}</div>
        </div>

        <div className="actions">
          <div className="action-container" id="Download">
            {props.templateModelloLibro === "IVASS" ?
              (<img src={download} alt="download" onClick={() => printArticoloPDF(props.id, props.titoloArticolo)} />)
              :
              (<img src={download} alt="download" onClick={() => printArticolo(props.id, props.titoloArticolo)} />)}

            <Tooltip placement="bottom" isOpen={tooltipDownload} target="Download" toggle={toggleToolTipDownload}>
              Download
            </Tooltip>
          </div>
          {/* <div className="action-container" id="Print" >
            <img src={print} alt="print" />
            <Tooltip placement="bottom" isOpen={tooltipPrint} target="Print" toggle={toggleToolTipPrint}>
              Print
            </Tooltip>
          </div> */}
          <div className="action-container" id="View">
            <img src={view} alt="view" onClick={toggleModal} />
            <Tooltip placement="bottom" isOpen={tooltipView} target="View" toggle={toggleToolTipView}>
              View
            </Tooltip>
          </div>
        </div>
      </div>

      {/* Modale con le azioni */}
      <Modal size="xl" id="modale-componi-articolo" isOpen={showModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
        </ModalHeader>
        <ModalBody>
          <div className="row" style={{ height: '700px' }}>

            {/* COPERTINA */}

            <div id="copertina" className="col-md-6"
              style={{ backgroundImage: `url("data:image/png;base64, ${props.immagineAnteprima}")`, backgroundSize: "100% 100%" }} />

            {/* CONTENUTO */}

            <div id="contenuto" className="col-md-6">
              <div className="row margin-top-30">
                <div className="col-md-12 color-main-text" style={{ fontSize: "14px" }}>
                  {convertDate(!props.dataPubblicazione ? props.dataAggiornamento : props.dataPubblicazione)}
                </div>
              </div>

              <div className="row margin-top-15">
                <div className="col-md-12 color-main-color text-uppercase">
                  <h5 className="mb-0" style={{ fontSize: "22px", fontFamily: "Raleway-Black" }}>{props.titoloArticolo}</h5>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mt-2 titolo-item" style={{ fontSize: "16px", fontFamily: "OpenSans-Regular" }}>
                  <span className="color-azure font-weight-bold">{props.libroNome}</span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mt-2 titolo-item" style={{ fontSize: "14px", fontFamily: "OpenSans-Regular" }}>
                  <span className="color-azure font-italic">{props.categoriaArticoloRes.nomeCategoria} </span>
                  <span className="font-italic">| {props.autore.nome} {props.autore.cognome}</span>
                </div>
              </div>

              <div className="row margin-top-15">
                <div className="col-md-12" style={{ fontSize: "14px", fontFamily: "OpenSans-Regular" }}>
                  <span className="color-main-text my-3">{formatTitle(stripHtml(props.boxTestoPrimoCompilato.contenutoBoxTesto), 250)}</span>
                </div>
              </div>

              <div className="row margin-top-30">
                <div className="col-md-4">
                  <div className="d-flex action-container justify-content-center cursor-pointer" onClick={toggleModal}>
                    <i className="fas fa-times-circle color-rosso mr-1" aria-label="Modifica" /><small className="font-italic">Chiudi</small>
                  </div>
                </div>
                <div className="col-md-4">
                  {setModalSecondIcon()}
                </div>
                <div className="col-md-4">
                  <div className="d-flex action-container justify-content-center cursor-pointer" onClick={() => printArticolo(props.id, props.titoloArticolo)}>
                    <i className="fas fa-file-download color-rosso mr-1" aria-label="download" /><small className="font-italic">Download</small>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="d-flex action-container justify-content-center" >
                    <i className="fas fa-print cursor-pointer color-rosso mr-1" aria-label="print" /><small className="font-italic">Stampa</small>
                  </div>
                </div> */}
              </div>
              <hr className="margin-top-40 margin-bottom-40" />
              {setModalButtons()}
            </div>
          </div>
        </ModalBody>
        <ModalFooter />
      </Modal>

    </div>
  );
}

export default connect(null, { toggleSpinner, toggleToast })(Articolo);
