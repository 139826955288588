import React, { useEffect, useState } from 'react';
import './App.css';
import Layout from "../Layout/Layout";
import axiosInstance from '../../api/interceptor';
import { connect } from "react-redux";
import { auth, authCheckState } from "../../store/actionCreators/auth";
import { getTemplateModelli, getTematicheArticoli, getImportanzaArticoli, getAutori, getListaArticoli, getStatiUpdateArticolo } from '../../store/actionCreators/staticData';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { getRoutes, scrollTop } from '../../routing/utils';

const resources = {
  templateModelli: '/template-modello-libro',
  tematicheArticoli: '/tematiche-articolo',
  importanzaArticoli: '/livelli-importanza-articolo',
  listaAutori: '/utenti/slim?ruoliUtente=ADMIN,EDITOR',
  listaArticoli: '/articoli/slim',
  statiUpdateArticolo: '/stato-aggiornamento'
}

function App(props) {
  const { tryAutoSignUp, isLoading, user } = props;
  const [browserOld, setBrowserOld] = useState(null);
  const [show, setShow] = useState(false);

  // Metodi per salvare in redux i dati di Template Modelli, Importanza e Tematiche Articoli
  const fetchTemplateModello = async () => {
    await axiosInstance.get(resources.templateModelli)
      .then(r => {
        props.getTemplateModelli(r.data)
      })
      .catch(({ messaggio }) => console.log('e', messaggio))
  }

  const fetchTematicheArticoli = async () => {
    await axiosInstance.get(resources.tematicheArticoli)
      .then(r => {
        props.getTematicheArticoli(r.data)
      })
      .catch(({ messaggio }) => console.log('e', messaggio))
  }

  const fetchImportanzaArticoli = async () => {
    await axiosInstance.get(resources.importanzaArticoli)
      .then(r => {
        props.getImportanzaArticoli(r.data)
      })
      .catch(({ messaggio }) => console.log('e', messaggio))
  }

  const fetchAutori = async () => {
    const r = await axiosInstance.get(resources.listaAutori);
    props.getAutori(r.data.map(el => ({ ...el, cognome: el.cognome.toUpperCase() })))
  }

  const fetchListaArticoli = async () => {
    const r = await axiosInstance.get(resources.listaArticoli);
    props.getListaArticoli(r.data)
  }

  const fetchStatoUpdateArticolo = async () => {
    const r = await axiosInstance.get(resources.statiUpdateArticolo);
    props.getStatiUpdateArticolo(r.data)
  }

  const getData = async () => {
    await Promise.all([
      fetchTemplateModello(),
      fetchTematicheArticoli(),
      fetchImportanzaArticoli(),
      fetchAutori(),
      fetchListaArticoli(),
      fetchStatoUpdateArticolo()
    ])
  }
  // Fine sezione Metodi per salvare in redux i dati di Template Modelli, Importanza e Tematiche Articoli

  useEffect(() => {
    // edge è per le versioni di Edge non più supportate, trident per IE
    if (window.navigator.userAgent.toLowerCase().indexOf("edge") > -1 || window.navigator.userAgent.toLowerCase().indexOf("trident") > -1) {
      const browserOld = window.navigator.userAgent.toLowerCase().indexOf("edge") > -1 ? 'edge' : 'ie';
      setBrowserOld(browserOld);
    }

    tryAutoSignUp();

    window.onscroll = () => {
      document.body.scrollTop > 250 || document.documentElement.scrollTop > 250 ? setShow(true) : setShow(false);
    };
  }, []); // eslint-disable-line

  // Se l'utente è loggato eseguo le chiamate per ottenere i dati da salvare in redux
  useEffect(() => {
    if (user) {
      getData()
    }
  }, [user]);// eslint-disable-line

  const classScroll = [
    'fas',
    'fa-chevron-circle-up',
    'cursor-pointer',
    show && 'show'
  ].filter(Boolean).join(' ');


  return (
    <>
      {
        !user ?
          getRoutes(user) :
          <div id="app-container">
            <Layout user={user} />
            <i id="scroll-top-img"
              onClick={scrollTop}
              className={classScroll} />
            {browserOld && <div id="browser-old-wrapper">
              {
                browserOld === 'edge' ?
                  <p>Per poter usufruire della piattaforma aggiorna il tuo browser a questo&nbsp;<a href="https://www.microsoft.com/it-it/edge">indirizzo</a></p> :
                  'La piattaforma non è supportata dal tuo browser, ti consigliamo di utilizzare Google Chrome'
              }
            </div>}
          </div>
      }
      <ToastContainer autoClose={5000} />
      {isLoading && <LoadingSpinner />}
    </>
  );
}

const mapStateToProps = ({ auth, general }) => ({
  user: auth.user,
  menu: general.menu,
  isLoading: general.loading,
});

const mapDispatchToProps = dispatch => ({
  tryAutoSignUp: () => dispatch(authCheckState()),
  onAuth: () => dispatch(auth()),
  getTemplateModelli: data => dispatch(getTemplateModelli(data)),
  getTematicheArticoli: data => dispatch(getTematicheArticoli(data)),
  getImportanzaArticoli: data => dispatch(getImportanzaArticoli(data)),
  getAutori: data => dispatch(getAutori(data)),
  getListaArticoli: data => dispatch(getListaArticoli(data)),
  getStatiUpdateArticolo: data => dispatch(getStatiUpdateArticolo(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
