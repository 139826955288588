import React from 'react';
import notFound from '../../assets/img/404-error.png';
import {NavLink} from "react-router-dom";
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';

function NotFound(props) {
    return (
        <div id="not-found-container" className="container">
          <BackToPrevious goBack ={() => props.history.goBack()} />
            <div className="row">
                <div className="col-md-4 offset-md-2 d-flex align-content-center justify-content-center flex-wrap">
                    <h1 className="w-100 text-center">Oops</h1>
                    <h5 className="w-100 text-center">Pagina non trovata</h5>
                    <NavLink className="btn btn-azure margin-top-20" to="/">Torna alla HOMEPAGE</NavLink>
                </div>
                <div className="col-md-4">
                    <img src={notFound} alt="Pagina non trovata"/>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
