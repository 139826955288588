import { degrees, rgb, TextAlignment, PDFString } from "pdf-lib";

export const mainColors = {
    colorCovidLabel: rgb(0 / 255, 176 / 255, 80 / 255),
    colorBlue: rgb(0, 32 / 255, 96 / 255),
    colorBlack: rgb(0, 0, 0)
}

export const mainFontSizes = {
    fontSizeHeader: 28,
    fontSizeSubtitle: 14,
    fontSizeText: 10,
}

const colorPalette = {
    "Antifrode": { colorMain: rgb(247 / 255, 138 / 255, 59 / 255), colorLight: rgb(253 / 255, 214 / 255, 178 / 255) },
    "Auto e Card": { colorMain: rgb(245 / 255, 153 / 255, 97 / 255), colorLight: rgb(251 / 255, 229 / 255, 214 / 255) },
    "Bilancio": { colorMain: rgb(255 / 255, 217 / 255, 102 / 255), colorLight: rgb(255 / 255, 230 / 255, 153 / 255) },
    "Cybersecurity": { colorMain: rgb(191 / 255, 191 / 255, 191 / 255), colorLight: rgb(204 / 255, 204 / 255, 204 / 255) },
    "Danni": { colorMain: rgb(169 / 255, 209 / 255, 142 / 255), colorLight: rgb(175 / 255, 209 / 255, 200 / 255) },
    "Distribuzione": { colorMain: rgb(143 / 255, 170 / 255, 220 / 255), colorLight: rgb(177 / 255, 196 / 255, 231 / 255) },
    "Innovazione": { colorMain: rgb(123 / 255, 150 / 255, 160 / 255), colorLight: rgb(177 / 255, 196 / 255, 231 / 255) },
    "Legale": { colorMain: rgb(132 / 255, 151 / 255, 176 / 255), colorLight: rgb(165 / 255, 179 / 255, 197 / 255) },
    "Solvency": { colorMain: rgb(0 / 255, 153 / 255, 153 / 255), colorLight: rgb(0 / 255, 189 / 255, 189 / 255) },
    "Sostenibilità": { colorMain: rgb(49 / 255, 165 / 255, 52 / 255), colorLight: rgb(90 / 255, 206 / 255, 94 / 255) },
    "Vita": { colorMain: rgb(239 / 255, 237 / 255, 139 / 255), colorLight: rgb(245 / 255, 243 / 255, 179 / 255) }
}

export const getPalette = (categoria) => {
    return colorPalette[categoria] || { colorMain: rgb(245 / 255, 153 / 255, 97 / 255), colorLight: rgb(251 / 255, 229 / 255, 214 / 255) }
}

class PDFGenerationUtils {

    static fillParagraph(text, font, fontSize, maxWidth) {
        const paragraphs = text.split('\n');

        const wrappedParagraphs = paragraphs.map(paragraph => {
            if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
                let words = paragraph.split(' ')
                let wrappedParagraph = []
                let i = 0
                wrappedParagraph[i] = [];

                words.forEach(word => {
                    wrappedParagraph[i].push(word);
                    if (font.widthOfTextAtSize(wrappedParagraph[i].join(' '), fontSize) > maxWidth) {
                        wrappedParagraph[i].splice(-1)
                        i += 1
                        wrappedParagraph[i] = []
                        wrappedParagraph[i].push(word)
                    }
                })

                return wrappedParagraph.map(p => p.join(' ')).join('\n');
            }

            return paragraph
        })

        return wrappedParagraphs.join('\n');
    }

    static drawCenteredRectangle = (page, options) => {
        const { width, height } = page.getSize()
        page.drawRectangle({
            x: (width - options.rectWidth) / 2,
            y: height - options.rectHeight - options.y,
            width: options.rectWidth,
            height: options.rectHeight,
            color: options.color
        })
    }

    static drawCenteredText = (page, options) => {
        const { width, height } = page.getSize()
        const filledParagraph = PDFGenerationUtils.fillParagraph(options.text, options.font, options.textSize, options.maxWidth ?? 500)
        const paragraphs = filledParagraph.split("\n")
        const lineHeight = options.textSize

        paragraphs.forEach((p, idx) => {
            const textWidth = options.font.widthOfTextAtSize(p, options.textSize)

            page.drawText(p, {
                x: (width - textWidth) / 2,
                y: height - options.y - idx * lineHeight,
                size: options.textSize,
                font: options.font,
                color: options.color,
                maxWidth: options.maxWidth ?? undefined
            })
        })
    }

    static drawCenteredTextField = (page, options) => {
        const { width, height } = page.getSize()
        const sintesiForm = page.doc.getForm()
        const textField = sintesiForm.createTextField("articolo.sintesi." + new Date().getTime())
        textField.setAlignment(TextAlignment.Left)
        textField.enableMultiline()
        textField.setText(options.text)
        textField.acroField.setDefaultAppearance("/Helv 0 Tf")
        textField.setFontSize(mainFontSizes.fontSizeText)

        textField.addToPage(page, { width: options.width, height: options.height, y: height - options.y, x: (width - options.width) / 2, borderWidth: 0, font: options.font, textColor: options.color })

        const boxHeight = options.height * 1.05
        const boxWidth = options.width * 1.05

        page.drawRectangle({
            x: (width - boxWidth) / 2,
            y: height - options.y - options.height * 0.05 / 2,
            width: boxWidth,
            height: boxHeight,
            borderColor: options.color,
            borderWidth: 1.5
        })
    }

    static drawWrappedTextInBox = (page, text, options) => {
        const { width, height } = page.getSize()
        let wrappedText = PDFGenerationUtils.fillParagraph(text, options.font, options.textSize, options.width)

        const lineHeight = options.textSize * 1.3;
        const linesNumber = wrappedText.split("\n").length
        const textApproximateHeight = lineHeight * linesNumber

        if (options.height && textApproximateHeight >= options.height) {
            const approximateNumberOfFittingLines = Math.floor(options.height / lineHeight) - 1
            let fittingTextLines = wrappedText.split("\n").slice(0, approximateNumberOfFittingLines)
            if (fittingTextLines[fittingTextLines.length - 1].trim() === "")
                fittingTextLines.splice(fittingTextLines.length - 1)
            wrappedText = fittingTextLines.join("\n") + " [...]"
        }

        page.drawText(wrappedText, {
            x: (width - options.width) / 2,
            y: height - options.y - 20,
            size: options.textSize,
            font: options.font,
            color: options.color,
            lineHeight: lineHeight,
            maxWidth: options.width,
        })

        const boxHeight = options.height ?? options.textSize * linesNumber * 1.4;
        const boxWidth = options.width * 1.05

        page.drawRectangle({
            x: (width - boxWidth) / 2,
            y: height - boxHeight - options.y,
            width: boxWidth,
            height: boxHeight,
            borderColor: options.color,
            borderWidth: 1.5
        })
    }

    static addLink = (indexPage, destPage, linkText, linkIdx, positionY, font) => {
        if (!font) return {}
        const { width } = indexPage.getSize()
        const wrappedLine = font.widthOfTextAtSize(linkText, 15) > width - 100

        const linkAnnotation = indexPage.doc.context.obj({
            Type: 'Annot',
            Subtype: 'Link',
            Rect: [0, positionY - 10, width, positionY + (wrappedLine ? 20 : 10)],
            Border: [0, 0, 0],
            C: [0, 0, 0],
            Dest: [destPage.ref, 'XYZ', null, null, null],
        })
        const linkAnnotationRef = indexPage.doc.context.register(linkAnnotation)

        indexPage.drawText((linkIdx + 1).toString() + ".", {
            x: 32,
            y: positionY,
            font: font,
            size: 15,
            color: rgb(0, 0, 0),
            maxWidth: width - 100,
            lineHeight: 17
        });

        indexPage.drawText(linkText, {
            x: 55,
            y: positionY,
            font: font,
            size: 15,
            color: rgb(0, 0, 1),
            maxWidth: width - 100,
            lineHeight: 17
        });

        return { linkAnnotationRef, wrappedLine }
    }

    static addExternalLink = (page, url, rect) => {
        const linkAnnotation = page.doc.context.obj({
            Type: 'Annot',
            Subtype: 'Link',
            Rect: rect,
            Border: [0, 0, 0],
            C: [0, 0, 0],
            A: {
                Type: 'Action',
                S: 'URI',
                URI: PDFString.of(url),
            }
        })

        const linkAnnotationRef = page.doc.context.register(linkAnnotation)
        return linkAnnotationRef
    }

    static addInternalLink = (page, ref, rect) => {
        const linkAnnotation = page.doc.context.obj({
            Type: 'Annot',
            Subtype: 'Link',
            Rect: rect,
            Border: [0, 0, 0],
            C: [0, 0, 0],
            Dest: [ref, 'XYZ', null, null, null],
        })

        const linkAnnotationRef = page.doc.context.register(linkAnnotation)
        return linkAnnotationRef
    }

    static getSquareOffsets = (gridOptions, height) => {
        const { offsetX, offsetY, squareSize, squareMargin, headerheight } = gridOptions
        return {
            nuiSquareOffsets: { x: offsetX, y: height - offsetY },
            uiSquareOffsets: { x: offsetX + squareSize + squareMargin, y: height - offsetY },
            nuniSquareOffsets: { x: offsetX, y: height - offsetY - squareSize - squareMargin - headerheight },
            uniSquareOffsets: { x: offsetX + squareSize + squareMargin, y: height - offsetY - squareSize - squareMargin - headerheight },
        }
    }

    static drawGrigliaBase = (page, gridOptions, fontBold) => {
        const { height } = page.getSize()
        const { squareSize, headerheight, offsetX, offsetY, squareMargin } = gridOptions
        const { nuiSquareOffsets, uiSquareOffsets, nuniSquareOffsets, uniSquareOffsets } = PDFGenerationUtils.getSquareOffsets(gridOptions, height)

        page.drawRectangle({ x: nuiSquareOffsets.x, y: nuiSquareOffsets.y + squareSize, width: squareSize, height: headerheight, color: rgb(0, 32 / 255, 96 / 255), borderColor: mainColors.colorBlack, borderWidth: 1 })
        page.drawText("Non Urgente", { x: nuiSquareOffsets.x + 75, y: nuiSquareOffsets.y + squareSize + 30, font: fontBold, color: rgb(1, 1, 0), size: 14 })
        page.drawText("Importante", { x: nuiSquareOffsets.x + 80, y: nuiSquareOffsets.y + squareSize + 10, font: fontBold, color: rgb(1, 1, 0), size: 14 })
        page.drawSquare({ ...nuiSquareOffsets, size: squareSize, borderColor: rgb(0, 0, 0) })

        page.drawRectangle({ x: uiSquareOffsets.x, y: uiSquareOffsets.y + squareSize, width: squareSize, height: headerheight, color: rgb(1, 0, 0), borderColor: mainColors.colorBlack, borderWidth: 1 })
        page.drawText("Urgente", { x: uiSquareOffsets.x + 87, y: uiSquareOffsets.y + squareSize + 30, font: fontBold, color: rgb(1, 1, 1), size: 14 })
        page.drawText("Importante", { x: uiSquareOffsets.x + 80, y: uiSquareOffsets.y + squareSize + 10, font: fontBold, color: rgb(1, 1, 1), size: 14 })
        page.drawSquare({ ...uiSquareOffsets, size: squareSize, borderColor: rgb(0, 0, 0) })

        page.drawRectangle({ x: nuniSquareOffsets.x, y: nuniSquareOffsets.y + squareSize, width: squareSize, height: headerheight, color: rgb(0, 176 / 255, 80 / 255), borderColor: mainColors.colorBlack, borderWidth: 1 })
        page.drawText("Non Urgente", { x: nuniSquareOffsets.x + 75, y: nuniSquareOffsets.y + squareSize + 30, font: fontBold, color: rgb(1, 1, 1), size: 14 })
        page.drawText("Non Importante", { x: nuniSquareOffsets.x + 65, y: nuniSquareOffsets.y + squareSize + 10, font: fontBold, color: rgb(1, 1, 1), size: 14 })
        page.drawSquare({ ...nuniSquareOffsets, size: squareSize, borderColor: rgb(0, 0, 0) })

        page.drawRectangle({ x: uniSquareOffsets.x, y: uniSquareOffsets.y + squareSize, width: squareSize, height: headerheight, color: rgb(255 / 255, 255 / 255, 0 / 255), borderColor: mainColors.colorBlack, borderWidth: 1 })
        page.drawText("Urgente", { x: uniSquareOffsets.x + 87, y: uniSquareOffsets.y + squareSize + 30, font: fontBold, color: rgb(1, 0, 0), size: 14 })
        page.drawText("Non Importante", { x: uniSquareOffsets.x + 65, y: uniSquareOffsets.y + squareSize + 10, font: fontBold, color: rgb(1, 0, 0), size: 14 })
        page.drawSquare({ ...uniSquareOffsets, size: squareSize, borderColor: rgb(0, 0, 0) })

        page.drawLine({ start: { x: offsetX - 10, y: offsetY - 30 - squareSize }, end: { x: offsetX - 10, y: offsetY + squareSize + headerheight * 2 }, thickness: 2 })
        page.drawLine({ start: { x: offsetX - 10, y: offsetY - 30 - squareSize }, end: { x: offsetX + squareSize * 2 + squareMargin + 10, y: offsetY - 30 - squareSize }, thickness: 2 })

        page.drawText("urgenza", { x: offsetX + 180, y: offsetY - 30 - squareSize - 30, font: fontBold, color: rgb(0, 0, 0), size: 26 })
        page.drawText("importanza", { x: offsetX - 25, y: height / 2 - 50, font: fontBold, color: rgb(0, 0, 0), size: 26, rotate: degrees(90) })
    }
}

export default PDFGenerationUtils;