import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axiosInstance from '../../api/interceptor';
import { printAndDownloadArticolo } from "../../components/PdfGeneration/PDFPrint"
import { toggleToast, toggleSpinner } from '../../store/actionCreators/general';

function PrintArticle({ match, toggleToast, toggleSpinner }) {
  const history = useHistory();

  async function printArticoloPDF(articolo) {
    toggleSpinner();
    await printAndDownloadArticolo(articolo)
      .then(() => {
        toggleSpinner()
        history.push('/')
      })
  }

  useEffect(() => {
    if (match) {
      const url = `/articoli/${match.params.id}`;
      
      axiosInstance.get(url)
      .then(res => {
        if (res && res.status === 200) { printArticoloPDF(res.data) }
      })
      .catch(e => {
        toggleToast('e', e.messaggio);
        history.push('/')
      })

    } else {
      history.push('/')
    }
  }, []) // eslint-disable-line

  return (
    <div className=" d-flex flex-column justify-content-center align-items-center w-100" style={{ height: "300px" }}>
      <div className="spinner-border text-primary" role="status" />
      <h6 className="color-main-color mt-3">In stampa ...</h6>
    </div>
  )
}

export default connect(null, {toggleToast,toggleSpinner})(PrintArticle)
