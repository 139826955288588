import {REMOVE_SELECTED_LIBRO, SET_SELECTED_LIBRO, UPDATE_TABELLA} from "../actions/Libro";

const initialState = {
  selectedLibro: null,
  update: false
}

const libro = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_LIBRO:
      return {...state, selectedLibro: action.payload}
    case REMOVE_SELECTED_LIBRO:
      return {...state, selectedLibro: null}
    case UPDATE_TABELLA:
      return {...state, update: !state.update}
    default: return state;
  }
}

export default libro;
