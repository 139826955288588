import React from 'react';
import PropTypes from 'prop-types';
import {toggleSpinner, toggleToast} from "../../../store/actionCreators/general";
import {connect} from "react-redux";
import axiosInstance from "../../../api/interceptor";

const utentePostURL = '/utenti';
const utentePutURL = id => `/utenti/${id}`;

const ruoli = ['ADMIN', 'EDITOR', 'VIEWER'];

const actions = {
  init: 'init',
  setInitialState: 'setInitialState',

  setNome: 'setNome',
  setErrorNome: 'setErrorNome',

  setCognome: 'setCognome',
  setErrorCognome: 'setErrorCognome',

  setEmail: 'setEmail',
  setErrorEmail: 'setErrorEmail',

  setRuolo: 'setRuolo',
  setErrorRuolo: 'setErrorRuolo'
}

const initialState = {
  nome: {
    value: '',
    error: false
  },
  cognome: {
    value: '',
    error: false
  },
  email: {
    value: '',
    error: false
  },
  ruolo: {
    value: 'ADMIN',
    error: false
  }
}

const init = () => initialState;

const reducer = (state, action) => {
  switch (action.type) {
    case actions.init:
      return init();
    case actions.setInitialState: {
      return {
        nome: {
          value: action.nome,
          error: false
        },
        cognome: {
          value: action.cognome,
          error: false
        },
        email: {
          value: action.email,
          error: false
        },
        ruolo: {
          value: action.ruolo,
          error: false
        }
      }
    }
    case actions.setNome: {
      return {
        ...state,
        nome: {
          value: action.value,
          error: action.value === ""
        }
      }
    }
    case actions.setCognome: {
      return {
        ...state,
        cognome: {
          value: action.value,
          error: action.value === ""
        }
      }
    }
    case actions.setEmail: {
      return {
        ...state,
        email: {
          value: action.value,
          error: action.value === "" && !action.value.includes('@')
        }
      }
    }
    case actions.setRuolo: {
      return {
        ...state,
        ruolo: {
          value: action.value,
          error: action.value === ""
        }
      }
    }

    case actions.setErrorNome: {
      return {
        ...state,
        nome: {
          value: state.nome.value,
          error: true
        }
      }
    }
    case actions.setErrorCognome: {
      return {
        ...state,
        cognome: {
          value: state.cognome.value,
          error: true
        }
      }
    }
    case actions.setErrorEmail: {
      return {
        ...state,
        email: {
          value: state.email.value,
          error: true
        }
      }
    }
    case actions.setErrorRuolo: {
      return {
        ...state,
        ruolo: {
          value: state.ruolo.value,
          error: true
        }
      }
    }
    default:
      return state;
  }
}

const errorMessage = <small style={{
  color: 'red',
  fontSize: '12px',
  position: 'absolute'
}}>Campo obbligatorio</small>

const FormUtente = (props) => {

  FormUtente.propTypes = {
    selectedUser: PropTypes.shape({}),
    removeSelectedUser: PropTypes.func.isRequired,
    toggleForm: PropTypes.func.isRequired,
    updateTabella: PropTypes.func.isRequired
  }

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [valid, setValid] = React.useState(true);

  const {
    toggleToast,
    toggleSpinner,
    toggleForm,
    updateTabella,
    selectedUser,
    removeSelectedUser
  } = props;

  const checkValidity = () => {
    const {nome, cognome, email, ruolo} = state;
    let error;
    if (nome.value === "") {
      dispatch({type: actions.setErrorNome});
      setValid(false);
      error = true;
    }
    if (cognome.value === "") {
      dispatch({type: actions.setErrorCognome});
      setValid(false);
      error = true;
    }
    if (email.value === "" || !email.value.includes('@')) {
      dispatch({type: actions.setErrorEmail})
      setValid(false);
      error = true;
    }
    if (ruolo.value === "") {
      dispatch({type: actions.setErrorRuolo});
      setValid(false);
      error = true;
    }
    if (!error) {
      if (selectedUser) {
        putUtente();
      } else {
        saveUtente()
      }
    }
  }

  const putUtente = () => {
    const {nome, cognome, email, ruolo} = state;
    let utenteRequest = {
      nome: nome.value,
      cognome: cognome.value,
      email: email.value,
      ruoloUtente: ruolo.value
    }
    toggleSpinner();
    axiosInstance.put(utentePutURL(selectedUser.id), utenteRequest)
      .then(r => {
        if(r.status === 200) {
          updateTabella();
          toggleForm();
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => toggleSpinner())
  }

  const saveUtente = () => {
    const {nome, cognome, email, ruolo} = state;
    let utenteRequest = {
      nome: nome.value,
      cognome: cognome.value,
      email: email.value,
      ruoloUtente: ruolo.value
    }
    toggleSpinner();
    axiosInstance.post(utentePostURL, utenteRequest)
      .then(r => {
        if (r.status === 200) {
          updateTabella();
          toggleForm();
          const {data: {nome, cognome}} = r;
          toggleToast('s', `Utente "${nome} ${cognome}" creato con successo`);
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => toggleSpinner())
  }


  /**
   *  Controlla e aggiorna l'utente selezionato in tabella
   */
  React.useEffect(() => {
    if (selectedUser) {
      dispatch({
        type: actions.setInitialState,
        nome: selectedUser.nome,
        cognome: selectedUser.cognome,
        email: selectedUser.email,
        ruolo: selectedUser.ruoloUtente
      })
    }

    // Clean the state when comp unmount
    return () => dispatch({type: actions.init})
  }, [selectedUser])

  const {nome, cognome, email, ruolo} = state;

  return (
    <div className="input-utenti">
      <div className="input-group">
        <div>
          <label>Nome</label>
          <input
            type="text"
            onChange={e => dispatch({type: actions.setNome, value: e.target.value})}
            value={nome.value}
            className="form-control form-control-sm"
            placeholder="Nome"
          />
          {nome.error ? errorMessage : null}
        </div>
        <div>
          <label>Cognome</label>
          <input
            type="text"
            onChange={e => dispatch({type: actions.setCognome, value: e.target.value})}
            value={cognome.value}
            className="form-control form-control-sm"
            placeholder="Cognome"
          />
          {cognome.error ? errorMessage : null}
        </div>
        <div>
          <label>E-mail</label>
          <input
            type="email"
            onChange={e => dispatch({type: actions.setEmail, value: e.target.value})}
            value={email.value}
            className="form-control form-control-sm"
            placeholder="utente@domain.it"
          />
          {email.error ? errorMessage : null}
        </div>
        <div>
          <label>Ruolo</label>
          <select
            value={ruolo.value}
            className="form-control form-control-sm"
            onChange={e => dispatch({type: actions.setRuolo, value: e.target.value})}
          >
            {
              ruoli.map((r, i) => {
                return <option key={i} value={r}>{r}</option>
              })}
          </select>
          {ruolo.error ? errorMessage : null}
        </div>
      </div>
      <div className="btn-container">
        <button className="btn btn-azure btn-sm"
                onClick={checkValidity}
        >SALVA
        </button>
        <button className="btn outline-azure btn-sm"
                onClick={() => {
                  removeSelectedUser();
                  dispatch({type: actions.init})
                  toggleForm();
                }}
        >
          ANNULLA
        </button>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    toggleToast: (s, m) => dispatch(toggleToast(s, m)),
    toggleSpinner: () => dispatch(toggleSpinner())
  }
}

export default connect(null, mapDispatchToProps)(FormUtente);
