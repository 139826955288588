import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Anteprima(props) {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState();

    const {file} = props;

    return (
        <div id="document-container">
            {/*node_modules\react-pdf\dist\Document riga 508*/}
            <h6 className="color-main-color">Anteprima</h6>
            <Document
                renderMode="svg"
                file={file}
                loading="Caricamento PDF..."
                onLoadSuccess={numPages => setNumPages(numPages)}
            >
                <Page scale={0.7} pageNumber={pageNumber}/>
            </Document>
        </div>
    );
}

export default Anteprima;
