import React from 'react';

const dati = [
  {
    titolo: "FORUM",
    sottotitolo: "ANIA Consumatori",
    link: "http://www.forumaniaconsumatori.it/",
    fax: " 06 91622588",
    telefono: "06 326881"
  },
  {
    titolo: "FONDAZIONE",
    sottotitolo: "ANIA",
    link: "http://www.fondazioneania.it/home",
    fax: " 06 91622567",
    telefono: "06 326881"
  },
  {
    titolo: "ANIA",
    sottotitolo: "SAFE",
    link: "http://www.aniasafe.it/",
    fax: " 06 91622567",
    telefono: "06 326881"
  }
];
const datiDx = {
  titolo: "ANIA",
  sottotitolo: "Associazione Nazionale fra le Imprese Assicuratrici",
  via: "Via di San Nicola da Tolentino, 72",
  cap: "00187 Roma",
  tel: "Tel. 06 326881",
  fax: "Fax 06 3227135",
  //direttore: "Gabriella Carmagnola",
  link: "http://www.ania.it/protezione-dei-dati-personali-applicata-nel-sito-dell-ania",
  linkDue: "https://www.ania.it/web/ania/informativa-privacy-ania-ed-enti-collegati" //non usato
};

function Footer() {
  return (
    <div id="footer-container">
      <div id="footer-left"
        className="col-sm-6 col-lg-8">
        <div className="row">
          {
            React.Children.toArray(dati.map(dato => (
              <div className="col-lg-4 footer-data-wrapper">
                <div className="titolo-wrapper">
                  <h4 className="color-bianco title">{dato.titolo}</h4>
                  <p className="color-banner-input"><b>{dato.sottotitolo}</b></p>
                </div>
                <div className="indirizzo">
                  <p className="color-banner-input">Via di San Nicola da Tolentino, 72</p>
                  <p className="color-banner-input cap">00187 Roma</p>
                  <p>&nbsp;</p>
                  <p className="color-banner-input riferimenti">Tel.{dato.telefono}</p>
                  <p className="color-banner-input riferimenti">Fax{dato.fax}</p>
                </div>
                <div>
                  <a href={dato.link} target="_blank" rel="noopener noreferrer" className="color-bianco">Vai al sito {'>'}</a>
                </div>
              </div>
            )))
          }
        </div>
      </div>
      <div id="footer-right"
        className="col-sm-6 col-lg-4 bg-dark-blue">
        <div className="row">
          <div className="col footer-data-wrapper">
            <div className="titolo-wrapper">
              <h4 className="color-bianco title">{datiDx.titolo}</h4>
              <p className="color-banner-input">{datiDx.sottotitolo}</p>
            </div>
            <div className="indirizzo">
              <p className="color-banner-input">{datiDx.via}</p>
              <p className="color-banner-input">{datiDx.cap}</p>
              <p>&nbsp;</p>
              <p className="color-banner-input">{datiDx.tel}</p>
              <p className="color-banner-input">{datiDx.fax}</p>
            </div>
            {/* <div>
              <p className="color-banner-input">Direttore Responsabile: {datiDx.direttore}</p>
            </div> */}

            <div>
              <h6 className="color-bianco">PRIVACY-POLICY</h6>
              <a href={datiDx.link} target="_blank" rel="noopener noreferrer" className="color-bianco link">Protezione dei dati personali applicata nel sito dell'ANIA</a>
              <a href={datiDx.linkDue} target="_blank" rel="noopener noreferrer" className="color-bianco link">Informativa privacy ANIA ed Enti collegati</a>
            </div>
          </div>
        </div>
        <span className="color-dark-blue">ver. 1</span>
      </div>
    </div>
  );
}

export default Footer;
