import React, { useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;
const ItemElement = styled.div`
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  margin: 0 auto;
  background-color: #ffffff;
  padding: ${grid}px;
`;

function Item({ item, index, selezioneArticolo }) {
  return (
    <Draggable draggableId={item.id} index={index}>
      {provided => (
        <ItemElement
          {...index}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="row">
            <div className="col-1">
              {/*ICONA*/}
              <i className="fas fa-file mr-2" style={{ color: '#d3462f' }} />
            </div>
            <div className="col-9 p-0">
              {/*TITOLO*/}
              <p className="color-main-text mb-0">
                {item.titoloArticolo}
                <br />
                <span className="color-main-color font-weight-bold" style={{fontSize: '12px'}}>
                  #{item.categoriaArticoloRes.nomeCategoria}
                </span>
              </p>
            </div>
            <div className="col-1 text-center">
              {/*ICONA*/}
              <i className="fas fa-ellipsis-v color-main-color" />
            </div>
            <div className="col-1 text-center cursor-pointer" onClick={selezioneArticolo}>
              {/*ICONA*/}
              <i className="fas fa-times color-rosso" aria-label="Cancella" />
            </div>
          </div>
        </ItemElement>
      )}
    </Draggable>
  );
}

const ItemList = React.memo(function ItemList({ items, selezioneArticolo }) {
  return items.map((item, index) => (
    <Item item={item} index={index} key={item.id} selezioneArticolo={() => selezioneArticolo(item)} />
  ));
});

function DraggableListArticolo(props) {

  const { items, setOrder, selezioneArticolo } = props;

  const [state, setState] = useState({ items });

  React.useEffect(() => {
    setState({ items })
  }, [items])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };


  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index
    );
    setState({ items });
  }

  //  Formatto i dati per la chiamata di pubblicazione e li passo al componente padre
  //  L'indice è a partire da 1
  const formatValues = () => setOrder(state.items.map((item, i) => ({ idArticolo: item.id, posizione: i + 1 })));

  React.useEffect(() => {
    formatValues();
  }, [state.items]) // eslint-disable-line

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div
            ref={provided.innerRef} {...provided.droppableProps}>
            <ItemList items={state.items} selezioneArticolo={selezioneArticolo} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableListArticolo;
