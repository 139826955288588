/**
 * 0: visibile a tutti gli utenti (non autenticati, autenticati e autenticati con privilegi)
 * 1: visibile solo agli utenti non autenticati
 * 2: visibile a tutti utenti autenticati
 * 3: visibile solo agli utenti autenticati con un determinato ruolo
 * Per impostare un path nei route senza farlo comparire nei link basta mettere il name come stringa vuota
 */

import Home from "../pages/Home/Home";
import GestioneUtenti from "../pages/GestioneUtenti/GestioneUtenti";
import NotFound from "../pages/NotFound/NotFound";
import RevisioneArticoli from "../pages/RevisioneArticoli/RevisioneArticoli";
import UltimePubblicazioni from "../pages/UltimePubblicazioni/UltimePubblicazioni";
import NuovoArticolo from "../pages/NuovoArticolo/NuovoArticolo";
import GestioneCategoria from "../pages/GestioneCategoria/GestioneCategoria";
import GestioneLibri from "../pages/GestioneLibri/GestioneLibri";
import AccountActivation from "../pages/AccountActivation/AccountActivation";
import LoginContainer from "../pages/Login/LoginContainer";
import GestioneModelliLibro from "../pages/GestioneModelliLibro/GestioneModelliLibro";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Libri from '../pages/Libri/Libri';
import PrimoPiano from "../pages/PrimoPiano/PrimoPiano";
import PrintArticle from '../pages/PrintArticle/PrintArticle';

const routes = [
    {
        path: "/",
        name: "",
        // name: "HOME",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: Home,
        access: [2],
        private: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        }
    },
    {
        path: "/404",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: NotFound,
        access: [0],
        private: false,
        roles: ["ADMIN"],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    // {
    //     path: "/nuovo-libro",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: NuovoLibro,
    //     access: [3],
    //     private: false,
    //     roles: ["ADMIN"],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/revisione",
    //     name: "Revisione",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Revisione,
    //     access: [3],
    //     private: false,
    //     roles: ["ADMIN"],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
  {
    path: "/gestione-modelli",
    name: "Modelli",
    isExternalPage: false,
    logo: {
      icon: null,
      img: null,
      svg: null
    },
    component: GestioneModelliLibro,
    access: [3],
    private: false,
    roles: ["ADMIN"],
    style: {
      color: '#005A8E',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      opacity: .5
    },
  },
    {
        path: "/gestione-libri",
        name: "Libri",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: GestioneLibri,
        access: [3],
        private: false,
        roles: ["ADMIN"],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/nuovo-articolo",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: NuovoArticolo,
        access: [3],
        private: false,
        roles: ["ADMIN", "EDITOR"],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
      path: "/revisione-articoli",
      name: "Articoli",
      isExternalPage: false,
      logo: {
        icon: null,
        img: null,
        svg: null
      },
      component: RevisioneArticoli,
      access: [3],
      private: false,
      roles: ["ADMIN", "EDITOR"],
      // style: {
      //   color: '#005A8E',
      //   fontWeight: 'bold',
      //   textTransform: 'uppercase',
      //   opacity: .5
      // }
    },
    {
      path: "/ultime-pubblicazioni",
      name: "",
      isExternalPage: false,
      logo: {
        icon: null,
        img: null,
        svg: null
      },
      component: UltimePubblicazioni,
      access: [2],
      private: false,
      roles: [],
      // style: {
      //   color: '#005A8E',
      //   fontWeight: 'bold',
      //   textTransform: 'uppercase',
      //   opacity: .5
      // }
    },
    {
        path: "/gestione-categoria",
        name: "Categorie",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: GestioneCategoria,
        access: [3],
        private: false,
        roles: ["ADMIN"],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
  {
    path: "/gestione-utenti",
    name: "Utenti",
    isExternalPage: false,
    logo: {
      icon: null,
      img: null,
      svg: null
    },
    component: GestioneUtenti,
    access: [3],
    private: false,
    roles: ["ADMIN"],
    style: {
      color: '#005A8E',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      opacity: .5
    },
  },
  {
    path: "/login",
    name: "",
    isExternalPage: false,
    logo: {
      icon: null,
      img: null,
      svg: null
    },
    component: LoginContainer,
    access: [1],
    private: false,
    roles: [],
    // style: {
    //   color: '#005A8E',
    //   fontWeight: 'bold',
    //   textTransform: 'uppercase',
    //   opacity: .5
    // },
  },
  {
    path: "/attivazione/:token",
    name: "",
    logo: {
      icon: null,
      img: null,
      svg: null
    },
    component: AccountActivation,
    access: [1],
    private: false,
    roles: [],
    button: {
      enable: true,
      style: {
        color: '#005A8E',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }
    }
  },
  {
    path: "/password-reset/:token",
    name: "",
    logo: {
      icon: null,
      img: null,
      svg: null
    },
    component: ResetPassword,
    access: [1],
    private: false,
    roles: [],
    button: {
      enable: true,
      style: {
        color: '#005A8E',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }
    }
  },
  {
    path: "/libri",
    name: "",
    logo: {
      icon: null,
      img: null,
      svg: null
    },
    component: Libri,
    access: [2],
    private: false,
    roles: [],
    button: {
      enable: true,
      style: {
        color: '#005A8E',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }
    }
  },
  {
    path: "/primo-piano",
    name: "",
    logo: {
      icon: null,
      img: null,
      svg: null
    },
    component: PrimoPiano,
    access: [2],
    private: false,
    roles: [],
    button: {
      enable: true,
      style: {
        color: '#005A8E',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }
    }
  },
  {
    path: "/print-article/:id",
    name: "",
    logo: {
      icon: null,
      img: null,
      svg: null
    },
    component: PrintArticle,
    access: [2],
    private: false,
    roles: [],
    button: {
      enable: true,
      style: {
        color: '#005A8E',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }
    }
  }
];

export default routes;
