import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
                      itemsPerPage,
                      setItemsPerPage,
                      totalItems,
                      paginate,
                      currentPage,
                      totalPages
                    }) => {

  Pagination.propTypes = {
    itemsPerPage: PropTypes.number.isRequired,
    setItemsPerPage: PropTypes.func.isRequired,
    totalItems: PropTypes.number.isRequired,
    paginate: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
  }

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  let indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  if (indexOfLastItem > totalItems) indexOfLastItem = totalItems;

  return (
    <div id="paginazione-component" className="margin-top-20">
      <div className="pages-dropdown">
        <select className="form-control form-control-sm"
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                value={itemsPerPage}
                data-toggle="dropdown"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
        <span className="pagination-text mt-2">
          Sono visualizzati da {indexOfFirstItem + 1} a {indexOfLastItem} di {totalItems} elementi
        </span>
      </div>
      <div className="page-numbers">
        <nav>
          <ul className='pagination'>
            {
              totalPages === 1 ? null :
                <>
                  <li className="page-item">
                    <button
                      onClick={() => {
                        if (currentPage === 0) return;
                        paginate(0)
                      }}
                      className="page-link" aria-label="Previous"
                    >
                      <span aria-hidden="true">primo</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      onClick={() => {
                        if (currentPage === 0) return;
                        paginate(currentPage - 1)
                      }}
                      className="page-link" aria-label="Previous"
                    >
                      <i className="fas fa-chevron-left"/>
                    </button>
                  </li>
                </>
            }

            {pageNumbers && pageNumbers.length > 1 && pageNumbers.map(number => (
              <li key={number} className='page-item'>
                <button
                  onClick={() => paginate(number - 1)}
                  className={(currentPage + 1) === number ? "page-link current" : "page-link"}
                >
                  {number}
                </button>
              </li>
            ))}

            {
              totalPages === 1 ? null :
                <>
                  <li className="page-item">
                    <button
                      onClick={() => {
                        if (currentPage === (totalPages -1)) return;
                        paginate(currentPage + 1)
                      }}
                      className="page-link" aria-label="Next"
                    >
                      <i className="fas fa-chevron-right"/>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      onClick={() => {
                        if (currentPage === (totalPages -1)) return;
                        paginate(totalPages - 1)
                      }}
                      className="page-link" aria-label="Next"
                    >
                      <span aria-hidden="true">ultimo</span>
                    </button>
                  </li>
                </>
            }
          </ul>
        </nav>
      </div>

    </div>
  );
};

export default Pagination;
