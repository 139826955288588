import React, { useState, useEffect, useReducer } from 'react';
import axiosInstance from '../../api/interceptor';
import Articolo from "../../components/Articolo/Articolo";
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import Pagination from "../../components/Pagination/Pagination";
import ModalComposizioneArticoli from '../../components/ComponiRaccolta/ModalComponiRaccolta';
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';
import Swal from "sweetalert2";

const bootstrapSpinner = (<div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>)

const actions = {
  setState: 'setState',
  setArticoli: 'setArticoli',
  setCurrentPage: 'setCurrentPage',
  setTotalItems: 'setTotalItems',
  setTotalPages: 'setTotalPages',
  setItemsPerPage: 'setItemsPerPage'
}

const initialState = {
  articoli: [],
  articoliPerPage: 10,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.setState: {
      const { articoli, totalItems, totalPages } = action.value;
      return {
        ...state,
        articoli,
        totalItems,
        totalPages
      }
    }
    case actions.setItemsPerPage: {
      return {
        ...state,
        articoliPerPage:
          action.value,
        currentPage: 0
      }
    }
    case actions.setCurrentPage: {
      return {
        ...state, currentPage: action.value
      }
    }
    default:
      return state;
  }
}

function UltimePubblicazioni({ history, filterArticoliParams, toggleToast, toggleSpinner }) {
  // Gestione visiva del loading di articoli
  const [msgAllArticoli, setMsgAllArticoli] = React.useState(bootstrapSpinner);

  // MODAL COMPONI
  const [openModalComponi, setOpenModalComponi] = useState(false);
  const toggleModalComponi = () => setOpenModalComponi(!openModalComponi);

  // GESTIONE DELLA COMPOSIZIONE DEGLI ARTICOLI
  const [componi, setComponi] = useState(false);
  const [articoliSelezionati, setArticoliSelezionati] = useState([]);
  const [templateFirstSelected, setTemplateFirstSelected] = useState(''); // Variabile per capire il template del primo articolo selezionato

  function selezioneArticolo(articolo) {
    // Se non ho ancora selezionato articoli setto il template alla prima selezione
    if(articoliSelezionati.length === 0) setTemplateFirstSelected(articolo.templateModelloLibro)

    // Cliccando sull'articolo se era selezionato lo tolgo dall'array, se non lo era lo aggiungo
    articoliSelezionati.some(el => articolo.id === el.id)
      ? setArticoliSelezionati(articoliSelezionati.filter(el => articolo.id !== el.id))
      : setArticoliSelezionati([...articoliSelezionati, articolo])
  }

  function checkArticoloSelezionato(articolo) {
    if (componi) return articoliSelezionati.some(el => articolo.id === el.id)
  }

  function componiArticoli() {
    if (articoliSelezionati.length > 0) {
      setOpenModalComponi(true)
    } else Swal.fire({
      title: `<br />`,
      text: 'Per procedere si deve selezionare almeno un articolo',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: 'Chiudi',
      confirmButtonAriaLabel: 'Chiudi'
    })
  }

  // GESTIONE CHIAMATA DB E PAGINAZIONE
  const [state, dispatch] = useReducer(reducer, initialState, () => initialState)

  const paginate = pageNumber => dispatch({ type: actions.setCurrentPage, value: pageNumber });
  const setArticoliPerPage = upp => dispatch({ type: actions.setItemsPerPage, value: upp });

  // GESTIONE QUERY PARAMS
  const getSortParams = () => {
    let params = `/articoli/pubblicati`;
    const { articoliPerPage, currentPage } = state;
    // ${filterArticoliParams} è la query string con i filtri di ricerca salvati in redux. Di default è vuota
    params += `?size=${articoliPerPage}&page=${currentPage}${filterArticoliParams}`;
    return params;
  }

  // UPDATE DATI UN USEREDUCER
  const setState = values => {
    const { content, totalElements, totalPages } = values;
    dispatch({
      type: actions.setState,
      value: {
        articoli: content,
        totalItems: totalElements,
        totalPages: totalPages,
      }
    })
  }

  // Richiama i dati del DB quando cambia la paginazione
  useEffect(() => {
    toggleSpinner();
    axiosInstance.get(getSortParams())
      .then(({ data }) => setState(data))
      .catch(({ messaggio }) => {
        toggleToast('e', messaggio);
      })
      .finally(() => {
        setMsgAllArticoli('Nessun articolo pubblicato');
        toggleSpinner()
      })
  }, [state.articoliPerPage, state.currentPage, filterArticoliParams]); // eslint-disable-line

  // Resetta i dati degli articoli selezionati
  useEffect(() => { if (!componi) setArticoliSelezionati([]) }, [componi]);

  return (
    <div id="revisione-articoli" className="container">
      <BackToPrevious goBack={() => history.goBack()} />

      <div id="libri-container">
        <div className="row">
          <div className="title col-md-6">
            <i className="fas fa-th" />
            Tutti gli articoli pubblicati
          </div>
          <div className="col-md-6">
            <small className="font-italic text-secondary float-right padding-right-10 go-to color-main-color cursor-pointer" onClick={() => setComponi(!componi)}>
              <i className="far fa-copy text-danger" /> Componi
            </small>
          </div>
        </div>

        { /* Barra visibile solo in modalità componi */
          !componi ? null :
            <div className="row color-bianco ml-0 mr-0 mt-3 pt-2 pb-2" style={{ backgroundColor: "#005A8E" }}>
              <div className="col-md-4 d-flex align-items-center text-uppercase" style={{ fontFamily: "OpenSans-ExtraBold" }}>crea una raccolta</div>
              <div className="col-md-4 d-flex justify-content-center align-items-center" style={{ fontFamily: "OpenSans-ExtraBold" }}>Selezionati: {articoliSelezionati.length}</div>
              <div className="col-md-2 d-flex justify-content-center">
                <button className="btn btn-dark-blue text-uppercase font-weight-bold" onClick={() => componiArticoli()} >
                  <i className="fa fa-check mr-2" aria-label="Conferma" />conferma
              </button>
              </div>
              <div className="col-md-2 d-flex justify-content-center">
                <button className="btn btn-grigio-chiaro text-uppercase font-weight-bold" onClick={() => setComponi(false)}>
                  <i className="fa fa-times mr-2" aria-label="Annulla" />annulla
              </button>
              </div>
            </div>
        }

        <div className="libri-container">
          {(!state.articoli || state.articoli.length === 0)
            ? (<div className="nessuna-pubblicazione justify-content-center align-items-center color-main-color">
              {msgAllArticoli}
            </div>)
            : state.articoli.map(a => (<Articolo
              key={a.id}
              {...a}
              componi={componi}
              selezioneArticolo={() => selezioneArticolo(a)}
              checkArticoloSelezionato={() => checkArticoloSelezionato(a)}
              disabled={articoliSelezionati.length === 0 ? false : a.templateModelloLibro !== templateFirstSelected}
            />))}
        </div>
      </div>
      {(!state.articoli || state.articoli.length === 0)
        ? null
        : <Pagination
          itemsPerPage={state.articoliPerPage}
          totalItems={state.totalItems}
          currentPage={state.currentPage}
          totalPages={state.totalPages}
          setItemsPerPage={setArticoliPerPage}
          paginate={paginate}
        />
      }

      <ModalComposizioneArticoli
        openModalComponi={openModalComponi}
        toggleModalComponi={toggleModalComponi}
        articoliSelezionati={articoliSelezionati}
        selezioneArticolo={selezioneArticolo}
        templateFirstSelected={templateFirstSelected}
      />

    </div>
  );
}

function mstp(state) {
  return {
    filterArticoliParams: state.filterArticoliParams
  }
}

export default connect(mstp, { toggleToast, toggleSpinner })(UltimePubblicazioni);
