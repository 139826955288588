import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";

const ArticoloDIV = styled.div`
  margin-bottom: 0.5em;
  padding: 0.5em;
  color: #808285;//$main-text
  box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 1px 3px rgba(0,0,0,0.23);
  border-radius: 2px;
`;

const ArticoloItem = props => {
  const {titoloArticolo} = props;
  return <ArticoloDIV>
    <i className="fas fa-file mr-2 color-main-color"
    />{titoloArticolo}
  </ArticoloDIV>;
}

const ListaArticoliAssociati = props => {
  const {
    open,
    toggle,
    articoli,
    title,
    text,
    onConfirm,
    onDeny,
    confirmButtonText,
    denyButtonText,
  } = props;

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader
        className="color-main-color"
        toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <p className="my-2 color-azure">{text}</p>
        {React.Children.toArray(articoli.map(a => <ArticoloItem {...a}/>))}
      </ModalBody>
      <ModalFooter>
        {
          onConfirm && <button
            className="btn btn-azure text-uppercase btn-sm"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </button>
        }
        {
          onDeny && <button
            className="btn outline-azure text-uppercase btn-sm"
            onClick={onDeny}
          >
            {denyButtonText}
          </button>
        }
      </ModalFooter>
    </Modal>
  );
};

ListaArticoliAssociati.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  articoli: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onDeny: PropTypes.func,
  confirmButtonText: PropTypes.string,
  denyButtonText: PropTypes.string,
};

export default ListaArticoliAssociati;
