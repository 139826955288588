import { GET_LIVELLI_IMPORTANZA_ARTICOLI, GET_TEMATICHE_ARTICOLI, GET_TEMPLATES_MODELLI, GET_AUTORI, GET_LISTA_ARTICOLI, GET_STATI_UPDATE_ARTICOLO } from '../actions/staticData';

export const getTemplateModelli = payload => ({
  type: GET_TEMPLATES_MODELLI,
  payload
})

export const getTematicheArticoli = payload => ({
  type: GET_TEMATICHE_ARTICOLI,
  payload
})

export const getImportanzaArticoli = payload => ({
  type: GET_LIVELLI_IMPORTANZA_ARTICOLI,
  payload
})

export const getAutori = payload => ({
  type: GET_AUTORI,
  payload
})

export const getListaArticoli = payload => ({
  type: GET_LISTA_ARTICOLI,
  payload
})

export const getStatiUpdateArticolo = payload => ({
  type: GET_STATI_UPDATE_ARTICOLO,
  payload
})
