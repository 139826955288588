import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axiosInstance from '../../api/interceptor';
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import Swal from "sweetalert2";
import MultiSelect from "react-multi-select-component";
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';
import { statoPubblicazioneLibro, statoPubblicazioneArticolo, enumImportanza, enumTemplate } from '../../assets/utils/enum';

// DatePicker and multiselect imports
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { addMonths } from "date-fns";
import it from 'date-fns/locale/it';

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
Quill.register('modules/imageResize', ImageResize);


const bootstrapSpinner = (<div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>)

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    ['link', 'image']
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar']
  }
}

const modulesIvass = {
  toolbar: [
    [{ 'header': [false] }]
  ]
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'indent',
  'align', 'center', 'right', 'justify',
  'link', 'image'
]

// Conversione data a YYYY-MM-DD
function convertDate(str) {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function NuovoArticolo(props) {
  const { toggleToast, toggleSpinner, history, tematicheArticoli, importanzaArticoli, listaAutori, listaArticoli, statiUpdateArticolo } = props;

  // Variabile passata via location con tutte le info dell'articolo che vogliamo modificare
  const { modifyArticleProps } = props.location;

  // settare italiano come locale per configurare Datepicker
  registerLocale('it', it);

  // Verifica numeri libri fetchati dal DB con messaggio di loading e di assenza di libri
  const [noLibri, setNoLibri] = useState(true);
  const [landingMessage, setLandingMessage] = useState(bootstrapSpinner);

  // States relativi ai campi della form
  const [titoloArticolo, setTitoloArticolo] = useState('');
  const [listaLibri, setListaLibri] = useState(null);
  const [selectedLibro, setSelectedLibro] = useState(null);
  const [listCategorie, setListCategorie] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState(null);

  const [selectedAutore, setSelectedAutore] = useState('');
  const [selectedImportanza, setSelectedImportanza] = useState('75939501-fb86-4538-993b-8ed75494a12d');
  const [selectedTematica, setSelectedTematica] = useState([]);
  const [dataCommentiAnia, setDataCommentiAnia] = useState(null);
  const [dataCommentiIvass, setDataCommentiIvass] = useState(null);
  const [selectedArticoliStessoLibro, setSelectedArticoliStessoLibro] = useState([]);
  const [selectedArticoliNoStessoLibro, setSelectedArticoliNoStessoLibro] = useState([]);
  const [selectedStatoUpdateArticolo, setSelectedStatoUpdateArticolo] = useState('');
  const [referenti, setReferenti] = useState('');

  const [boxContent, setBoxContent] = useState([]);

  const [templateSelectedLibro, setTemplateSelectedLibro] = useState("");

  // Validators dei campi obbligatori
  const [titoloValidation, setTitoloValidation] = useState({ isValid: false, errorMsg: 'Campo obbligatorio' });
  const [boxValidation, setBoxValidation] = useState({ isValid: false, errorMsg: `Compila almeno uno dei box qui sotto` });
  const [dataAniaValidation, setDataAniaValidation] = useState({ isValid: false, errorMsg: 'Campo obbligatorio' });
  const [formSubmitted, setformSubmitted] = useState(false);

  // Controllo di validazione della form
  useEffect(() => {
    checkValidation();
  }, [titoloArticolo, boxContent, dataCommentiAnia]); // eslint-disable-line

  function checkValidation() {
    !titoloArticolo ? setTitoloValidation({ ...titoloValidation, isValid: false }) : setTitoloValidation({ ...titoloValidation, isValid: true });

    (boxContent.length !== 0 && boxContent.some(b => b.contenuto && b.contenuto !== "<p><br></p>"))
      ? setBoxValidation({ ...boxValidation, isValid: true }) : setBoxValidation({ ...boxValidation, isValid: false });

    templateSelectedLibro === enumTemplate.CRITICITA_PROPOSTE
      ? setDataAniaValidation({ ...dataAniaValidation, isValid: true })
      : dataCommentiAnia ? setDataAniaValidation({ ...dataAniaValidation, isValid: true }) : setDataAniaValidation({ ...dataAniaValidation, isValid: false })
  }

  // Messaggio di alert se la validazione non va a buon fine
  function warning() {
    Swal.fire({
      title: "Attenzione!",
      text: 'Si prega di compilare tutti i campi obbligatori',
      showCloseButton: true,
      focusConfirm: true
    })
  }

  // Messaggio di alert se il testo incollato nel box Sintesi Ania supera i 2100 caratteri
  function warningTextLength() {
    Swal.fire({
      title: "Attenzione!",
      text: 'Il testo supera il limite di 2100 caratteri',
      showCloseButton: true,
      focusConfirm: true
    })
  }

  // Setting inziale dei campi in base al fetchiamo dei libri. Setting leggermente diverso se siamo in modifica di una articolo
  useEffect(() => {
    getLibri();
    if (modifyArticleProps) {
      setTitoloArticolo(modifyArticleProps.titoloArticolo);
      setSelectedLibro(modifyArticleProps.idLibro);
      setSelectedAutore(modifyArticleProps.autore.id);
      setSelectedImportanza(modifyArticleProps.livelloImportanzaArticoloId);
      setSelectedTematica(compareFormatSelectedTematica(tematicheArticoli.map(el => ({ value: el.id, label: el.label })), modifyArticleProps.tematicaArticoloId))
      setSelectedArticoliStessoLibro(modifyArticleProps.listaArticoliStessoLibro.map(el => ({ value: el, label: '' })));
      setSelectedArticoliNoStessoLibro(modifyArticleProps.listaArticoliAltriLibri.map(el => ({ value: el, label: '' })));
      setListCategorie([{ ...modifyArticleProps.categoriaArticoloRes }]);
      setSelectedCategoria(modifyArticleProps.categoriaArticoloRes.id);
      setReferenti(modifyArticleProps.referenti ? modifyArticleProps.referenti : '');
      if (modifyArticleProps.dataCommentiAnia) setDataCommentiAnia(new Date(modifyArticleProps.dataCommentiAnia));
      if (modifyArticleProps.dataCommentiIvass) setDataCommentiIvass(new Date(modifyArticleProps.dataCommentiIvass));
      if (modifyArticleProps.statoAggiornamento) setSelectedStatoUpdateArticolo(modifyArticleProps.statoAggiornamento.id);
      let temp = modifyArticleProps.boxTesto.map(b => ({ idBoxTesto: b.idBoxTestoCat, titoloBoxTesto: b.titoloContenitoreBox, posizione: b.posizioneBoxTestoCat, contenuto: b.contenutoBoxTesto, }))
      setBoxContent(temp)
    }
  }, [modifyArticleProps]); // eslint-disable-line


  // Metodo per settare i valori degli articoli e delle tematiche selezionati nel caso sia in modifica articolo
  function compareFormatSelectedArticoli(arr1, arr2) {
    const finalarray = [];
    arr1.forEach(e1 => arr2.forEach(e2 => { if (e1.value === e2.value) finalarray.push(e1) }))
    return finalarray
  }

  function compareFormatSelectedTematica(arr1, arr2) {
    const finalarray = [];
    arr1.forEach(e1 => arr2.forEach(e2 => { if (e1.value === e2) finalarray.push(e1) }))
    return finalarray
  }

  // Aggiorniamo la lista degli articoli dello stesso libro (e non) quando cambia il libro selezionato
  useEffect(() => {
    if (selectedLibro) {
      const temp = compareFormatSelectedArticoli(listaArticoli.filter(el => el.idLibro !== selectedLibro).map(el => ({ value: el.idArticolo, label: el.nomeLibro + ' - ' + el.titoloArticolo })), selectedArticoliNoStessoLibro)
      setSelectedArticoliNoStessoLibro(temp);

      const temp1 = compareFormatSelectedArticoli(listaArticoli.filter(el => el.idLibro === selectedLibro).map(el => ({ value: el.idArticolo, label: el.nomeLibro + ' - ' + el.titoloArticolo })), selectedArticoliStessoLibro)
      setSelectedArticoliStessoLibro(temp1);

      setTemplateSelectedLibro(listaLibri?.filter(el => el.id === selectedLibro)[0].modelloLibroRes.template)
    }
  }, [selectedLibro]); // eslint-disable-line

  // Settiamo le categorie in base al modello di libro selezionato
  useEffect(() => {
    if (!modifyArticleProps) {
      if (listaLibri && selectedLibro) {
        listaLibri.forEach(l => {
          if (l.id === selectedLibro) {
            const categorie = l.modelloLibroRes.categorie.sort((a, b) => a.nomeCategoria.toLowerCase() > b.nomeCategoria.toLowerCase() ? 1 : -1);
            setListCategorie(categorie);
            setSelectedCategoria(categorie[0].id);
            setTemplateSelectedLibro(listaLibri?.filter(el => el.id === selectedLibro)[0].modelloLibroRes.template)
          }
        });
      }
    }
  }, [selectedLibro, listaLibri, modifyArticleProps]);

  // Settiamo il contenuto dei box in base alla categorie scelta
  useEffect(() => {
    if (!modifyArticleProps) {
      if (listCategorie) {
        listCategorie.forEach(c => c.id === selectedCategoria ? setBoxContent(c.boxTesto.map(b => ({ ...b, contenuto: '' }))) : null);
      };
    } else {
      setNoLibri(false)
    }
  }, [selectedCategoria, listCategorie, modifyArticleProps]);

  // Update valori provenienti dall'editor Quill
  function handleChangeQuill(value, box, isSintesiAnia) {
    
    const valueLength = value.replaceAll(/<[^>]*>/g, "").length

    if (!isSintesiAnia || (isSintesiAnia && valueLength <= 2100)) {
      const temp = boxContent.map(el => el.idBoxTesto === box.idBoxTesto ? { ...el, contenuto: value } : el);
      setBoxContent(temp)
    }

    if(isSintesiAnia && valueLength > 2100) {
      const temp = boxContent.map(el => el.idBoxTesto === box.idBoxTesto ? { ...el, contenuto: el.contenuto } : el);
      setBoxContent(temp)
      warningTextLength()
    }
  }

  // Set colore bollino in base alla selezione dell'utente
  function colorBollino(selectedLabel) {
    switch (selectedLabel) {
      case enumImportanza.UI:
        return (<div className="bollino-rosso text-white text-center mx-1"><small><strong>U<br />I</strong></small></div>)
      case enumImportanza.UNI:
        return (<div className="bollino-giallo text-danger text-center mx-1"><small><strong>U<br />nI</strong></small></div>)
      case enumImportanza.NUI:
        return (<div className="bollino-blu text-white text-center mx-1"><small><strong>nU<br />I</strong></small></div>)
      case enumImportanza.NUNI:
        return (<div className="bollino-verde text-white text-center mx-1"><small><strong>nU<br />nI</strong></small></div>)
      case enumImportanza.C:
        return (<div className="bollino-grigio text-center mx-1"><small><strong>C</strong></small></div>)
      default:
        return (<div />)
    }
  }

  // Metodo per verificare se il template è IVASS
  const checkIsIvass = () => ((templateSelectedLibro === enumTemplate.IVASS) || (modifyArticleProps?.templateModelloLibro === enumTemplate.IVASS))

  // Gestisci ricerca per filtrare articoli referenziati
  function filterOptions(options, searchParam) {
    return !searchParam ? options : options.filter(({ label }) => label && label.toLowerCase().includes(searchParam.toLowerCase()));
  }

  // Metodo per fetch di tutti i libri con vari setting (valori della form, messaggi di attesa ecc)
  async function getLibri() {
    toggleSpinner();
    await axiosInstance.get('/libri/elenco')
      .then(res => {

        if (!modifyArticleProps) {
          let temp = res.data.filter(item => item.modelloLibroRes.categorie.length > 0 && item.statoLibro === statoPubblicazioneLibro.IN_LAVORAZIONE);
          if (temp.length > 0) {
            setNoLibri(false)
            setListaLibri(temp);
            setSelectedLibro(temp[0].id);
            setListCategorie(temp[0].modelloLibroRes.categorie.sort((a, b) => a.nomeCategoria.toLowerCase() > b.nomeCategoria.toLowerCase() ? 1 : -1));
            setSelectedCategoria(temp[0].modelloLibroRes.categorie[0].id)
          } else setLandingMessage('Non ci sono libri in lavorazione. Bisogna creare almeno un libro per poter creare un articolo')
        } else {
          let temp = res.data.filter(item => item.modelloLibroRes.id === modifyArticleProps.categoriaArticoloRes.modelloLibroId && item.statoLibro === statoPubblicazioneLibro.IN_LAVORAZIONE);
          if (temp.length > 0) {
            setNoLibri(false);
            setListaLibri(temp);
            setTemplateSelectedLibro(temp.filter(el => el.id === modifyArticleProps.idLibro)[0].modelloLibroRes.template)
          } else setLandingMessage('Non ci sono libri in lavorazione. Bisogna creare almeno un libro per poter creare un articolo')
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => {
        toggleSpinner();
      })
  }

  // Creazione di un articolo
  async function createArticolo(body) {
    toggleSpinner();
    await axiosInstance.post("/articoli", body)
      .then(r => {
        if (r && r.status === 200) {
          toggleToast('s', "Articolo creato con successo!");
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => {
        toggleSpinner();
        history.push('/revisione-articoli')
      })
  }

  // Aggiornamento di un articolo
  async function updateArticolo(body, id) {
    toggleSpinner();
    await axiosInstance.put(`/articoli/${id}`, body)
      .then(r => {
        if (r && r.status === 200) {
          toggleToast('s', "Articolo aggiornato con successo!");
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => {
        toggleSpinner();
        history.push('/revisione-articoli')
      })
  }

  // Submission
  function handleSubmit(status) {
    // Flag che serve per visualizzare i messagi di form invalid ma solo dopo una prima submission
    if (!formSubmitted) setformSubmitted(true);

    if ((status === statoPubblicazioneArticolo.BOZZA && titoloValidation.isValid) || (status === statoPubblicazioneArticolo.ATTESA_PUBBLICAZIONE && titoloValidation.isValid && boxValidation.isValid && dataAniaValidation.isValid)) {
      let body = {
        titoloArticolo,
        categoriaId: selectedCategoria,
        libroId: selectedLibro,
        statoArticolo: status,
        boxTestoArticolo: boxContent,
        referenti,
        listaArticoliStessoLibro: selectedArticoliStessoLibro.map(el => el.value),
        listaArticoliAltriLibri: selectedArticoliNoStessoLibro.map(el => el.value),
        autoreId: selectedAutore,
        livelloImportanzaArticoloId: selectedImportanza,
        statoAggiornamento: selectedStatoUpdateArticolo ? selectedStatoUpdateArticolo : null,
        tematicaArticoloIds: selectedTematica.map(el => el.value),
        dataCommentiAnia: !dataCommentiAnia ? null : convertDate(dataCommentiAnia),
        dataCommentiIvass: !dataCommentiIvass ? null : convertDate(dataCommentiIvass)
      }

      !modifyArticleProps ? createArticolo(body) : updateArticolo(body, modifyArticleProps.id)
    } else warning()
  }

  return (
    <div id="nuovo-articolo-container" className="container">
      <BackToPrevious goBack={() => props.history.goBack()} />

      <div className="title-nuovo-articolo">
        <div className="title">
          <i className="fas fa-th" />
          {!modifyArticleProps ? 'nuovo articolo' : 'modifica articolo'}
        </div>
      </div>

      {noLibri && !modifyArticleProps
        ? <div className="nessuna-pubblicazione justify-content-center align-items-center color-main-color">
          {landingMessage}
        </div>
        : (<>
          <div className="row margin-top-30">
            <div className="col-md-12" id="configuratore">
              <div id="scelta-libro-categoria" className="margin-top-30">

                <div className="row">
                  {/* SELECT PER SELEZIONARE LIBRO */}
                  <div className="col-md-4">
                    <label htmlFor="tipoLibro">Seleziona un libro</label>
                    <select className="form-control" value={!selectedLibro ? '' : selectedLibro}
                      onChange={e => setSelectedLibro(e.target.value)}>
                      {!listaLibri
                        ? <option value="" />
                        : listaLibri.map(l => <option key={l.id} value={l.id}>{`${l.nomeLibro} (${l.modelloLibroRes.nomeModelloLibro})`}</option>)}
                    </select>
                  </div>

                  {/* SELECT PER SELEZIONARE CATEGORIA */}
                  <div className="col-md-4">
                    <label htmlFor="categoria">Categoria <i className="fas fa-exclamation-triangle text-danger ml-2" /></label>
                    <select className="form-control" name="categoria" onChange={e => setSelectedCategoria(e.target.value)} disabled={modifyArticleProps}>
                      {listCategorie.length === 0
                        ? <option value="" />
                        : listCategorie.sort((a, b) => a.nomeCategoria.toLowerCase() > b.nomeCategoria.toLowerCase() ? 1 : -1).map(c => (<option key={c.id} value={c.id}>{c.nomeCategoria}</option>))}
                    </select>
                    <small className="text-success font-weight-bold font-italic">
                      Il cambio di categoria comporta la cancellazione del contenuto dei box
                    </small>
                  </div>

                  {/* <div className="col-md-4 d-flex mt-4">
                    <div><i className="fas fa-exclamation-triangle text-danger mr-2" /></div>
                    <div>
                      <small className="text-success font-weight-bold font-italic">
                        Il cambio di categoria comporta la cancellazione del contenuto dei box
                      </small>
                    </div>
                  </div> */}

                  {/* SELECT PER SELEZIONARE STATO UPDATE DELL'ARTICOLO */}
                  <div className="col-md-4">
                    <label>Stato di aggiornamento</label>
                    <select className="form-control" name="importanza" value={selectedStatoUpdateArticolo} onChange={e => setSelectedStatoUpdateArticolo(e.target.value)}>
                      <option value="">Nessuna selezione</option>
                      {statiUpdateArticolo.map(el => (<option key={el.id} value={el.id}>{el.statoAggiornamento}</option>))}
                    </select>
                  </div>

                  {templateSelectedLibro === enumTemplate.IVASS && (<>
                    {/* MULTISELECT PER SELEZIONARE TEMATICA DELL'ARTICOLO */}
                    <div className="col-md-4 mt-3">
                      <label>Tematica</label>
                      <MultiSelect
                        options={tematicheArticoli.map(el => ({ value: el.id, label: el.label }))}
                        value={selectedTematica}
                        onChange={setSelectedTematica}
                        overrideStrings={{
                          selectSomeItems: "Seleziona Tematica",
                          allItemsAreSelected: "Tutte le opzioni sono selezionate",
                          selectAll: "Seleziona tutto",
                          search: "Cerca",
                        }}
                        disableSearch
                      />
                    </div>

                    {/* SELECT PER SELEZIONARE IMPORTANZA DELL'ARTICOLO */}
                    <div className="col-md-4 mt-3">
                      <label>Importanza</label>
                      <select className="form-control" name="importanza" value={selectedImportanza} onChange={e => setSelectedImportanza(e.target.value)}>
                        {importanzaArticoli.sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? 1 : -1).map(el => (<option key={el.id} value={el.id}>{el.label}</option>))}
                      </select>
                    </div>

                    <div className="col-md-4 d-flex align-items-center mt-4">
                      {/* selectedImportanza.map(el => colorBollino(el.label)) */}
                      {colorBollino(selectedImportanza)}
                    </div>
                  </>)}

                  {/* SELECT PER SELEZIONARE AUTORE PROPRIETARIO DELL'ARTICOLO */}
                  {/* <div className="col-md-4 mt-3">
                    <label htmlFor="autore">Autore</label>
                    <select className="form-control" name="autore" value={selectedAutore} onChange={e => setSelectedAutore(e.target.value)}>
                      {listaAutori.map(autore => (<option key={autore.id} value={autore.id}>{autore.nome + ' ' + autore.cognome}</option>))}
                    </select>
                  </div> */}

                </div>
              </div>
            </div>
          </div>

          {/* Titolo dell'articolo */}
          <div id="titolo-articolo" className="row margin-top-30">
            <div className="col-md-6">
              <label htmlFor="titoloArticolo">Titolo</label>
            </div>
            <div className="col-md-6 text-right">
              <div className="small-size-text color-azure">max. 180 caratteri</div>
            </div>
            <div className="col-md-12">
              <input type="text" className={`form-control ${!titoloValidation.isValid && formSubmitted ? "is-invalid" : null}`} maxLength="180"
                value={titoloArticolo} placeholder="Inserisci il titolo dell'articolo" onChange={e => setTitoloArticolo(e.target.value)} />
            </div>
            <div className="col-md-6">
              <small className="text-danger">{!titoloValidation.isValid && formSubmitted ? titoloValidation.errorMsg : null}</small>
            </div>
            <div className="col-md-6 text-right">
              <div className="small-size-text">{`(${titoloArticolo.length}/180)`}</div>
            </div>
          </div>

          { /* CKEditor con i box di testo delle categorie */
            boxContent.length === 0
              ? null
              : <>
                <p className={`margin-top-30 mb-0 ${!boxValidation.isValid && formSubmitted ? "form-control is-invalid" : null}`} >
                  <em>Inserisci un contenuto per almeno uno dei box di testo appartenenti alla categoria</em>
                </p>
                <small className="text-danger">{!boxValidation.isValid && formSubmitted ? boxValidation.errorMsg : null}</small>
                {
                  boxContent.map(box => (
                    <div key={box.idBoxTesto} id="titolo-box" className="row margin-top-20">
                      <div className="col-md-12">
                        <label htmlFor="">{box.titoloBoxTesto}</label>
                        <ReactQuill
                          onKeyDown={e => {
                            // Nel caso del primo box IVASS limitiamo a 2100 i caratteri. 
                            // Oltre quella soglia possiamo solo usare i tasti backspace e delete
                            if (checkIsIvass() && box.posizione === 1 && box.contenuto.replaceAll(/<[^>]*>/g, "").length >= 2100) {
                              if (e.keyCode === 46 || e.keyCode === 8) {
                                return true
                              } else e.preventDefault()
                            }
                          }}
                          value={box.contenuto}
                          onChange={e => handleChangeQuill(e, box, (checkIsIvass() && box.posizione === 1))}
                          modules={!checkIsIvass() ? modules : modulesIvass}
                          formats={!checkIsIvass() ? formats : []} />
                      </div>
                      {
                        checkIsIvass()
                        && box.posizione === 1
                        && <div className="small-size-text w-100 mr-4 text-right">{`(${box.contenuto.replaceAll(/<[^>]*>/g, "").length}/2100)`}</div>
                      }
                    </div>))
                }
              </>
          }

          {templateSelectedLibro === enumTemplate.IVASS &&
            <div className="row my-4">
              <div className="col-md-4 d-flex flex-column">
                <label className={`mb-1`}>Data Commenti Ania</label>
                <DatePicker
                  placeholderText="gg/mm/aaaa"
                  selected={dataCommentiAnia}
                  onChange={date => setDataCommentiAnia(date)}
                  selectsStart
                  showYearDropdown
                  scrollableYearDropdown
                  dateFormat="dd/MM/yyyy"
                  locale="it"
                  className={`text-center ${!dataAniaValidation.isValid && formSubmitted ? "form-control is-invalid" : null}`}
                />
              </div>
              <div className="col-md-4 d-flex flex-column">
                <label className="mb-1">Data Commenti IVASS</label>
                <DatePicker
                  placeholderText="gg/mm/aaaa"
                  selected={dataCommentiIvass}
                  onChange={date => setDataCommentiIvass(date)}
                  selectsStart
                  showYearDropdown
                  scrollableYearDropdown
                  dateFormat="dd/MM/yyyy"
                  locale="it"
                  className="text-center"
                />
              </div>
            </div>
          }

          {/* ARTICOLI DA LINKARE E REFERENTI */}
          <p className="margin-top-30 mb-0" >
            <em>
              {templateSelectedLibro === enumTemplate.IVASS
                ? "Seleziona gli articoli correlati ed indica i referenti"
                : "Indica i referenti"
              }
            </em>
          </p>

          <div className="row">
            {templateSelectedLibro === enumTemplate.IVASS && (<>
              <div className="col-md-4">

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label>Articoli dello stesso libro (Max. 2)</label>
                  </div>

                  <div className="col-md-12">
                    <MultiSelect
                      options={
                        listaArticoli.filter(el => el.idLibro === selectedLibro)
                          .filter(el => !modifyArticleProps?.id ? el : el.idArticolo !== modifyArticleProps.id)
                          .map(el => ({ value: el.idArticolo, label: el.nomeLibro + ' - ' + el.titoloArticolo }))
                      }
                      value={selectedArticoliStessoLibro}
                      onChange={setSelectedArticoliStessoLibro}
                      filterOptions={filterOptions}
                      disabled={selectedArticoliStessoLibro.length === 2}
                      overrideStrings={{
                        selectSomeItems: "Seleziona articolo",
                        allItemsAreSelected: "Tutti gli articoli sono selezionati",
                        selectAll: "Seleziona tutto",
                        search: "Cerca",
                      }}
                    />
                  </div>

                  {
                    React.Children.toArray(selectedArticoliStessoLibro.map((el, id) => (
                      <>
                        <div className="col-md-10 d-flex align-items-center my-2 pl-3">
                          <p className="m-0 font-italic">{el.label}</p>
                        </div>
                        <div className="col-md-2 my-2 d-flex justify-content-center align-items-center">
                          <i className="fas fa-times color-main-color cursor-pointer"
                            onClick={() => setSelectedArticoliStessoLibro(selectedArticoliStessoLibro.filter((el, index) => index !== id))} />
                        </div>
                      </>
                    ))
                    )}
                </div>
              </div>

              <div className="col-md-4">

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label>Articoli di "Criticità e Proposte" (Max. 2)</label>
                  </div>
                  <div className="col-md-12">
                    <MultiSelect
                      options={
                        listaArticoli.filter(el => el.templateModello.id === enumTemplate.CRITICITA_PROPOSTE)
                          .map(el => ({ value: el.idArticolo, label: el.nomeLibro + ' - ' + el.titoloArticolo }))
                      }
                      value={selectedArticoliNoStessoLibro}
                      onChange={setSelectedArticoliNoStessoLibro}
                      filterOptions={filterOptions}
                      disabled={selectedArticoliNoStessoLibro.length === 2}
                      overrideStrings={{
                        selectSomeItems: "Seleziona articolo",
                        allItemsAreSelected: "Tutti gli articoli sono selezionati",
                        selectAll: "Seleziona tutto",
                        search: "Cerca",
                      }}
                    />
                  </div>
                  {
                    React.Children.toArray(selectedArticoliNoStessoLibro.map((el, id) => (
                      <>
                        <div className="col-md-10 d-flex align-items-center my-2 pl-3">
                          <p className="m-0 font-italic">{el.label}</p>
                        </div>
                        <div className="col-md-2 my-2 d-flex justify-content-center align-items-center">
                          <i className="fas fa-times color-main-color cursor-pointer"
                            onClick={() => setSelectedArticoliNoStessoLibro(selectedArticoliNoStessoLibro.filter((el, index) => index !== id))} />
                        </div>
                      </>
                    ))
                    )}
                </div>
              </div>
            </>)}

            <div className="col-md-4">
              <label className="mt-3">Referenti (max. 100 caratteri)</label>
              <input type="text" className="form-control" value={referenti} maxLength="100" onChange={e => setReferenti(e.target.value)} />
            </div>
          </div>

          <div className="text-right margin-top-35">
            <Link to='/revisione-articoli'><div className="btn outline-azure btn-sm text-uppercase margin-right-20">annulla</div></Link>
            <div className="btn outline-azure btn-sm text-uppercase margin-right-20" onClick={() => handleSubmit(statoPubblicazioneArticolo.BOZZA)}>salva in bozza</div>
            <button className="btn btn-azure btn-sm text-uppercase" onClick={() => handleSubmit(statoPubblicazioneArticolo.ATTESA_PUBBLICAZIONE)}>manda in approvazione</button>
          </div>
        </>)
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    importanzaArticoli: state.staticData.importanzaArticoli,
    tematicheArticoli: state.staticData.tematicheArticoli,
    listaAutori: state.staticData.listaAutori,
    listaArticoli: state.staticData.listaArticoli,
    statiUpdateArticolo: state.staticData.statiUpdateArticolo
  }
}

export default withRouter(connect(mapStateToProps, { toggleToast, toggleSpinner })(NuovoArticolo));
