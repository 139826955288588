import React from 'react';
import {Route, Switch, Redirect} from "react-router-dom";
import routes from "./routes";
import Home from '../pages/Home/Home';
import LoginContainer from '../pages/Login/LoginContainer';

const getVisibleToAllRoutes = () => routes.filter(r => r.access.includes(0));
const getPublicRoutes = () => routes.filter(r => r.access.includes(1));
const getPrivateRoutes = () => routes.filter(r => r.access.includes(2));
const getPrivateRoutesWithRoles = (authorities) => routes.filter(r => r.access.includes(3) &&
    authorities &&
    r.roles.some(r => authorities.indexOf(r) >= 0));

function CustomComponent(Component) {
    return function (props) {
        return <Component {...props}/>;
    }
}

const getRouteComponent = routes => routes.map((r, i) => <Route path={r.path} exact key={i}>{CustomComponent(r.component)}</Route>);

export const getRouteItems = user =>
    user ?
        getVisibleToAllRoutes().concat(getPrivateRoutesWithRoles(user.ruoloUtente), getPrivateRoutes()) :
        getVisibleToAllRoutes().concat(getPublicRoutes());

export const getRoutes = (user) => {
    return (
        <Switch>
            {/* Metodo per generare dinamicamente le routes */}
            {getRouteComponent(getRouteItems(user))}
            
            {/* Aggiungiamo una Route fissa per gestire l'inseriento di url sbagliato. A seconda dello stato di login facciamo vedere Home o Login */}
            <Route component={ user ? Home : LoginContainer } />

            {/* <Redirect to={user ? sessionStorage.getItem('path') || '/' : '/login'}/> */}
        </Switch>
    )
};

export const scrollTop = () => {
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, 200);
}
