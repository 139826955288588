import React from 'react';
import { NavLink } from "react-router-dom";
import axiosInstance from '../../api/interceptor';
import { connect } from 'react-redux';
import { getCategorie } from '../../store/actionCreators/categorie';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import { setFilterArticoliParams } from '../../store/actionCreators/filterArticoliParams';
import Libro from "../../components/Libro/Libro";
import Articolo from "../../components/Articolo/Articolo";
import { enumTemplate } from '../../assets/utils/enum';

const resources = {
  ultimiArticoli: '/articoli/pubblicati',
  ultimiLibri: '/libri/pubblicati',
  modelloPrincipale: '/libri/modelloprincipale'
}

const bootstrapSpinner = (<div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>)

function Home(props) {
  const { categorie, getCategorie, setFilterArticoliParams, toggleToast, toggleSpinner } = props;

  // LIMITA CATEGORIE A XXX CARATTERI
  function formatCategoria(categoria, limitChar) {
    return categoria.length > limitChar ? categoria.substr(0, limitChar) + '...' : categoria;
  }

  //  Dati in pagina
  const [allArticoli, setAllArticoli] = React.useState([]);
  const [ultimiLibri, setUltimiLibri] = React.useState([]);
  const [modelliPrincipali, setModelliPrincipali] = React.useState([]);
  //  Dati in pagina

  // Gestione visiva del loading di articoli e libri
  const [msgAllArticoli, setMsgAllArticoli] = React.useState(bootstrapSpinner);
  const [msgAllLibri, setMsgAllLibri] = React.useState(bootstrapSpinner);

  const [loadingPrimoPiano, setLoadingPrimoPiano] = React.useState(false);

  React.useEffect(() => {
    getCategorie()
  }, [getCategorie]);

  React.useEffect(() => {
    getData()
  }, []) // eslint-disable-line

  const getData = async () => {
    toggleSpinner();
    await Promise.all([
      getArticoli(),
      getUltimiLibri(),
      getModelloPrincipale()
    ]).finally(() => toggleSpinner())
  }

  async function getArticoli() {
    await axiosInstance.get(resources.ultimiArticoli)
      .then(res => {
        let temp = res.data.content; // Selezioniamo gli ultimi 3 articoli da visualizzare sulla home
        (temp.length <= 3) ? setAllArticoli(temp) : setAllArticoli(temp.slice(0, 3));
      })
      .catch(e => toggleToast('e', e))
      .finally(() => {
        setMsgAllArticoli('Nessun articolo pubblicato');
      })
  }

  const getUltimiLibri = async () => {
    await axiosInstance.get(resources.ultimiLibri)
      .then((r) => {
        if (r && r.status === 200) {
          let temp = r.data.content;// Selezioniamo gli ultimi 5 libri da visualizzare sulla home
          (temp.length <= 5) ? setUltimiLibri(temp) : setUltimiLibri(temp.reverse().slice(0, 5));
        }
      })
      .catch(({ messaggio }) => messaggio && toggleToast('e', messaggio))
      .finally(() => {
        setMsgAllLibri('Nessun libro pubblicato');
      });
  }

  const getModelloPrincipale = async () => {
    setLoadingPrimoPiano(true)
    await axiosInstance.get(resources.modelloPrincipale)
      .then((r) => {
        if (r && r.status === 200) {
          // Todo devono essere gli ultimi 2 creati/modificati, ma la data che torna il backend è senza ore-minuti
          setModelliPrincipali(r.data.content.reverse().slice(0, 2));
        }
      })
      .catch(e => e && e.messaggio ? toggleToast('e', e.messaggio) : console.log(e))
      .finally(() => setLoadingPrimoPiano(false));
  }

  return (
    <div id="home-container" className="container">
      <div id="criticita-proposte-articoli-container">

        <div id="criticita-container" className="content">
          <div className="title">
            <i className="fas fa-th" />
            Primo piano
            {/*  FIXME  Chiedere testo*/}
          </div>
          <div className={`libri-container ${loadingPrimoPiano ? 'h-75' : null}`}>
            {
              loadingPrimoPiano ? (<div className="d-flex m-auto spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>) :
                modelliPrincipali && modelliPrincipali.length > 0 ?
                  React.Children.toArray(modelliPrincipali.map(c => <Libro {...c} />))
                  :
                  <div className="nessuna-pubblicazione justify-content-center align-items-center color-main-color">
                    Non ci sono pubblicazioni in primo piano
                  </div>
            }
          </div>
          {modelliPrincipali && modelliPrincipali.length > 0 ?
            <NavLink className="d-inline-block go-to color-main-color margin-top-15" to="/primo-piano">
              Tutte le pubblicazioni in primo piano{' >'}
            </NavLink> : null
          }
          {/*  FIXME  Chiedere testo*/}
        </div>

        <div id="articoli-container" className="content">
          <div className="title">
            <i className="fas fa-th" />
            ultimi articoli
          </div>
          <div className="articoli-container">
            {(allArticoli && allArticoli.length > 0)
              ? allArticoli.map(c => <Articolo key={c.id} {...c} />)
              : (<div className="nessuna-pubblicazione justify-content-center align-items-center color-main-color">
                {msgAllArticoli}
              </div>)
            }
          </div>
          {(allArticoli && allArticoli.length > 0)
            ? <NavLink className="go-to color-main-color margin-top-15" to="/ultime-pubblicazioni">
              Tutti gli articoli pubblicati {'>'}</NavLink>
            : null
          }
        </div>
      </div>

      <div className="categorie-container">
        <div className="title">
          <i className="fas fa-th" />
          categorie
        </div>
        <div className="categorie row">
          {!categorie ? null :
            categorie.filter(c => !c.secondLevelCategory && (c.categoriaConArticoliPubblicati || c.titoloCategoria === enumTemplate.IVASS)).map(categoria => (
              <div className="col-md-3" key={categoria.id}>
                {/* Cliccando sulla categoria setto il suo id come filtro di ricerca e vado su ultime-pubblicazioni */}
                <div className="categoria row" onClick={() => setFilterArticoliParams(`&categoriaIds=${categoria.id}`)}>
                  <div className="col-md-2">
                    <i className={`fas fa-${categoria.icona}`} />
                  </div>
                  <div className="col-md-10">
                    <NavLink className="color-bianco" to='/ultime-pubblicazioni'>
                      {formatCategoria(categoria.titoloCategoria, 50)}
                    </NavLink>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <div id="libri-container">
        <div className="title">
          <i className="fas fa-th" />
          tutti i libri
        </div>
        <div className="libri-container">
          {
            ultimiLibri && ultimiLibri.length > 0 ? React.Children.toArray(ultimiLibri.map(l => <Libro {...l} />)) :
              <div className="nessuna-pubblicazione justify-content-center align-items-center color-main-color">
                {msgAllLibri}
              </div>
          }
        </div>
        {
          ultimiLibri && ultimiLibri.length > 0 ?
            <NavLink className="go-to color-main-color margin-top-15" to="/libri">Visualizza tutti i
              libri {'>'}
            </NavLink>
            : null
        }
      </div>
    </div>
  );
}

// Reducer contenente le categorie, fare il fetch dal DB con getCategorie()
function mstp(state) {
  return {
    categorie: state.categorie
  }
}

export default connect(mstp, { getCategorie, setFilterArticoliParams, toggleToast, toggleSpinner })(Home);
