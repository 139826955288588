import React from 'react';
import logo_ania from '../../../assets/img/logo/logo.png';
import {connect} from "react-redux";
import {auth} from "../../../store/actionCreators/auth";

const mailToAddress = 'devjiengis@gmail.com';

const actions = {
    reset: 'reset',
    changeField1: 'changeField1',
    changePassword: 'changePassword',
    errorChangeField1: 'errorChangeField1',
    errorChangePassword: 'errorChangePassword'
}

const initialState = {
    field1: {
        value: '',
        error: false
    },
    password: {
        value: '',
        error: false
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.reset:
            return init();
        case actions.changeField1: {
            return {
                ...state,
                field1: {
                    value: action.value,
                    error: action.value === ''
                }
            }
        }
        case actions.changePassword: {
            return {
                ...state,
                password: {
                    value: action.value,
                    error: action.value === ''
                }
            }
        }
        case actions.errorChangeField1: {
            return {
                ...state,
                field1: {
                    value: state.field1.value,
                    error: true
                }
            }
        }
        case actions.errorChangePassword: {
            return {
                ...state,
                password: {
                    value: state.password.value,
                    error: true
                }
            }
        }
        default:
            return state;
    }
}

const init = () => initialState;

const errorMessages = {
    campoObbligatorio: 'Campo obbligatorio',
}

const getErrorMessage = () => <small style={{
    color: 'red',
    fontSize: '12px',
}}>{errorMessages.campoObbligatorio}</small>

function LoginForm(props) {
    const {auth, setFormType} = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const checkValidity = () => {
        let error;
        if (state.field1.value === "") {
            error = true;
            dispatch({type: actions.errorChangeField1})
        }
        if (state.password.value === "") {
            error = true;
            dispatch({type: actions.errorChangePassword})
        }

        if (!error) {
            auth(state.field1.value, state.password.value);
        }
    }

    return (
        <div id="login-form">
            <img src={logo_ania} alt="logo_ania"/>
            <div className="form-row margin-bottom-15">
                <label htmlFor="email/username">E-mail / Username</label>
                <div className="input-wrapper">
                    <input
                        type="text"
                        className={!state.field1.error ? "form-control" : "form-control not-valid"}
                        onChange={e => {
                            dispatch({
                                type: actions.changeField1,
                                value: e.target.value
                            })
                        }}
                        value={state.field1.value}
                    />
                    {
                        state.field1.value !== "" &&
                        <span
                            onClick={() => dispatch({
                                type: actions.changeField1,
                                value: ""
                            })}>
                    <i className="fas fa-times"
                       style={state.field1.error ? {color: 'red'} : null}
                    />
                  </span>
                    }
                </div>
                {state.field1.error ? getErrorMessage() : null}
            </div>
            <div className="form-row margin-bottom-15" style={{marginBottom: '35px'}}>
                <label htmlFor="password">Password</label>
                <div className="input-wrapper">
                    <input
                        type="password"
                        className={!state.password.error ? "form-control" : "form-control not-valid"}
                        onChange={e => {
                            dispatch({
                                type: actions.changePassword,
                                value: e.target.value
                            })
                        }}
                        value={state.password.value}
                    />
                    {
                        state.password.value !== "" &&
                        <span
                            onClick={() => dispatch({
                                type: actions.changePassword,
                                value: ""
                            })}>
                            <i className="fas fa-times"
                               style={state.password.error ? {color: 'red'} : null}
                            />
                        </span>
                    }
                </div>

                {state.password.error ? getErrorMessage() : null}
            </div>
            <div id="actions">
                <div className="go-to color-main-text font-weight-light margin-bottom-10 cursor-pointer">
                    <span onClick={() => setFormType('forgotPassword')}>Hai dimenticato la password?</span>
                </div>
                <button
                    className="btn btn-azure margin-bottom-10 margin-top-10"
                    onClick={checkValidity}
                >LOGIN
                </button>
              <div className="go-to color-main-text font-weight-light margin-top-10 cursor-pointer">
                <a href={`mailto:${mailToAddress}`} style={{textDecoration: 'none'}} className="color-main-text">
                  Non hai ancora un account?
                </a>
              </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    auth: (username, password) => dispatch(auth(username, password))
});

export default connect(null, mapDispatchToProps)(LoginForm);
