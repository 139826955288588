import PDFGenerationUtils, { getPalette, mainColors, mainFontSizes } from "./PDFGenerationUtils";
import { PDFDocument, StandardFonts, rgb, degrees, PDFName } from "pdf-lib";

import _ from "lodash";



class PDFGenerator {

    constructor(libro) {
        this.libro = libro
        this.indicePageLength = 0
        this.indiceReferences = {}
    }

    initializePDFDocument = async () => {
        const pdfDoc = await PDFDocument.create()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

        const covidIcon = await fetch(process.env.PUBLIC_URL + "/covid_icon.png").then(res => res.arrayBuffer()).then(ab => pdfDoc.embedPng(ab))
        const semaforoUI = await fetch(process.env.PUBLIC_URL + "/semaforo-ui.png").then(res => res.arrayBuffer()).then(ab => pdfDoc.embedPng(ab))
        const semaforoUNI = await fetch(process.env.PUBLIC_URL + "/semaforo-uni.png").then(res => res.arrayBuffer()).then(ab => pdfDoc.embedPng(ab))
        const semaforoNUI = await fetch(process.env.PUBLIC_URL + "/semaforo-nui.png").then(res => res.arrayBuffer()).then(ab => pdfDoc.embedPng(ab))
        const semaforoNUNI = await fetch(process.env.PUBLIC_URL + "/semaforo-nuni.png").then(res => res.arrayBuffer()).then(ab => pdfDoc.embedPng(ab))
        const semaforoChiuso = await fetch(process.env.PUBLIC_URL + "/semaforo-chiuso.png").then(res => res.arrayBuffer()).then(ab => pdfDoc.embedPng(ab))

        const legendaDistribuzione = await fetch(process.env.PUBLIC_URL + "/legenda-categorie.png").then(res => res.arrayBuffer()).then(ab => pdfDoc.embedPng(ab))

        this.immaginiSemaforo = {
            "UrgenteImportante": semaforoUI,
            "UrgenteNonImportante": semaforoUNI,
            "NonUrgenteImportante": semaforoNUI,
            "NonUrgenteNonImportante": semaforoNUNI,
            "Chiuso": semaforoChiuso
        }

        this.covidImg = covidIcon
        this.legendaDistribuzione = legendaDistribuzione
        this.document = pdfDoc
        this.font = font
        this.fontBold = fontBold

        // Non mi metterò MAI a scrivere un algoritmo che fa la conversione da numeri arabi a romani. 
        this.lookupRomanNumbers = ["i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix", "x", "xi", "xii", "xiii", "xiv", "xv", "xvi", "xvii", "xviii", "xix", "xx"]
    }

    drawStatusLabel = (page, font, status) => {
        const { height } = page.getSize()
        const colorRed = rgb(253 / 255, 94 / 255, 88 / 255)
        const colorYellow = rgb(1, 1, 142 / 255)

        const statusColor = (!status
            ? null
            : status === "Nuovo" ? colorRed : colorYellow)
        const statusText = (!status
            ? ""
            : status === "Nuovo" ? "NEW" : "AGG")
        if (statusColor) page.drawRectangle({ x: 500, y: height - 40, width: 100, height: 40, color: statusColor, ySkew: degrees(-60) })
        if (statusText) page.drawText(statusText, { x: 460, y: height - 10, size: 12, font: font, color: mainColors.colorBlack, rotate: degrees(-30) })
        page.drawRectangle({ x: 498, y: height - 50, width: 100, height: 100, color: rgb(1, 1, 1) })
    }

    createCopertina = async () => {
        if (!this.document || !this.font || !this.fontBold) return

        const page = this.document.addPage()
        const { width, height } = page.getSize()

        const pngImageBytes = await fetch(process.env.PUBLIC_URL + "/copertina.png").then(res => res.arrayBuffer())
        const pngImage = await this.document.embedPng(pngImageBytes)

        page.drawImage(pngImage, { x: 0, y: 0, width: width, height: height })

        // page.drawText(this.libro.titolo, { x: 50, y: height - 180, font: this.fontBold, color: rgb(1, 1, 1), size: 18, maxWidth: 300 })
        // page.drawText(this.libro.sottoTitolo, { x: 50, y: height - 300, font: this.font, color: rgb(1, 1, 1), size: 18, maxWidth: 300 })

        page.drawText("ANIA-IVASS: punti aperti", { x: 50, y: height - 200, font: this.fontBold, color: rgb(1, 1, 1), size: 18, maxWidth: 300 })
        page.drawText(this.libro.titolo, { x: 50, y: height - 300, font: this.font, color: rgb(1, 1, 1), size: 18, maxWidth: 300 })
        page.drawText("Servizio Bilanci e Sostenibilità", { x: 50, y: height - 812.5, font: this.font, color: rgb(0, 32 / 255, 96 / 255), size: 16, maxWidth: 500 })
    }

    createLegenda = async () => {
        if (!this.document || !this.font || !this.fontBold) return

        const page = this.document.addPage()
        const { width, height } = page.getSize()

        const pngImageBytes = await fetch(process.env.PUBLIC_URL + "/legenda.png").then(res => res.arrayBuffer())
        const pngImage = await this.document.embedPng(pngImageBytes)

        page.drawImage(pngImage, { x: 0, y: 0, width: width, height: height, })

        // Creation of an extra page for the index
        this.document.addPage()
    }

    createIndice = async () => {
        if (!this.document || !this.font || !this.fontBold) return

        let pages = this.document.getPages()
        let indexPage = pages[2]
        let { width, height } = indexPage.getSize()
        const startingY = height - 100

        let currentY = startingY
        let linkRefs = []
        let articoloIdx = 0
        let indexPageIdx = 0

        PDFGenerationUtils.drawCenteredText(indexPage, { text: "ELENCO PUNTI CON RELATIVO COLLEGAMENTO", color: mainColors.colorBlue, font: this.fontBold, textSize: 20, y: 50, maxWidth: width })

        const groupedArticoli = this.getArticoliAttiviByCategoria()

        _.keys(groupedArticoli).forEach(categoria => {
            if (currentY <= 30 && this.document) {
                indexPage.node.set(PDFName.of('Annots'), this.document.context.obj(linkRefs));
                linkRefs = []
                if ((indexPageIdx + 1) < this.lookupRomanNumbers.length) {
                    indexPage.drawText(this.lookupRomanNumbers[indexPageIdx + 1], { x: 550, y: 30, size: mainFontSizes.fontSizeText, font: this.font, color: mainColors.colorBlue, lineHeight: 14 })
                }

                indexPage = indexPage.doc.insertPage(3 + indexPageIdx)
                width = indexPage.getSize().width
                height = indexPage.getSize().height
                currentY = height - 50
                indexPageIdx += 1
                //pages = this.document.getPages()
            }
            currentY -= 8
            indexPage.drawText(
                categoria,
                { x: 32, y: currentY, font: this.fontBold, size: 18, color: rgb(0, 0, 0), maxWidth: width, lineHeight: 17 }
            );
            currentY -= 23
            const articoli = groupedArticoli[categoria]

            articoli.forEach(articolo => {
                if (currentY <= 30 && this.document) {
                    indexPage.node.set(PDFName.of('Annots'), this.document.context.obj(linkRefs));
                    linkRefs = []
                    if ((indexPageIdx + 1) < this.lookupRomanNumbers.length) {
                        indexPage.drawText(this.lookupRomanNumbers[indexPageIdx + 1], { x: 550, y: 30, size: mainFontSizes.fontSizeText, font: this.font, color: mainColors.colorBlue, lineHeight: 14 })
                    }

                    indexPage = indexPage.doc.insertPage(3 + indexPageIdx)
                    width = indexPage.getSize().width
                    height = indexPage.getSize().height
                    currentY = height - 50
                    indexPageIdx += 1
                    //pages = this.document.getPages()
                }
                const link = PDFGenerationUtils.addLink(indexPage, pages[3 + articoloIdx], articolo.titolo, articoloIdx, currentY, this.font)
                currentY -= (link.wrappedLine ? 40 : 22)
                articoloIdx += 1
                if (link.linkAnnotationRef) {
                    linkRefs.push(link.linkAnnotationRef)
                    this.indiceReferences[articolo.idArticolo] = { idArticolo: articolo.idArticolo, pageReference: pages[3 + articoloIdx - 1].ref, pageIndex: 3 + articoloIdx + indexPageIdx - 1 }
                }
            })
        })

        if ((indexPageIdx + 1) < this.lookupRomanNumbers.length) {
            indexPage.drawText(this.lookupRomanNumbers[indexPageIdx + 1], { x: 550, y: 30, size: mainFontSizes.fontSizeText, font: this.font, color: mainColors.colorBlue, lineHeight: 14 })
        }

        this.indicePageLength = indexPageIdx + 1
        indexPage.node.set(PDFName.of('Annots'), this.document.context.obj(linkRefs));
    }

    createDistribuzione = () => {
        if (!this.document || !this.font || !this.fontBold) return
        const page = this.document.insertPage(2 + this.indicePageLength)

        const gridOptions = {
            circlesPerRow: 6, circleSpacing: 4, circleSize: 17, circleOffset: [20, 25],
            offsetX: 80, offsetY: 400, squareSize: 230, squareMargin: 10, headerheight: 50
        }

        PDFGenerationUtils.drawCenteredText(page, { text: "DISTRIBUZIONE PUNTI PER URGENZA E IMPORTANZA", y: 50, textSize: 26, font: this.font, color: mainColors.colorBlue, maxWidth: 400 })
        PDFGenerationUtils.drawGrigliaBase(page, gridOptions, this.fontBold)
        this.drawCircles(page, gridOptions)

        const { height, width } = page.getSize()
        const scalingFactor = 2.5
        const imageSize = { width: this.legendaDistribuzione.width / scalingFactor, height: this.legendaDistribuzione.height / scalingFactor }
        page.drawImage(this.legendaDistribuzione, { x: width / 2 - imageSize.width / 2, y: 10, ...imageSize })

        if ((this.indicePageLength + 1) < this.lookupRomanNumbers.length) {
            page.drawText(this.lookupRomanNumbers[this.indicePageLength + 1], { x: 550, y: 30, size: mainFontSizes.fontSizeText, font: this.font, color: mainColors.colorBlue, lineHeight: 14 })
        }
    }

    drawCircles = (page, gridOptions) => {
        const articolyBySemaforo = _.groupBy(this.libro.articoli, "semaforo")
        const { height } = page.getSize()

        const { nuiSquareOffsets, uiSquareOffsets, nuniSquareOffsets, uniSquareOffsets } = PDFGenerationUtils.getSquareOffsets(gridOptions, height)
        this.drawGrigliaCategoria(page, articolyBySemaforo["NonUrgenteImportante"], nuiSquareOffsets, gridOptions)
        this.drawGrigliaCategoria(page, articolyBySemaforo["NonUrgenteNonImportante"], nuniSquareOffsets, gridOptions)
        this.drawGrigliaCategoria(page, articolyBySemaforo["UrgenteNonImportante"], uniSquareOffsets, gridOptions)
        this.drawGrigliaCategoria(page, articolyBySemaforo["UrgenteImportante"], uiSquareOffsets, gridOptions)
    }

    drawGrigliaCategoria = (page, articoli, squareOffset, options) => {
        _.sortBy(articoli, "categoria").forEach((articolo, articoloIdx) => {
            const xCircle = options.circleOffset[0] + squareOffset.x + (articoloIdx % options.circlesPerRow) * (options.circleSpacing + 2 * options.circleSize)
            const yCircle = squareOffset.y + options.squareSize - options.circleOffset[1] - (Math.floor(articoloIdx / options.circlesPerRow) * (options.circleSpacing + 2 * options.circleSize))

            page.drawCircle({
                x: xCircle,
                y: yCircle,
                size: options.circleSize,
                color: getPalette(articolo.categoria).colorMain
            })

            // Gestione bollini per Solvency e Sostenibilità
            if (articolo.categoria === "Sostenibilità" || articolo.categoria === "Solvency" || articolo.categoria === "Innovazione") {
                page.drawText(
                    articolo.categoria.substring(0, 3),
                    {
                        x: -13 + xCircle,
                        y: -5 + yCircle,
                        size: 15,
                        color: mainColors.colorBlack,
                        font: this.fontBold
                    }
                )
            }
            else if (articolo.categoria === "Danni" || articolo.categoria === "Antifrode"
                || articolo.categoria === "Auto e CARD" || articolo.categoria === "Distribuzione") {
                page.drawText(
                    articolo.categoria.substring(0, 2),
                    {
                        x: -10 + xCircle,
                        y: -5 + yCircle,
                        size: 15,
                        color: mainColors.colorBlack,
                        font: this.fontBold
                    }
                )
            } else {
                page.drawText(
                    articolo.categoria.charAt(0).toUpperCase(),
                    {
                        x: -5 + xCircle,
                        y: -5 + yCircle,
                        size: 16,
                        color: mainColors.colorBlack,
                        font: this.fontBold
                    }
                )
            }

            const cs = options.circleSize

            const linkRef = PDFGenerationUtils.addInternalLink(
                page,
                this.indiceReferences[articolo.idArticolo].pageReference,
                [xCircle - cs, yCircle - cs, xCircle + cs, yCircle + cs]
            )
            page.node.addAnnot(linkRef)
        })
    }

    createPuntiChiusi = () => {
        if (!this.document || !this.font || !this.fontBold) return

        let page = this.document.addPage()
        let { width, height } = page.getSize()
        const startingY = height - 100
        let currentY = startingY

        PDFGenerationUtils.drawCenteredText(page, { text: "PUNTI CHIUSI", color: mainColors.colorBlue, font: this.fontBold, textSize: 20, y: 50, maxWidth: width })

        const groupedArticoli = this.getArticoliChiusiByCategoria()

        _.keys(groupedArticoli).forEach(categoria => {
            currentY -= 8
            page.drawText(
                categoria,
                { x: 32, y: currentY, font: this.fontBold, size: 18, color: rgb(0, 0, 0), maxWidth: width, lineHeight: 17 }
            );
            currentY -= 23
            const articoli = groupedArticoli[categoria]

            articoli.forEach(articolo => {
                if (!this.document || !this.font || !this.fontBold) return

                if (currentY <= 30 && this.document) {
                    page = page.doc.addPage()
                    width = page.getSize().width
                    height = page.getSize().height
                    currentY = height - 50
                }
                const wrappedLine = this.font.widthOfTextAtSize(articolo.titolo, 15) > width - 100

                page.drawText(articolo.titolo, {
                    x: 55,
                    y: currentY,
                    font: this.font,
                    size: 14,
                    color: rgb(0, 0, 0),
                    maxWidth: width - 100,
                    lineHeight: 15
                });
                currentY -= (wrappedLine ? 55 : 30)
            })
        })
    }

    createArticoli = async () => {
        const groupedArticoli = this.getArticoliAttiviByCategoria()

        let articoloPageNumber = 1
        _.keys(groupedArticoli).forEach(categoria => {
            const articoli = groupedArticoli[categoria]
            articoli.forEach(articolo => {
                this.createSingleArticoloPage(articolo, articoloPageNumber)
                articoloPageNumber += 1
            })
        })
    }

    createSingleArticoloPage = async (articolo, articoloPageNumber) => {
        if (!this.document || !this.font || !this.fontBold) return
        const page = this.document.addPage()
        this.createHeader(page, articolo)
        this.createSemaforo(page, articolo)
        this.createContent(page, articolo)
        this.createBlocchi(page, articolo)
        this.createFooter(page, articolo, articoloPageNumber)
    }

    createHeader = (page, articolo) => {
        if (!this.document || !this.font || !this.fontBold) return

        const { height } = page.getSize()
        const { colorMain, colorLight } = getPalette(articolo.categoria)

        // HEADER - TITOLO
        PDFGenerationUtils.drawCenteredRectangle(page, { y: 0, rectWidth: 400, rectHeight: 40, color: colorLight })
        PDFGenerationUtils.drawCenteredText(page, { text: articolo.categoria, color: mainColors.colorBlue, font: this.fontBold, textSize: mainFontSizes.fontSizeHeader, y: 30, maxWidth: 400 })

        // HEADER - NEW LABEL
        this.drawStatusLabel(page, this.font, articolo.statoAggiornamento)

        // HEADER - SOTTOTITOLO
        PDFGenerationUtils.drawCenteredRectangle(page, { y: 40, rectWidth: 400, rectHeight: 50, color: colorMain })
        PDFGenerationUtils.drawCenteredText(page, { text: articolo.titolo, color: mainColors.colorBlue, font: this.font, textSize: mainFontSizes.fontSizeSubtitle, y: 60, maxWidth: 400 })

        // HEADER - COVID LABEL
        if (articolo.isCovid) {
            PDFGenerationUtils.drawCenteredRectangle(page, { y: 90, rectWidth: 100, rectHeight: 25, color: mainColors.colorCovidLabel })
            page.drawText("COVID", { x: 290, y: height - 105, size: mainFontSizes.fontSizeText, font: this.fontBold, color: rgb(1, 1, 1) })
            page.drawImage(this.covidImg, { x: 260, y: height - 112.5, width: 25, height: 20 })
        }

    }

    createSemaforo = (page, articolo) => {
        if (!this.immaginiSemaforo) return
        const { height, width } = page.getSize()
        const immagineSemaforo = this.immaginiSemaforo[articolo.semaforo]
        const scalingFactor = 3
        const semaforoSize = { width: immagineSemaforo.width / scalingFactor, height: immagineSemaforo.height / scalingFactor }
        page.drawImage(immagineSemaforo, { x: width / 2 - semaforoSize.width / 2, y: height - 165, ...semaforoSize })
    }

    createContent = (page, articolo) => {
        if (!this.document || !this.font || !this.fontBold) return

        // SINTESI ANIA        
        PDFGenerationUtils.drawCenteredText(page, { text: `SINTESI ANIA ${articolo.dataCommentiAnia}`, color: mainColors.colorBlack, font: this.fontBold, textSize: 14, y: 185 })

        PDFGenerationUtils.drawCenteredTextField(page, { text: articolo.contenuto, width: 450, height: 310, y: 510, font: this.fontBold, color: mainColors.colorBlue, textSize: mainFontSizes.fontSizeText })
        // PDFGenerationUtils.drawWrappedTextInBox(page, articolo.contenuto, { color: rgb(98 / 255, 115 / 255, 146 / 255), font: this.font, textSize: mainFontSizes.fontSizeText, width: 450, height: 300, y: 200 })

        // IVASS
        PDFGenerationUtils.drawCenteredText(page, { text: `COMMENTI IVASS ${articolo.dataCommentiIvass}`, color: rgb(1, 0, 0), font: this.fontBold, textSize: 14, y: 540 })
        PDFGenerationUtils.drawCenteredTextField(page, { text: articolo.contenuto_ivass, width: 450, height: 90, y: 640, font: this.fontBold, color: rgb(1, 0, 0), textSize: mainFontSizes.fontSizeText })
    }

    ellipseText = (text, textSize, lineHeight, maxWidth, maxHeight, font) => {
        let wrappedText = PDFGenerationUtils.fillParagraph(text, font, textSize, maxWidth)
        const linesNumber = wrappedText.split("\n").length
        const textApproximateHeight = lineHeight * linesNumber

        if (textApproximateHeight >= maxHeight) {
            const approximateNumberOfFittingLines = Math.floor(maxHeight / lineHeight) - 1
            let fittingTextLines = wrappedText.split("\n").slice(0, approximateNumberOfFittingLines)
            if (fittingTextLines[fittingTextLines.length - 1].trim() === "")
                fittingTextLines.splice(fittingTextLines.length - 1)
            wrappedText = fittingTextLines.join("\n") + " [...]"
        }

        return wrappedText
    }

    drawCorrelatoCriticita = (page, punti, pos, colorMain, font) => {
        if (!this.document || !this.font || !this.fontBold) return

        if (punti[0]) {
            page.drawRectangle({ x: pos[0], y: pos[1], width: 212, height: 30, color: colorMain })

            const isOverMaxWidth = font.widthOfTextAtSize(punti[0].titoloArticolo, mainFontSizes.fontSizeText) > 210
            const ellipsed = this.ellipseText(punti[0].titoloArticolo, mainFontSizes.fontSizeText, 10, 200, 30, this.fontBold)
            const textPosition = { x: pos[0] + 9, y: (pos[1] + 10) + (isOverMaxWidth ? 5 : 0) }
            page.drawText(ellipsed, { ...textPosition, size: mainFontSizes.fontSizeText, font: this.fontBold, color: mainColors.colorBlack, maxWidth: 210, lineHeight: 10 })

            const linkRect = [pos[0], pos[1], pos[0] + 212, pos[1] + 30]
            const linkURL = punti[0].isIVASS ?
                window.location.origin + "/#/print-article/" + punti[0].idArticolo :
                process.env.REACT_APP_BASENAME + "/print/articolo/" + punti[0].idArticolo
            const linkRef = PDFGenerationUtils.addExternalLink(page, linkURL, linkRect)
            page.node.addAnnot(linkRef)
        }
        if (punti[1]) {
            page.drawRectangle({ x: pos[2], y: pos[3], width: 212, height: 30, color: colorMain })

            const isOverMaxWidth = font.widthOfTextAtSize(punti[1].titoloArticolo, mainFontSizes.fontSizeText) > 210
            const ellipsed = this.ellipseText(punti[1].titoloArticolo, mainFontSizes.fontSizeText, 10, 200, 30, this.fontBold)

            const textPosition = { x: pos[2] + 9, y: (pos[3] + 10) + (isOverMaxWidth ? 5 : 0) }
            page.drawText(ellipsed, { ...textPosition, size: mainFontSizes.fontSizeText, font: this.fontBold, color: mainColors.colorBlack, maxWidth: 210, lineHeight: 10 })

            const linkRect = [pos[2], pos[3], pos[2] + 212, pos[3] + 30]
            const linkURL = punti[1].isIVASS ?
                window.location.origin + "/#/print-article/" + punti[1].idArticolo :
                process.env.REACT_APP_BASENAME + "/print/articolo/" + punti[1].idArticolo
            const linkRef = PDFGenerationUtils.addExternalLink(page, linkURL, linkRect)
            page.node.addAnnot(linkRef)
        }
    }

    createBlocchi = (page, articolo) => {
        if (!this.document || !this.font || !this.fontBold) return

        const { colorMain, colorLight } = getPalette(articolo.categoria)

        // BLOCCO PUNTI CORRELATI
        page.drawRectangle({ x: 61, y: 80, width: 220, height: 110, color: colorLight, borderColor: colorMain, borderWidth: 1.5 })
        page.drawText("ULTERIORI PUNTI CORRELATI", { x: 95, y: 170, size: mainFontSizes.fontSizeText, font: this.fontBold, color: mainColors.colorBlack })
        // CORRELATI - ITEM
        if (articolo.correlati) {
            this.drawCorrelatoCriticita(page, articolo.correlati, [65, 125, 65, 85], colorMain, this.fontBold)
        }

        // BLOCCO CRITICITA'
        page.drawRectangle({ x: 315, y: 80, width: 220, height: 110, color: colorLight, borderColor: colorMain, borderWidth: 1.5 })
        page.drawText("RIFERIMENTI IN ", { x: 382.5, y: 175, size: 12, font: this.fontBold, color: mainColors.colorBlack })
        page.drawText("\"CRITICITA' E PROPOSTE\"", { x: 362.5, y: 160, size: mainFontSizes.fontSizeText, font: this.fontBold, color: mainColors.colorBlack })
        // CRITICITA' - ITEM
        if (articolo.criticita) {
            this.drawCorrelatoCriticita(page, articolo.criticita, [319, 125, 319, 85], colorMain, this.fontBold)
        }
    }

    createFooter = (page, articolo, articoloPageNumber) => {
        if (!this.document || !this.font || !this.fontBold) return
        const { width, height } = page.getSize()
        const { colorMain, colorLight } = getPalette(articolo.categoria)

        // FOOTER
        PDFGenerationUtils.drawCenteredRectangle(page, { y: height - 65, rectWidth: width, rectHeight: 65, color: colorMain })

        // FOOTER - Referenti
        page.drawText("Referenti: ", { x: 15, y: 45, size: 12, font: this.fontBold, color: mainColors.colorBlue })
        page.drawText(articolo.referenti, { x: 75, y: 45, size: mainFontSizes.fontSizeText, font: this.font, color: mainColors.colorBlue, maxWidth: 345, lineHeight: 14 })
        // FOOTER - Aggiornamento
        // page.drawText("Aggiornamento: ", { x: 15, y: 15, size: mainFontSizes.fontSizeText, font: this.fontBold, color: mainColors.colorBlue })
        // page.drawText(articolo.dataAggiornamento, { x: 115, y: 15, size: mainFontSizes.fontSizeText, font: this.font, color: mainColors.colorBlue, maxWidth: 345, lineHeight: 14 })

        // FOOTER - Page Count
        page.drawRectangle({ x: 450, y: 0, width: 300, height: 65, color: colorLight, ySkew: degrees(-35) })
        page.drawText(articoloPageNumber ? articoloPageNumber.toString() : '1', { x: 550, y: 30, size: mainFontSizes.fontSizeText, font: this.font, color: mainColors.colorBlue, lineHeight: 14 })
    }

    getPdfBytes = async () => {
        if (!this.document) {
            const pdfDoc = await PDFDocument.create()
            return pdfDoc.save()
        }
        const pdfBytes = await this.document.save()
        return pdfBytes
    }

    getArticoliAttiviByCategoria() {
        const articoliAttivi = this.libro.articoli
            .filter(a => a.semaforo !== "Chiuso")
            .sort((a, b) => a.categoria.toLowerCase() > b.categoria.toLowerCase() ? 1 : -1)

        const groupedBy = _.groupBy(articoliAttivi, "categoria");
        const groupedAndSorted = {}
        _.keys(groupedBy).forEach(key => {
            groupedAndSorted[key] = _.sortBy(groupedBy[key], a => { return prioritaSemaforo[a.semaforo] })
        })

        return groupedAndSorted
    }

    getArticoliChiusiByCategoria() {
        const articoliChiusi = this.libro.articoli.filter(a => a.semaforo === "Chiuso").sort((a, b) => a.categoria.toLowerCase() > b.categoria.toLowerCase() ? 1 : -1)
        return _.groupBy(articoliChiusi, "categoria");
    }
}

const prioritaSemaforo = {
    "UrgenteImportante": 1,
    "UrgenteNonImportante": 2,
    "NonUrgenteImportante": 3,
    "NonUrgenteNonImportante": 4,
    "Chiuso": 5
}


export default PDFGenerator;