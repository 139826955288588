import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DraggableListArticolo from './DraggableListArticolo';
import imgRaccolta from '../../assets/img/libro_blu.jpg'
import { toggleSpinner, toggleToast } from '../../store/actionCreators/general';
import axiosInstance from '../../api/interceptor';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { convertiArticolo, printAndDownloadLibro } from '../PdfGeneration/PDFPrint';

const ModalComposizioneArticoli = props => {
  const { openModalComponi, toggleModalComponi, articoliSelezionati, selezioneArticolo, templateFirstSelected } = props;

  // ORDER CONTIENE L'ARRAY DA INVIARE AL BE CON ID E POSIZIONI
  const [order, setOrder] = React.useState([]);
  const [titoloRaccolta, setTitoloRaccolta] = React.useState('');
  const [validTitle, setValidTitle] = React.useState(true);

  const [prontoPerSubmission, setProntoPerSubmission] = React.useState(false);

  function checkValidation() {
    if (titoloRaccolta.length > 0) {
      setValidTitle(true)
      setProntoPerSubmission(true);
    } else {
      setValidTitle(false);
      setProntoPerSubmission(false);
    }
  }

  function resetValues() {
    toggleModalComponi();
    setTitoloRaccolta('');
    setValidTitle(true);
    setProntoPerSubmission(false);
  }

  // STAMPARE ARTICOLO
  async function printArticolo() {
    if (props.articoliSelezionati && props.articoliSelezionati.length >= 1) {
      if (templateFirstSelected === "IVASS") {
        const libro = {
          nomeLibro: titoloRaccolta,
          articoliLibro: props.articoliSelezionati
        }

        printAndDownloadLibro(libro)
      }
      else {
        const idArticoli = order.map(a => a.idArticolo); // creo un array di soli ID
        const body = { titoloRaccolta, idArticoli };

        props.toggleSpinner();
        await axiosInstance.post('/print/raccolta', body, { responseType: 'blob' })
          .then(res => {
            saveAs(res.data, `${titoloRaccolta}.docx`) // salviamo il file
          })
          .catch(messaggio => {
            props.toggleToast('e', messaggio);
          })
          .finally(() => {
            props.toggleSpinner()
          })
      }
    }
  }

  return (
    <Modal size="xl" id="modale-componi-articolo" isOpen={openModalComponi} toggle={toggleModalComponi}>
      <ModalHeader toggle={toggleModalComponi} />
      <ModalBody>
        <div className="row">
          {/* COPERTINA */}

          <div id="copertina" className="col-6 d-flex flex-column"
            style={{ backgroundImage: `url(${imgRaccolta})`, backgroundSize: "100% 100%" }}>

            <div className="d-flex justify-content-end h-25 padding-right-10 text-right" />

            <div className="d-flex flex-column h-75 margin-left-40" style={{ maxWidth: '200px', color: '#97a9c9' }}>
              <span className="text-uppercase font-weight-bold" style={{ fontFamily: "Raleway-Black", fontSize: '28px' }}>RACCOLTA ARTICOLI</span>

            </div>
          </div>

          {/* CONTENUTO */}
          <div id="contenuto" className="col-md-6">
            <div className="row padding-top-40">
              <div className="col-12">
                <h4 className="text-uppercase">
                  <i className="fas fa-list color-azure mr-2" /> indice
                </h4>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12 color-main-text">
                <label>Titolo della raccolta</label>
                <input type="text" className={`form-control form-control-sm ${validTitle ? null : 'is-invalid'}`} disabled={prontoPerSubmission}
                  value={titoloRaccolta} onChange={e => setTitoloRaccolta(e.target.value)} />
                <div className="invalid-feedback">{!validTitle ? 'Inserisci il titolo della raccolta' : null}</div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 color-main-text">
                Spostare le voci sotto elencate per personalizzare l'indice della raccolta
              </div>
            </div>

            <div className={`row my-3 ${prontoPerSubmission ? 'disabled-element' : null}`}>
              <div className="col-12 custom-overflow">
                {articoliSelezionati && <DraggableListArticolo
                  items={articoliSelezionati}
                  selezioneArticolo={selezioneArticolo}
                  setOrder={setOrder}
                />}
              </div>
            </div>

            <div className={`row mt-3 ${prontoPerSubmission ? 'justify-content-between' : ''}`}>
              {prontoPerSubmission ? null : <>
                <div className="col-md-3 offset-3 text-center">
                  <div className="btn outline-azure btn-sm text-uppercase d-block" onClick={resetValues}>
                    annulla
                  </div>
                </div>
                <div className="col-md-3 text-center">
                  <div className="btn btn-sm btn-azure text-uppercase d-block" onClick={checkValidation}>
                    fatto
                  </div>
                </div></>
              }

              {/*AZIONI MODIFICA / STAMPA / DOWNLOAD */}
              {!prontoPerSubmission ? null :
                (<>
                  <div className="col-md-4">
                    <div className="d-flex action-container justify-content-center cursor-pointer" onClick={resetValues}>
                      <i className="fas fa-times-circle color-rosso mr-1" aria-label="Chiudi" /><small className="font-italic">Chiudi</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex action-container justify-content-center cursor-pointer" onClick={() => setProntoPerSubmission(false)}>
                      <i className="fas fa-edit color-rosso mr-1" aria-label="Modifica" /><small className="font-italic">Modifica</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex action-container justify-content-center cursor-pointer" onClick={printArticolo}>
                      <i className="fas fa-file-download color-rosso mr-1" aria-label="download" /><small className="font-italic">Download</small>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="d-flex action-container justify-content-center cursor-pointer">
                      <i className="fas fa-print color-rosso mr-1" aria-label="print" /><small className="font-italic">Stampa</small>
                    </div>
                  </div> */}
                </>)
              }

            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
}

export default connect(null, { toggleSpinner, toggleToast })(ModalComposizioneArticoli);