import React from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DraggableList from "./DraggableList";
import axiosInstance from "../../api/interceptor";
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import { connect } from "react-redux";
import { updateTabella } from "../../store/actionCreators/Libro";
import { statoPubblicazioneLibro, statoPubblicazioneArticolo, tipoUtente } from '../../assets/utils/enum';
import ListaArticoliAssociati from "../../pages/GestioneLibri/components/ListaArticoliAssociati";
import { saveAs } from 'file-saver';

import { printAndDownloadLibro } from "../PdfGeneration/PDFPrint"

const pubblicaLibroURL = (conOrdinamento, libroId) => `/libri/pubblica/${conOrdinamento}/${libroId}`;
const modificaStatoLibroURL = (idLibro, stato) => `libri/${idLibro}/stato/${stato}`

const PubblicaLibro = props => {

  const history = useHistory();

  // FETCH DATI UTENTE DA SESSION STORAGE
  const currentUser = JSON.parse(atob(sessionStorage.getItem('token')));

  PubblicaLibro.propTypes = {
    toggleSpinner: PropTypes.func.isRequired,
    toggleToast: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    selectedLibro: PropTypes.shape({})
  };

  const {
    toggleSpinner,
    toggleToast,
    open,
    toggle,
    selectedLibro,
    selectedModel,
    updateTabella
  } = props;

  const {
    //    id,
    nomeLibro,
    statoLibro,
    articoliLibro,
    modelloLibroRes: { nomeModelloLibro, copertina: { immagine } }
  } = selectedLibro;

  const [order, setOrder] = React.useState([]);
  const [openWarningArticoli, setOpenWarningArticoli] = React.useState(false);

  const [loadingPubblica, setLoadingPubblica] = React.useState(false);
  const [loadingRimuovi, setLoadingRimuovi] = React.useState(false);

  const pubblicaLibroOrdinato = async () => {
    if (selectedLibro.libroConArticoliNonPubblicati) {
      setOpenWarningArticoli(!openWarningArticoli)
    } else {
      setLoadingPubblica(true)
      await axiosInstance.put(pubblicaLibroURL(
        selectedLibro.libroMaiPubblicato,
        selectedLibro.id
      ), order)
        .then(r => {
          if (r && r.status === 200) {
            updateTabella();
            toggle();
            toggleToast('s', `Libro "${nomeLibro}" pubblicato con successo!`)
          }
        })
        .catch(e => e && e.message ?
          toggleToast('e', e.message) : console.log(e))
        .finally(() => setLoadingPubblica(false));
    }
  }

  const rimuoviPubblicazioneLibro = async () => {
    setLoadingRimuovi(true);
    await axiosInstance.put(modificaStatoLibroURL(
      selectedLibro.id,
      false
    ))
      .then(r => {
        if (r && r.status === 200) {
          updateTabella();
          toggle();
          toggleToast('s', `Pubblicazione rimossa: "${nomeLibro}"`)
        }
      })
      .catch(e => e && e.message ?
        toggleToast('e', e.message) : console.log(e))
      .finally(() => setLoadingRimuovi(false));
  }

  async function printLibro() {
    toggleSpinner();
    await axiosInstance.get(`/print/libro/${selectedLibro.id}`, { responseType: 'blob' })
      .then(res => {
        let title = nomeLibro.toLowerCase().replace(/ /g, '_'); // creiamo il titolo per il file docx
        saveAs(res.data, `${title}.docx`) // salviamo il file
      })
      .catch(messaggio => {
        toggleToast('e', messaggio);
      })
      .finally(() => {
        toggleSpinner()
      })
  }

  async function printLibroPDF() {
    toggleSpinner()
    await printAndDownloadLibro(selectedLibro)
      .then(() => {toggleSpinner()})
  }

  return (
    <Modal size="xl" id="modale-componi-libro" isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <div className="row" style={{ minHeight: '700px' }}>
          {/* COPERTINA */}
          <div id="copertina" className="col-6 d-flex flex-column"
            style={{ backgroundImage: `url("data:image/png;base64, ${immagine}")`, backgroundSize: "100% 100%" }}>

            <div className="d-flex justify-content-end h-25 padding-right-10 text-right">
              <p className="margin-top-60 w-75 font-italic" style={{ fontFamily: "OpenSans-Regular", fontSize: '18px', color: '#97a9c9' }} />
            </div>

            <div className="d-flex flex-column h-75 margin-left-40" style={{ maxWidth: '200px', color: '#97a9c9' }}>
              <span className="text-uppercase font-weight-bold" style={{ fontFamily: "Raleway-Black", fontSize: '28px' }}>{nomeModelloLibro}</span>
              <p className="mt-2 font-italic" style={{ fontFamily: "OpenSans-Regular", fontSize: '18px' }}>{nomeLibro}</p>
            </div>
          </div>

          {/*  CONTENUTO  */}
          <div id={`contenuto`} className={`col-6`}>
            {
              articoliLibro && articoliLibro.length > 0 ?
                <>
                  <div className="row padding-top-40">
                    <div className="col-12">
                      <h4 className="text-uppercase">
                        <i className="fas fa-list color-azure mr-2" /> indice
                      </h4>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-12 color-main-text">
                      { /* Frase sotto il titolo INDICE appare solo in gestione libri per libri in stato di lavorazione */
                        history.location && history.location.pathname === '/gestione-libri' 
                        && statoLibro === statoPubblicazioneLibro.IN_LAVORAZIONE && !selectedModel.ivass
                        ? "Spostare le voci sotto elencate per personalizzare l'indice della raccolta" : ''
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 custom-overflow">
                      {articoliLibro && <DraggableList
                        selectedModel={selectedModel}
                        items={articoliLibro}
                        setOrder={setOrder}
                        libroPubblicato={statoLibro === statoPubblicazioneLibro.PUBBLICATO}
                      />}
                    </div>
                  </div>

                  <div className="row margin-top-60">

                    <div className="col-md-4">
                      <div className="d-flex action-container justify-content-center cursor-pointer" onClick={toggle}>
                        <i className="fas fa-times-circle color-rosso mr-1" aria-label="Modifica" /><small className="font-italic">Chiudi</small>
                      </div>
                    </div>


                    <div className="col-md-4">
                      {//  Controllo il tipo di utente per definire la seconda icona/azione
                        currentUser.ruoloUtente !== tipoUtente.ADMIN

                          ? null

                          : statoLibro === statoPubblicazioneLibro.PUBBLICATO
                            ? (<div className="d-flex action-container justify-content-center cursor-pointer" onClick={rimuoviPubblicazioneLibro}>
                              <i className="fas fa-edit color-rosso mr-1" aria-label="Riporta in bozza" /><small className="font-italic">Riporta in bozza</small>
                            </div>)
                            : (<div className="d-flex action-container justify-content-center cursor-pointer" onClick={pubblicaLibroOrdinato}>
                              <i className="fas fa-book color-rosso mr-1" aria-label="Pubblica" /><small className="font-italic">Pubblica</small>
                            </div>)
                      }
                    </div>

                    <div className="col-md-4">
                      {selectedLibro.modelloLibroRes.template === "IVASS" ? (
                        <div className="d-flex action-container justify-content-center cursor-pointer" onClick={printLibroPDF}>
                          <i className="fas fa-file-download color-rosso mr-1" aria-label="download" /><small className="font-italic">Download (PDF)</small>
                        </div>
                      ) : (
                          <div className="d-flex action-container justify-content-center cursor-pointer" onClick={printLibro}>
                            <i className="fas fa-file-download color-rosso mr-1" aria-label="download" /><small className="font-italic">Download</small>
                          </div>
                        )}
                    </div>

                  </div>
                </>
                :
                <>
                  <div className="row padding-top-40">
                    <div className="col-12">
                      <h4 className="text-uppercase">
                        <i className="fas fa-list color-azure mr-2" /> indice
                      </h4>
                    </div>
                  </div>
                  <div className="mt-3">Questo libro non ha articoli</div>
                  <div className="d-flex justify-content-center">
                    <div className="btn outline-azure btn-sm ml-1 mt-5 text-uppercase" onClick={toggle}>chiudi</div>
                  </div>
                </>
            }
            {
              openWarningArticoli && <ListaArticoliAssociati
                open={openWarningArticoli}
                toggle={() => setOpenWarningArticoli(!openWarningArticoli)}
                title="Attenzione"
                text="I seguenti articoli necessitano di essere prima pubblicati"
                articoli={articoliLibro.filter(a => a.statoArticolo !== statoPubblicazioneArticolo.pubblicato)}
                onDeny={() => setOpenWarningArticoli(!openWarningArticoli)}
                denyButtonText="chiudi"
              />
            }
          </div>
          {/*  CONTENUTO  */}
        </div>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleToast: (s, m) => dispatch(toggleToast(s, m)),
  toggleSpinner: () => dispatch(toggleSpinner()),
  updateTabella: () => dispatch(updateTabella())
})

export default connect(null, mapDispatchToProps)(PubblicaLibro);
