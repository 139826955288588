/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'reactstrap';

const TooltipWarning = props => {

    const {open, toggle, targetId, text, placement} = props;
    return (
      <Tooltip placement={placement} isOpen={open} target={targetId} toggle={toggle}>
        {text}
      </Tooltip>
    );
  }
;

Tooltip.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  targetId: PropTypes.string,
  text: PropTypes.string,
  placement: PropTypes.string
};

export default TooltipWarning;
