import React from 'react';
import { NavLink } from 'react-router-dom';
import axiosInstance from '../../api/interceptor';
import Articolo from "../../components/Articolo/Articolo";
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import { getListaArticoli } from '../../store/actionCreators/staticData';
import { tipoUtente, enumTemplate, statoPubblicazioneArticolo } from '../../assets/utils/enum';
import Pagination from "../../components/Pagination/Pagination";
import Swal from "sweetalert2";
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';
import classNames from 'classnames';

const bootstrapSpinner = (<div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>)

const initialState = {
  articoli: [],
  articoliPerPage: 10,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0
}

// Stato iniziale dei filtri di ricerca per stato di pubblicazione
const statusPubblicazione = [
  { label: "articolo pubblicato", icon: "far fa-check-circle color-verde ml-1 font-weight-bold", status: statoPubblicazioneArticolo.PUBBLICATO, selected: true },
  { label: "articolo in approvazione", icon: "far fa-hourglass ml-1", status: statoPubblicazioneArticolo.ATTESA_PUBBLICAZIONE, selected: true },
  { label: "articolo in bozza", icon: "far fa-edit ml-1", status: statoPubblicazioneArticolo.BOZZA, selected: true }
]

function RevisioneArticoli(props) {

  const { toggleToast, toggleSpinner, getListaArticoli } = props;

  // Gestione visiva del loading di articoli
  const [msgAllArticoli, setMsgAllArticoli] = React.useState(bootstrapSpinner);
  const [msgArticoliIvass, setMsgArticoliIvass] = React.useState(bootstrapSpinner);

  const [update, setUpdate] = React.useState(false);

  const [paramStatusPubblicazione, setParamStatusPubblicazione] = React.useState(statusPubblicazione)

  const [articoliCriticita, setArticoliCriticita] = React.useState(initialState);
  const [articoliIvass, setArticoliIvass] = React.useState(initialState);

  const [selectedModel, setSelectedModel] = React.useState({ criticita: true, ivass: false })

  const paginateCriticita = pageNumber => setArticoliCriticita(prevState => ({ ...prevState, currentPage: pageNumber }));
  const setArticoliPerPageCriticita = upp => setArticoliCriticita(prevState => ({ ...prevState, articoliPerPage: upp }));

  const paginateIvass = pageNumber => setArticoliIvass(prevState => ({ ...prevState, currentPage: pageNumber }));
  const setArticoliPerPageIvass = upp => setArticoliIvass(prevState => ({ ...prevState, articoliPerPage: upp }));

  const getSortParams = (filtro) => {
    // aggiungere &statoArticolo=BOZZA,ATTESA_PUBBLICAZIONE,PUBBLICATO per filtrare in base allo stato
    let params = `/articoli`;
    const { articoliPerPage, currentPage } = articoliCriticita;
    params += `?size=${articoliPerPage}&page=${currentPage}&modelloLibro=${'CRITICITA'}`;
    if (filtro) params += `&statoArticolo=${filtro}`
    return params;
  }

  const getSortParamsIvass = (filtro) => {
    // aggiungere &statoArticolo=BOZZA,ATTESA_PUBBLICAZIONE,PUBBLICATO per filtrare in base allo stato
    let params = `/articoli`;
    const { articoliPerPage, currentPage } = articoliIvass;
    params += `?size=${articoliPerPage}&page=${currentPage}&modelloLibro=${enumTemplate.IVASS}&statoArticolo=${filtro}`;
    return params;
  }

  // AGGIORNARE LO STATO DI UN ARTICOLO
  async function updateStatusArticolo(id, status) {
    toggleSpinner();
    await axiosInstance.put(`/articoli/${id}/stato/${status}`)
      .then(r => {
        if (r && r.status === 200) {
          setUpdate(!update)
          toggleToast('s', 'Stato aggiornato con successo')
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => toggleSpinner())
  }

  // ELIMINARE UN ARTICOLO
  async function deleteArticolo(id) {
    toggleSpinner();
    await axiosInstance.delete(`/articoli/${id}`)
      .then(r => {
        if (r && r.status === 200) {
          setUpdate(!update)
          toggleToast('s', 'Articolo eliminato')
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => toggleSpinner())
  }

  // Fetch dell'elenco degli articoli (serve per la creazione/modifica di un articolo)
  const fetchListaArticoli = async () => {
    const r = await axiosInstance.get('/articoli/slim');
    getListaArticoli(r.data)
  }

  React.useEffect(() => {
    fetchListaArticoli()
  }, []) // eslint-disable-line

  // ALERT PER CONFERMARE L'ELIMINAZIONE DI UN ARTICOLO
  const confirmDelete = (id, titolo) => {
    Swal.fire({
      title: "Attenzione! Stai eliminando l'articolo",
      text: `${titolo}`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText:
        `<i class="fas fa-check" style="font-family: 'Font Awesome 5 Free'" aria-label="Conferma"/>
            <span style="font-family: 'OpenSans-Regular'">Conferma</span>`,
      confirmButtonAriaLabel: 'Conferma',
      cancelButtonText:
        `<i class="fa fa-times" style="font-family: 'Font Awesome 5 Free'" aria-label="Annulla"/>
            <span style="font-family: OpenSans-Regular">Annulla</span>`,
      cancelButtonAriaLabel: 'Annulla',
      preConfirm: () => deleteArticolo(id)
    })
  }

  React.useEffect(() => {
    const filterParams = paramStatusPubblicazione.filter(el => el.selected).map(el => el.status)

    if (selectedModel.criticita) {
      toggleSpinner();
      axiosInstance.get(getSortParams(filterParams))
        .then(({ data }) => {
          const { content, totalElements, totalPages } = data;
          setArticoliCriticita(prevState => ({
            ...prevState,
            articoli: content,
            totalItems: totalElements,
            totalPages: totalPages,
          }))
        })
        .catch(({ messaggio }) => {
          toggleToast('e', messaggio);
        })
        .finally(() => {
          setMsgAllArticoli('Nessun articolo');
          toggleSpinner()
        })
    }

    else {
      toggleSpinner();
      axiosInstance.get(getSortParamsIvass(filterParams))
        .then(({ data }) => {
          const { content, totalElements, totalPages } = data;
          setArticoliIvass(prevState => ({
            ...prevState,
            articoli: content,
            totalItems: totalElements,
            totalPages: totalPages,
          }))
        })
        .catch(({ messaggio }) => {
          toggleToast('e', messaggio);
        })
        .finally(() => {
          setMsgArticoliIvass('Nessun articolo');
          toggleSpinner()
        })
    }

  }, [articoliCriticita.articoliPerPage, articoliCriticita.currentPage, articoliCriticita.totalItems, articoliCriticita.totalPages,  // eslint-disable-line
  articoliIvass.articoliPerPage, articoliIvass.currentPage, articoliIvass.totalItems, articoliIvass.totalPages, selectedModel, // eslint-disable-line
    paramStatusPubblicazione, update]); // eslint-disable-line

  // PRENDERE LE INFO DELL'USER DAL SESSION STORAGE
  const currentUser = JSON.parse(atob(sessionStorage.getItem('token')));

  return (
    <div id="revisione-articoli" className="container">
      <BackToPrevious goBack={() => props.history.goBack()} />

      <div id="libri-container">
        <div className="row">
          <div className="title col-md-6">
            <i className="fas fa-th" />
            {currentUser.ruoloUtente === tipoUtente.EDITOR ? 'i miei articoli' : 'Revisione articoli'}
          </div>
          <div className="col-md-6">
            <small className="font-italic text-secondary float-right padding-right-10">
              <NavLink className="go-to color-main-color" to='/nuovo-articolo'>
                <i className="far fa-file text-danger" /> Crea nuovo articolo
              </NavLink>
            </small>
          </div>
        </div>

        <div className="libri-container mt-4">
          <div className="d-flex w-100 font-italic">
            <div className="tab-article-model mr-5"
              onClick={() => setSelectedModel({ criticita: true, ivass: false })} >
              <span className={classNames("item px-2", { 'selected': selectedModel.criticita })}>
                <i className="fas fa-th mr-2" />
                Criticità e Proposte
              </span>
            </div>
            <div className="tab-article-model"
              onClick={() => setSelectedModel({ criticita: false, ivass: true })} >
              <span className={classNames("item px-2", { 'selected': selectedModel.ivass })}>
                <i className="fas fa-th mr-2" />
                ANIA-IVASS
              </span>
            </div>
          </div>
        </div>

        <div className="libri-container">
          <div className="d-flex flex-wrap mt-4 w-100 font-italic" style={{ fontSize: '0.75rem' }}>

            {
              React.Children.toArray(paramStatusPubblicazione.map(el => (
                <div className="d-flex align-items-center mr-5">
                  
                  <input
                    type="checkbox"
                    className="mr-1"
                    checked={el.selected}
                    onChange={() => {
                      setParamStatusPubblicazione(paramStatusPubblicazione.map(item => item.label === el.label ? ({...item, selected: !item.selected}) : item))
                    }}
                    value={el.status}
                  /> {el.label} <i className={el.icon} aria-label={el.label} />
                
                </div>
              )))
            }

        </div>
      </div>

      {selectedModel.criticita && <>
        <div className="libri-container">
          { /* UN MESSAGGIO E' VISUALIZZATO SE NON CI SONO ARTICOLI */
            (!articoliCriticita.articoli || articoliCriticita.articoli.length === 0)
              ? (<div className="nessuna-pubblicazione justify-content-center align-items-center color-main-color">
                {msgAllArticoli}
              </div>)
              : articoliCriticita.articoli.map(a => (
                <Articolo key={a.id}
                  {...a}
                  revisioneArticoli={true}
                  confirmDelete={confirmDelete}
                  updateStatusArticolo={updateStatusArticolo}
                  seeFlags={true} />
              ))
          }
        </div>
        { /* PAGINAZIONE VISIBILE SOLO SE CI SONO ARTICOLI */
          articoliCriticita.articoli.length > 0
          && <Pagination
            itemsPerPage={articoliCriticita.articoliPerPage}
            totalItems={articoliCriticita.totalItems}
            currentPage={articoliCriticita.currentPage}
            totalPages={articoliCriticita.totalPages}
            setItemsPerPage={setArticoliPerPageCriticita}
            paginate={paginateCriticita}
          />
        }
      </>}

      {/* {articoliCriticita.articoli.length > 0
          && articoliIvass.articoli.length > 0
          && <div className="mt-4 mb-2" style={{ height: '3px', backgroundColor: "rgb(0, 90, 142)" }} />
        } */}

      {selectedModel.ivass && <>
        {/* <div className="libri-container">
            <div className="row mt-4 w-100 font-italic">
              <div className="col-md-6 d-flex align-items-center color-main-color">
                <i className="fas fa-th mr-2" />
              ANIA-IVASS
            </div>
            </div>
          </div> */}

        <div className="libri-container">
          { /* UN MESSAGGIO E' VISUALIZZATO SE NON CI SONO ARTICOLI */
            (!articoliIvass.articoli || articoliIvass.articoli.length === 0)
              ? (<div className="nessuna-pubblicazione justify-content-center align-items-center color-main-color">
                {msgArticoliIvass}
              </div>)
              : articoliIvass.articoli.map(a => (
                <Articolo key={a.id}
                  {...a}
                  revisioneArticoli={true}
                  confirmDelete={confirmDelete}
                  updateStatusArticolo={updateStatusArticolo}
                  seeFlags={true} />
              ))
          }
        </div>
        { /* PAGINAZIONE VISIBILE SOLO SE CI SONO ARTICOLI */
          articoliIvass.articoli.length > 0
          && <Pagination
            itemsPerPage={articoliIvass.articoliPerPage}
            totalItems={articoliIvass.totalItems}
            currentPage={articoliIvass.currentPage}
            totalPages={articoliIvass.totalPages}
            setItemsPerPage={setArticoliPerPageIvass}
            paginate={paginateIvass}
          />
        }
      </>}

    </div>

    </div >
  );
};

export default connect(null, { toggleToast, toggleSpinner, getListaArticoli })(RevisioneArticoli);
