import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT
} from '../actions/auth';

const initialState = {
    user: null,
    loading: false,
    error: false,
    authorities: [],
    authCheckEnd: false,
    isLoading: false
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case AUTH_START: {
            return {
                ...state,
                error: null,
                loading: true,
                authCheckEnd: false,
                isLoading: true
            }
        }
        case AUTH_SUCCESS: {
            const { user, authorities } = action;
            return {
                ...state,
                loading: false,
                user,
                authorities,
                error: false,
                authCheckEnd: true,
                isLoading: false
            }
        }
        case AUTH_FAIL: {
            const { error } = action;
            return {
                ...state,
                error,
                loading: false,
                authCheckEnd: true,
                isLoading: false
            }
        }
        case AUTH_LOGOUT: {
            return {
                user: null,
                loading: false,
                error: false
            }
        }
        default:
            return state;
    }
};
