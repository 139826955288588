import { GET_LIVELLI_IMPORTANZA_ARTICOLI, GET_TEMATICHE_ARTICOLI, GET_TEMPLATES_MODELLI, GET_AUTORI, GET_LISTA_ARTICOLI, GET_STATI_UPDATE_ARTICOLO } from '../actions/staticData';

const initialState = {
  listaAutori: [],
  templateModelli: [],
  importanzaArticoli: [],
  tematicheArticoli: [],
  listaArticoli: [],
  statiUpdateArticolo: []
}

export const staticData = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES_MODELLI:
      return { ...state, templateModelli: action.payload.map((el, idx) => idx === 0 ? ({...el, checked: true}) : ({...el, checked: false})) };
    case GET_LIVELLI_IMPORTANZA_ARTICOLI:
      return { ...state, importanzaArticoli: action.payload };
    case GET_TEMATICHE_ARTICOLI:
      return { ...state, tematicheArticoli: action.payload };
    case GET_AUTORI:
      return { ...state, listaAutori: action.payload };
    case GET_LISTA_ARTICOLI:
      return {...state, listaArticoli: action.payload};
      case GET_STATI_UPDATE_ARTICOLO:
        return {...state, statiUpdateArticolo: action.payload};
    default:
      return state
  }
}