import {REMOVE_SELECTED_LIBRO, SET_SELECTED_LIBRO, UPDATE_TABELLA} from "../actions/Libro";

export const setSelectedLibro = libro => dispatch => {
  return dispatch({ type: SET_SELECTED_LIBRO, payload: libro })
}

export const removeSelectedLibro = () => dispatch => {
  return dispatch({ type: REMOVE_SELECTED_LIBRO })
}

export const updateTabella = () => dispatch => {
  return dispatch({ type: UPDATE_TABELLA })
}
