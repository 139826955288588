import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import { setSelectedLibro, updateTabella } from "../../store/actionCreators/Libro";
import Pagination from "../../components/Pagination/Pagination";
import axiosInstance from "../../api/interceptor";
import NuovoLibro from "../NuovoLibro/NuovoLibro";
import PubblicaLibro from "../../components/PubblicaLibro/PubblicaLibro";
import ListaArticoliAssociati from "./components/ListaArticoliAssociati";
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';
import { enumTemplate } from '../../assets/utils/enum';
import classNames from 'classnames';

const bootstrapSpinner = (<div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>)

// Ogni campo che viene aggiunto qui è oggetto di sort (check sortIcon())
const header = [
  { value: 'copertina', displayValue: 'Copertina' },
  { value: 'modelloLibro.nomeModelloLibro', displayValue: 'Modello' },
  { value: 'nomeLibro', displayValue: 'Titolo' },
  { value: 'clonato', displayValue: 'Clonato da' },
  { value: 'categorie', displayValue: 'Categorie' },
  { value: 'statoLibro', displayValue: "Stato" },
  { value: 'azioni', displayValue: 'Azioni' },
]

const initialState = {
  libri: [],
  libriPerPage: 10,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  sortField: 'nomeLibro',
  sortDirection: 'asc'
}

const GestioneLibri = props => {

  GestioneLibri.propTypes = {
    toggleSpinner: PropTypes.func.isRequired,
    toggleToast: PropTypes.func.isRequired,
    update: PropTypes.bool.isRequired,
    updateTabella: PropTypes.func.isRequired,
    setSelectedLibro: PropTypes.func.isRequired,
    selectedLibro: PropTypes.shape({})
  }

  const {
    toggleToast,
    toggleSpinner,
    update,
    updateTabella,
    selectedLibro,
    setSelectedLibro
  } = props;

  const [openWarningArticoli, setOpenWarningArticoli] = React.useState(false);

  const [libriCriticita, setLibriCriticita] = React.useState(initialState);
  const paginateCriticita = pageNumber => setLibriCriticita(prevState => ({ ...prevState, currentPage: pageNumber }));
  const setLibriPerPageCriticita = upp => setLibriCriticita(prevState => ({ ...prevState, libriPerPage: upp }));

  const [libriIvass, setLibriIvass] = React.useState(initialState);
  const paginateIvass = pageNumber => setLibriIvass(prevState => ({ ...prevState, currentPage: pageNumber }));
  const setLibriPerPageIvass = upp => setLibriIvass(prevState => ({ ...prevState, libriPerPage: upp }));

  const [selectedModel, setSelectedModel] = React.useState({ criticita: true, ivass: false })

  // Gestione visiva del loading di libri
  const [msgLibriCriticità, setMsgLibriCriticità] = React.useState(bootstrapSpinner);
  const [msgLibriIvass, setMsgLibriIvass] = React.useState(bootstrapSpinner);

  //HOOKS MODALE COMPONI LIBRO
  const [componiLibro, setComponiLibro] = React.useState(false);
  //HOOKS MODALE COMPONI LIBRO

  const [collapse, setCollapse] = useState(false);

  const deleteLibroById = (id, nomeLibro) => {
    toggleSpinner();
    axiosInstance.delete(`/libri/${id}`)
      .then(({ status }) => {
        if (status && status === 200) {
          updateTabella()
          toggleToast('s', `Libro "${nomeLibro}" eliminato con successo.`)
        }
      })
      .catch(e => e && e.messaggio ? toggleToast('e', e.messaggio) : console.log(e))
      .finally(() => toggleSpinner())
  }

  const clonaLibro = (id, nomeLibro) => {
    toggleSpinner();
    axiosInstance.post(`libri/${id}/clona`)
      .then(({ status }) => {
        if (status && status === 200) {
          updateTabella()
          toggleToast('s', `Libro "${nomeLibro}" clonato con successo.`)
        }
      })
      .catch(e => e && e.messaggio ? toggleToast('e', e.messaggio) : console.log(e))
      .finally(() => toggleSpinner())
  }

  const getSortParamsCriticità = () => {
    let params = `/libri`;
    const { libriPerPage, currentPage, sortField, sortDirection } = libriCriticita;
    params += `?size=${libriPerPage}&page=${currentPage}&modelloLibro=${'CRITICITA'}&sort=${sortField},${sortDirection}`;
    return params;
  }

  const getSortParamsIvass = () => {
    let params = `/libri`;
    const { libriPerPage, currentPage, sortField, sortDirection } = libriIvass;
    params += `?size=${libriPerPage}&page=${currentPage}&modelloLibro=${enumTemplate.IVASS}&sort=${sortField},${sortDirection}`;
    return params;
  }

  const getAllLibri = async () => {
    toggleSpinner();
    await axiosInstance.get(selectedModel.criticita ? getSortParamsCriticità() : getSortParamsIvass())
      .then((r) => {
        if (selectedModel.criticita) {
          setLibriCriticita(prevState => ({
            ...prevState,
            libri: r.data.content,
            totalItems: r.data.totalElements,
            totalPages: r.data.totalPages,
          }))
        } else {
          setLibriIvass(prevState => ({
            ...prevState,
            libri: r.data.content,
            totalItems: r.data.totalElements,
            totalPages: r.data.totalPages,
          }))
        }
      })
      .catch(e => e && e.messaggio ? toggleToast('e', e.messaggio) : null)
      .finally(() => {
        selectedModel.criticita ? setMsgLibriCriticità('Nessun libro') : setMsgLibriIvass('Nessun libro');
        toggleSpinner()
      })
  }

  React.useEffect(() => {
    setSelectedLibro(null); //Clean selectedLibro every mount
    getAllLibri();
  }, [libriCriticita.libriPerPage, libriCriticita.currentPage, libriCriticita.sortField, libriCriticita.sortDirection,  // eslint-disable-line
  libriIvass.libriPerPage, libriIvass.currentPage, libriIvass.sortField, libriIvass.sortDirection, selectedModel,  // eslint-disable-line
    update]); // eslint-disable-line

  const setSelectedLibroAndEdit = (libro, edit) => {
    setSelectedLibro(libro);
    if (edit) setCollapse(!collapse);
  }

  const sortIconCriticita = field => {
    return (field === 'modelloLibro.nomeModelloLibro') || (field === 'nomeLibro')
      ? <i
        className={`fas ${field !== libriCriticita.sortField ? 'fa-sort' :
          libriCriticita.sortField && libriCriticita.sortDirection === 'asc' ?
            'fa-caret-up' : 'fa-caret-down'
          } cursor-pointer`}
        onClick={() => setLibriCriticita(prevState => ({
          ...prevState,
          sortField: field,
          sortDirection: field !== libriCriticita.sortField ? 'asc' :
            libriCriticita.sortDirection === 'asc' ? 'desc' : 'asc'
        }))}
      />
      : null;
  }

  const sortIconIvass = field => {
    return (field === 'modelloLibro.nomeModelloLibro') || (field === 'nomeLibro')
      ? <i
        className={`fas ${field !== libriIvass.sortField ? 'fa-sort' :
          libriIvass.sortField && libriIvass.sortDirection === 'asc' ?
            'fa-caret-up' : 'fa-caret-down'
          } cursor-pointer`}
        onClick={() => setLibriIvass(prevState => ({
          ...prevState,
          sortField: field,
          sortDirection: field !== libriIvass.sortField ? 'asc' :
            libriIvass.sortDirection === 'asc' ? 'desc' : 'asc'
        }))}
      />
      : null;
  }

  const handlePagetitle = () => {
    return collapse && selectedLibro ? "modifica libro" :
      collapse && !selectedLibro ? "crea libro" : "gestione libri"
  }

  return (
    <div id="gestione-libri" className="container">
      <BackToPrevious goBack={() => props.history.goBack()} />
      <div className="title-gestione-libri">
        <div className="title">
          <i className="fas fa-th" />
          {handlePagetitle()}

          <div className="btn btn-sm btn-azure float-right padding-right-40 padding-right-40"
            onClick={() => {
              if (selectedLibro) setSelectedLibro(null);
              setCollapse(!collapse)
            }}>
            <i className="fas fa-plus" /> Crea nuovo libro
          </div>
        </div>
      </div>

      <div className="libri-container mt-4">
        <div className="d-flex w-100 font-italic">
          <div className="tab-libri-model mr-5"
            onClick={() => setSelectedModel({ criticita: true, ivass: false })} >
            <span className={classNames("item px-2", { 'selected': selectedModel.criticita })}>
              <i className="fas fa-th mr-2" />
                Criticità e Proposte
              </span>
          </div>
          <div className="tab-libri-model"
            onClick={() => setSelectedModel({ criticita: false, ivass: true })} >
            <span className={classNames("item px-2", { 'selected': selectedModel.ivass })}>
              <i className="fas fa-th mr-2" />
                ANIA-IVASS
              </span>
          </div>
        </div>
      </div>

      { // TABELLA LIBRI CRITICITA
        !selectedModel.criticita
          ? null
          : libriCriticita.libri && libriCriticita.libri.length > 0
            ?
            <>
              { //FORM LIBRO
                collapse &&
                <NuovoLibro
                  closeForm={() => setSelectedLibroAndEdit(null, true)}
                />
              }
              <table
                id="tabella-libri"
                className="table table-sm table-striped margin-top-30">
                <thead className="bg-main-color text-center color-bianco">
                  <tr>
                    {header && header.map((h, i) => <th key={i}>{h.displayValue.toUpperCase()} {sortIconCriticita(h.value)}</th>)}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {
                    React.Children.toArray(libriCriticita.libri.map(libro => {
                      const {
                        nomeLibro, statoLibro, libroCopiatoDa,
                        modelloLibroRes: { categorie, copertina: { immagine }, nomeModelloLibro }
                      } = libro;
                      return <tr>
                        {/*COPERTINA*/}
                        <td className="align-middle">
                          <img src={`data:image/png;base64, ${immagine}`} alt="Immagine di copertina" />
                        </td>
                        {/*NOME MODELLO DI LIBRO*/}
                        <td className="align-middle">
                          {nomeModelloLibro}
                        </td>
                        {/*NOME LIBRO*/}
                        <td className="align-middle">
                          {nomeLibro}
                        </td>
                        {/* CLONATO DA */}
                        <td className="align-middle">
                          {libroCopiatoDa}
                        </td>
                        {/*CATEGORIE ASSEGNATE*/}
                        <td className="align-middle mw-400px">
                          {
                            categorie && categorie.length > 0
                              ? categorie.map((cat, i) => (
                                <span
                                  key={i}
                                  style={categorie.length > 3 ? { fontSize: '14px' } : null}
                                >
                                  {`${cat.nomeCategoria} ${i !== categorie.length - 1 ? ' - ' : ''}`}
                                </span>
                              ))
                              : "Nessuna"
                          }
                        </td>
                        <td className="align-middle">
                          {statoLibro.includes('_') ? statoLibro.replace('_', ' ') : statoLibro}
                        </td>
                        <td className="align-middle">
                          <div className="row px-3 d-flex justify-content-center">
                            <div className="col-3 p-2">
                              {/*AZIONI*/}
                              <i
                                className="fas fa-list color-main-color mx-auto"
                                aria-label="Componi"
                                onClick={() => {
                                  setSelectedLibro(libro)
                                  setComponiLibro(!componiLibro)
                                }}
                              />
                            </div>
                            <div className="col-3 p-2">
                              <i className="fas fa-copy color-main-color mx-auto" aria-label="Duplica"
                                onClick={() => clonaLibro(libro.id, nomeLibro)} />
                            </div>
                            {
                              statoLibro === "PUBBLICATO"
                                ? <><div className="col-3 p-2" /><div className="col-3 p-2" /></>
                                : <>
                                  <div className="col-3 p-2">
                                    <i
                                      className="fas fa-pen color-main-color mx-auto"
                                      aria-label="Modifica"
                                      onClick={() => {
                                        if (collapse) setSelectedLibro(libro)
                                        else setSelectedLibroAndEdit(libro, true)
                                      }}
                                    />
                                  </div>
                                  <div className="col-3 p-2">
                                    <i
                                      className="fas fa-times color-rosso mx-auto"
                                      aria-label="Cancella"
                                      onClick={() => {
                                        setSelectedLibro(libro)
                                        setOpenWarningArticoli(!openWarningArticoli)
                                      }}
                                    />
                                  </div>
                                </>
                            }
                          </div>
                        </td>
                      </tr>
                    }))}
                </tbody>
              </table>
              <Pagination
                itemsPerPage={libriCriticita.libriPerPage}
                totalItems={libriCriticita.totalItems}
                currentPage={libriCriticita.currentPage}
                totalPages={libriCriticita.totalPages}
                setItemsPerPage={setLibriPerPageCriticita}
                paginate={paginateCriticita}
              />
            </>
            : <div className="nessuna-pubblicazione color-main-color d-flex justify-content-center align-items-center my-5">
              {msgLibriCriticità}
            </div>
      }
      { // Tabella libri IVASS
        !selectedModel.ivass 
        ? null
        : libriIvass.libri && libriIvass.libri.length > 0 
          ?
          <>
            { //FORM LIBRO
              collapse &&
              <NuovoLibro
                closeForm={() => setSelectedLibroAndEdit(null, true)}
              />
            }
            <table
              id="tabella-libri"
              className="table table-sm table-striped margin-top-30">
              <thead className="bg-main-color text-center color-bianco">
                <tr>
                  {header && header.map((h, i) => <th key={i}>{h.displayValue.toUpperCase()} {sortIconIvass(h.value)}</th>)}
                </tr>
              </thead>
              <tbody className="text-center">
                {
                  React.Children.toArray(libriIvass.libri.map(libro => {
                    const {
                      nomeLibro, statoLibro, libroCopiatoDa,
                      modelloLibroRes: { categorie, copertina: { immagine }, nomeModelloLibro }
                    } = libro;
                    return <tr>
                      {/*COPERTINA*/}
                      <td className="align-middle">
                        <img src={`data:image/png;base64, ${immagine}`} alt="Immagine di copertina" />
                      </td>
                      {/*NOME MODELLO DI LIBRO*/}
                      <td className="align-middle">
                        {nomeModelloLibro}
                      </td>
                      {/*NOME LIBRO*/}
                      <td className="align-middle">
                        {nomeLibro}
                      </td>
                      {/* CLONATO DA */}
                      <td className="align-middle">
                        {libroCopiatoDa}
                      </td>
                      {/*CATEGORIE ASSEGNATE*/}
                      <td className="align-middle mw-400px">
                        {
                          categorie && categorie.length > 0
                            ? categorie.map((cat, i) => (
                              <span
                                key={i}
                                style={categorie.length > 3 ? { fontSize: '14px' } : null}
                              >
                                {`${cat.nomeCategoria} ${i !== categorie.length - 1 ? ' - ' : ''}`}
                              </span>
                            ))
                            : "Nessuna"
                        }
                      </td>
                      <td className="align-middle">
                        {statoLibro.includes('_') ? statoLibro.replace('_', ' ') : statoLibro}
                      </td>
                      <td className="align-middle">
                        <div className="row px-3 d-flex justify-content-center">
                          <div className="col-3 p-2">
                            {/*AZIONI*/}
                            <i
                              className="fas fa-list color-main-color mx-auto"
                              aria-label="Componi"
                              onClick={() => {
                                setSelectedLibro(libro)
                                setComponiLibro(!componiLibro)
                              }}
                            />
                          </div>
                          <div className="col-3 p-2">
                            <i className="fas fa-copy color-main-color mx-auto" aria-label="Duplica"
                              onClick={() => clonaLibro(libro.id, nomeLibro)} />
                          </div>
                          {
                            statoLibro === "PUBBLICATO"
                              ? <><div className="col-3 p-2" /><div className="col-3 p-2" /></>
                              : <>
                                <div className="col-3 p-2">
                                  <i
                                    className="fas fa-pen color-main-color mx-auto"
                                    aria-label="Modifica"
                                    onClick={() => {
                                      if (collapse) setSelectedLibro(libro)
                                      else setSelectedLibroAndEdit(libro, true)
                                    }}
                                  />
                                </div>
                                <div className="col-3 p-2">
                                  <i
                                    className="fas fa-times color-rosso mx-auto"
                                    aria-label="Cancella"
                                    onClick={() => {
                                      setSelectedLibro(libro)
                                      setOpenWarningArticoli(!openWarningArticoli)
                                    }}
                                  />
                                </div>
                              </>
                          }
                        </div>
                      </td>
                    </tr>
                  }))}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={libriIvass.libriPerPage}
              totalItems={libriIvass.totalItems}
              currentPage={libriIvass.currentPage}
              totalPages={libriIvass.totalPages}
              setItemsPerPage={setLibriPerPageIvass}
              paginate={paginateIvass}
            />
          </>
          : <div className="nessuna-pubblicazione color-main-color d-flex justify-content-center align-items-center my-5">
            {msgLibriIvass}
          </div>
      }
      {componiLibro &&
        <PubblicaLibro
          selectedModel={selectedModel}
          open={componiLibro}
          toggle={() => setComponiLibro(!componiLibro)}
          selectedLibro={selectedLibro}
        />
      }
      {selectedLibro && openWarningArticoli && (
        <ListaArticoliAssociati
          open={openWarningArticoli}
          toggle={() => setOpenWarningArticoli(!openWarningArticoli)}
          title="Attenzione"
          text={selectedLibro.articoliLibro.length > 0 ? 'I seguenti articoli verranno eliminati, vuoi procedere?' : "Sei sicuro di voler procedere all'eliminazione del libro?"}
          articoli={selectedLibro.articoliLibro}
          onConfirm={() => deleteLibroById(selectedLibro.id, selectedLibro.nomeLibro)}
          onDeny={() => setOpenWarningArticoli(!openWarningArticoli)}
          confirmButtonText="conferma"
          denyButtonText="annulla"
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  update: state.libro.update,
  selectedLibro: state.libro.selectedLibro
})

const mapDispatchToProps = dispatch => ({
  toggleToast: (s, m) => dispatch(toggleToast(s, m)),
  toggleSpinner: () => dispatch(toggleSpinner()),
  updateTabella: () => dispatch(updateTabella()),
  setSelectedLibro: libro => dispatch(setSelectedLibro(libro))
})

export default connect(mapStateToProps, mapDispatchToProps)(GestioneLibri);
