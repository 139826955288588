import React from 'react';
import {Page, Text, View, Document, StyleSheet, Canvas, Image} from '@react-pdf/renderer';
import logo from '../../assets/img/logo/logo.png';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'red'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    canvas: {
        position: 'absolute',
        left: 15,
        top: 0,
        height: '114%',
        width: 20
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingLeft: 65,
        paddingRight: 35,
        backgroundColor: 'white'
    },
    title: {
        fontSize: 16,
        fontWeight: 'bolder',
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    h4: {
        fontSize: 12,
        textAlign: 'left',
        marginBottom: 10,
        textTransform: 'uppercase',
        color: 'gray'
    },
    h5: {
        fontSize: 10,
        textAlign: 'left',
        marginBottom: 5,
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
    },
    text: {
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
        width: '100%'
    },
    image: {
        marginVertical: 15,
        marginLeft: 150,
        width: 200,
        height: 150
    },
    header: {
        fontSize: 12,
        color: 'grey',
        width: '100%',
        overflow: 'hidden',
        height: 115
    },
    headerImage: {
        width: 130,
    },
    globeImage: {
        height: 80,
        width: 80,
        position: 'absolute',
        right: 0
    },
    layout1: {
        width: '100%',
        marginTop: 35,
        padding: '15 20',
        display: 'block',
        position: 'static'
    },
    layout2: {
        width: '47%',
        marginTop: 35,
        padding: '10 15',
        display: 'inline-block',
        float: 'left',
        position: 'absolute',
    },
    textColor: {
        color: '#414042'
    }
});

const defaultColors = ['#AEAEAE', '#dedede', '#f6f6f6'];

const testo1 = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
const testo2 = 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?';

function Template({palette, layout, box, image1, refreshFile}) {
    const [barColor, color1, color2] = palette ? palette.colori : defaultColors;

    async function onRender({blob, layoutData}) {
        //saveAs(blob, 'test.pdf')
        refreshFile(blob);
    }

    return (
        <Document onRender={onRender}>
            <Page style={styles.body}>
                <Canvas fixed
                        style={[styles.canvas, {backgroundColor: barColor}]}/>
                <View style={styles.header}>
                    <Image
                        style={styles.headerImage}
                        src={logo}
                    />
                </View>
                <Text style={styles.h4}>Lorem ipsum dolor sit amet</Text>
                <Text style={[styles.title, {color: barColor}]}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Text>

                <View>
                    {
                        layout === 'UNA_COLONNA' ?
                            <>
                                <View style={[
                                    styles.text, styles.textColor, styles.layout1, {backgroundColor: color1}]}>
                                    <Text style={styles.h4}>Lorem ipsum dolor sit amet</Text>
                                    <Text style={[styles.text, styles.textColor]}>
                                        {testo1}
                                    </Text>
                                </View>

                                <View style={[
                                    styles.text, styles.textColor, styles.layout1, {backgroundColor: color2}]}>
                                    <Text style={styles.h4}>Sed ut perspiciatis unde omnis</Text>
                                    <Text style={[styles.text, styles.textColor]}>
                                        {testo2}
                                    </Text>
                                </View>
                            </> :
                            <>
                                <View style={[
                                    styles.text, styles.textColor, styles.layout2, {
                                        backgroundColor: color1,
                                    }]}>
                                    <Text style={styles.h4}>Lorem ipsum dolor sit amet</Text>
                                    <Text style={[styles.text, styles.textColor]}>
                                        {testo1}
                                    </Text>
                                </View>

                                <View style={[
                                    styles.text, styles.textColor, styles.layout2, {
                                        backgroundColor: color2,
                                        right: 0
                                    }]}>
                                    <Text style={styles.h4}>Sed ut perspiciatis unde omnis</Text>
                                    <Text style={[styles.text, styles.textColor]}>
                                        {testo2}
                                    </Text>
                                </View>
                            </>
                    }
                </View>

            </Page>
        </Document>
    )
}

export default Template;
