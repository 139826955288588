import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {setSelectedLibro, updateTabella} from "../../store/actionCreators/Libro";
import axiosInstance from "../../api/interceptor";
import {Input} from 'reactstrap';

// Libri
const libriResource = `/libri`;
const libriPUT = id => `${libriResource}/${id}`;

// ModelliLibro
const modelliLibroResource = `/modellilibro`;

function NuovoLibro(props) {

  NuovoLibro.propTypes = {
    toggleSpinner: PropTypes.func.isRequired,
    toggleToast: PropTypes.func.isRequired,
    updateTabella: PropTypes.func.isRequired,
    setSelectedLibro: PropTypes.func.isRequired,
    closeForm: PropTypes.func.isRequired,
    selectedLibro: PropTypes.shape({})
  }

  const {
    selectedLibro,
    // setSelectedLibro,
    updateTabella,
    toggleToast,
    toggleSpinner,
    closeForm
  } = props;

  // FETCH HOOKS
  const [modelliLibro, setModelliLibro] = useState([]);
  // FETCH HOOKS

  //  FORM HOOKS
  const [titoloLibro, setTitoloLibro] = useState({value: '', error: false});
  const [modelloLibro, setModelloLibro] = useState({value: '', error: false});
  //  FORM HOOKS

  const handleChangeModelloLibro = e => {
    setModelloLibro({
      value: e.target.value,
      error: e.target.value.length === 0
    });
  }

  // Check values then submit PUT or POST
  const handleSubmit = () => {
    let errors = [];
    if (titoloLibro.value === '') {
      setTitoloLibro({value: titoloLibro.value, error: true});
      errors.push('Titolo libro è obbligatorio');
    }
    if (!modelloLibro.value || modelloLibro.value === '') {
      setModelloLibro({value: modelloLibro.value, error: true});
      errors.push('Modello libro è obbligatorio');
    }
    if (errors.length >= 1) {
      errors.forEach(e => toggleToast('e', e))
    } else selectedLibro ? putLibro() : createLibro()
  }

  const getModelliLibro = async () => {
    await axiosInstance.get(modelliLibroResource)
      .then(({status, data: {content}}) => {
        if (status && status === 200) setModelliLibro(content)
      })
      .catch(({messaggio}) => toggleToast('e', messaggio))
  }

  // POST
  const createLibro = async () => {
    toggleSpinner();
    await axiosInstance.post(libriResource, {
      nomeLibro: titoloLibro.value,
      idModelloLibro: modelloLibro.value
    })
      .then(({status, data}) => {
        if (status && status === 200) {
          closeForm();
          updateTabella();
          toggleToast('s', `Libro "${data.nomeLibro}" creato con successo`);
        }
      })
      .catch(({message}) => toggleToast('e', message))
      .finally(() => toggleSpinner())
  }

  // PUT
  const putLibro = async () => {
    toggleSpinner();
    await axiosInstance.put(libriPUT(selectedLibro.id), {
      nomeLibro: titoloLibro.value,
      idModelloLibro: modelloLibro.value
    })
      .then(({status, data}) => {
        if (status && status === 200) {
          closeForm();
          updateTabella();
          toggleToast('s', `Libro "${data.nomeLibro}" modificato con successo`);
        }
      })
      .catch(({message}) => toggleToast('e', message))
      .finally(() => toggleSpinner())
  }

  const setInitialState = () => {
    const {modelloLibroRes, nomeLibro} = selectedLibro;
    setTitoloLibro({value: nomeLibro, error: false});
    setModelloLibro({value: modelloLibroRes.id, error: false});
  }

  React.useEffect(() => {
    getModelliLibro();
    if (selectedLibro) setInitialState();
  }, [selectedLibro]) // eslint-disable-line


  return (
    <div id="nuovo-libro-container" className="container">

      <div className="row margin-top-30">
        <div className="col-md-12">
          <div className="row align-items-center">
            <div className="col-md-4">
              <label htmlFor="titolo-libro">Titolo libro</label>
              {/* TITOLO LIBRO */}
              <input id="titolo-libro"
                     type="text"
                     style={titoloLibro.error ? {border: '1px solid red'} : null}
                     className="form-control form-control-sm"
                     onChange={(e) => setTitoloLibro({value: e.target.value, error: e.target.value === ''})}
                     value={titoloLibro.value}
              />
              {titoloLibro.error ? <small style={{
                color: 'red',
                fontSize: '12px',
                position: 'absolute'
              }}>
                Campo obbligatorio</small> : null}
            </div>
            <div className="col-md-4">
              <label htmlFor="tipo-libro">Tipo di libro</label>
              {/* MODELLI LIBRO */}
              <Input type="select"
                     bsSize="sm"
                     style={modelloLibro.error ? {border: '1px solid red'} : null}
                     onChange={(e) => {handleChangeModelloLibro(e)}}
                     value={modelloLibro.value}
                     disabled={selectedLibro && selectedLibro.libroConArticoli}
              >
                <option value={''}>Seleziona</option>
                {
                  React.Children.toArray(modelliLibro.map(m => {
                    const {id, nomeModelloLibro} = m;
                    return (
                      <option value={id}>
                        {nomeModelloLibro}
                      </option>
                    )
                  }))
                }
              </Input>
              {modelloLibro.error ? <small style={{
                color: 'red',
                fontSize: '12px',
                position: 'absolute'
              }}>
                Campo obbligatorio</small> : null}
            </div>
            <div className="col-md-2">
              <div
                className="btn btn-azure text-uppercase padding-left-40 padding-right-40 btn-block margin-top-20 btn-sm"
                onClick={handleSubmit}
              >
                {selectedLibro ? 'modifica libro' : 'crea libro'}
              </div>
            </div>
            <div className="col-md-2">
              <div
                onClick={closeForm}
                className="btn outline-azure text-uppercase padding-left-40 padding-right-40 btn-block margin-top-20 btn-sm"
              >annulla
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedLibro: state.libro.selectedLibro
})

const mapDispatchToProps = dispatch => {
  return {
    toggleToast: (s, m) => dispatch(toggleToast(s, m)),
    toggleSpinner: () => dispatch(toggleSpinner()),
    updateTabella: () => dispatch(updateTabella()),
    setSelectedLibro: libro => dispatch(setSelectedLibro(libro))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuovoLibro);
