import React from "react";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import logo_ania from "../../../assets/img/logo/logo.png";
import axiosInstance from "../../../api/interceptor";
import {toggleSpinner, toggleToast} from "../../../store/actionCreators/general";


const apiURL = email => `auth/password-reset-token?email=${email}`;

const ForgotPasswordMailSenderForm = (props) => {

  ForgotPasswordMailSenderForm.propTypes = {
    setFormType: PropTypes.func.isRequired,
    toggleToast: PropTypes.func.isRequired,
    toggleSpinner: PropTypes.func.isRequired
  }

  const {setFormType, toggleSpinner, toggleToast} = props;

  const [email, setEmail] = React.useState({
    value: '',
    error: false
  });

  const [errMessage, setErrMessage] = React.useState('');

  // Verifico il form e chiamo l'API
  const checkValidity = () => {
    if (email.value === "") {
      setEmail({value: email.value, error: true})
      setErrMessage("Inserisci l'e-mail");

    } else if (!email.value.includes('@')) {
      setEmail({value: email.value, error: true})
      setErrMessage("Inserisci una mail valida")

    } else sendPasswordRecoveryMail()
  }

  const sendPasswordRecoveryMail = () => {
    toggleSpinner();
    axiosInstance.get(apiURL(email.value))
      .then(({status}) => {
        if (status && status === 200) toggleToast('s', `Email inoltrata all'indirizzo "${email.value}"`)
      })
      .catch(e => {
        if (e && e.messaggio) toggleToast('e', e.messaggio)
      })
      .finally(() => toggleSpinner())
  }

  return <div id="forgot-password-form">
    <div className="form-row">
      <span className="go-to color-main-color cursor-pointer" onClick={() => setFormType('login')}>
        <i className="fas fa-long-arrow-alt-left"/> Torna al login...
      </span>
    </div>
    <img src={logo_ania} alt="logo_ania"/>
    <div className="form-row">
      <label htmlFor="email">Email</label>
      <div className="input-group">
        <input
          type="email"
          className="form-control"
          onChange={e => setEmail({value: e.target.value, error: email.error})}
          value={email.value}
        />
        <div className="input-group-append">
          <span
            onClick={checkValidity}
            className="input-group-text color-azure cursor-pointer">
            <i className="far fa-paper-plane"/>
          </span>
        </div>
      </div>
      {
        email.error ?
          <small style={{color: 'red', fontSize: '12px'}}>{errMessage}</small>
          : null
      }
    </div>
    <div className="form-row pt-5">
      <p className="mx-auto">Inserisci qui la tua mail, ti invieremo le istruzioni per il recupero della password.</p>
    </div>
    {/*<div className="form-row">*/}
    {/*  <button className="btn btn-azure mx-auto">INVIA</button>*/}
    {/*</div>*/}
  </div>
}

function mapDispatchToProps(dispatch) {
  return {
    toggleToast: (s, m) => dispatch(toggleToast(s, m)),
    toggleSpinner: () => dispatch(toggleSpinner())
  }
}

export default connect(null, mapDispatchToProps)(ForgotPasswordMailSenderForm)
