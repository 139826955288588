import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logoAnia.svg';
import { NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actionCreators/auth";
import { setFilterArticoliParams } from '../../store/actionCreators/filterArticoliParams';
import { getRouteItems } from "../../routing/utils";
import routes from '../../routing/routes';

function Header(props) {
  const history = useHistory();

  const { user, logout, setFilterArticoliParams } = props;
  const [ricerca, setRicerca] = useState(false);
  const [ricercaParolaChiave, setRicercaParolaChiave] = useState('');
  const [azioniUtente, setAzioniUtente] = useState(false);

  const [searchIconVisibility, setSearchIconVisibility] = useState(true)

  // Set icona search a non visibile se siamo nella pagina con i filtri di ricerca avanzati
  // Gestione del redirect se inseriamo un path inesistente
  useEffect(() => {
    history.location.pathname === '/ultime-pubblicazioni' ? setSearchIconVisibility(false) : setSearchIconVisibility(true)
    if(!routes.map(el => el.path).some(el => el.includes(history.location.pathname))) history.push('/')

    history.listen((location, action) => {
      const { pathname } = location;
      pathname === '/ultime-pubblicazioni' ? setSearchIconVisibility(false) : setSearchIconVisibility(true);
    })
  }, [history])

  const gestisciAzioni = azione => {
    if (azione === 'r') {
      setRicerca(ricerca => !ricerca);
      setAzioniUtente(false);
    } else {
      setRicerca(false);
      setAzioniUtente(azioni => !azioni);
    }
  };

  // Quando si effettua la ricerca si fanno 3 cose: a) chiude l'input di ricerca, b) passa la parola chiave come filtro, c) va a ultime pubblicazioni
  const effettuaRicerca = () => {
    setRicerca(false)
    setFilterArticoliParams(`&parolaChiave=${ricercaParolaChiave}`)
    setRicercaParolaChiave('')
  }

  const iniziali = user ? user.nome.charAt(0) + user.cognome.charAt(0) : '';

  return (
    <div id="header-container" className="container">
      <NavLink to="/" className="color-main-color text-decoration-none d-flex align-items-center">
        <span style={{fontSize: '68px', fontFamily: 'times', letterSpacing: '2px'}}>EDIT</span>
        <img src={logo} alt="Logo Ania" />
        </NavLink>
      <div id="actions">
        { !searchIconVisibility ? null :
          <span>
            <i className="fas fa-search" onClick={() => gestisciAzioni('r')} />
          </span>
        }
        <span id="utente" onClick={() => gestisciAzioni('u')}>
          <span>{iniziali}</span>
        </span>
        
        {ricerca && <div id="ricerca">
          <div className="triangle-up" />
          <input type="text" value={ricercaParolaChiave} placeholder="Cerca parola chiave..." onChange={e => setRicercaParolaChiave(e.target.value)} />
          <div className="action-container" onClick={() => effettuaRicerca()}>
            <NavLink className="color-bianco" to='/ultime-pubblicazioni'>
              <i className="fas fa-chevron-right color-bianco" />
            </NavLink>
          </div>
        </div>}
        
        {azioniUtente && <div id="azioni-utente">
          <div className="triangle-up" />
          {
            React.Children.toArray(getRouteItems(user).filter(i => i.name !== "").map(item => {
              const { path, name } = item;
              return (
                <NavLink
                  to={path}
                  className="azione"
                  onClick={() => setAzioniUtente(false)}
                >
                  {name}
                </NavLink>
              )
            }))
          }
          <div className="azione" onClick={logout}>Esci</div>
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, { logout, setFilterArticoliParams })(Header);
