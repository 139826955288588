import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../../store/actionCreators/general";
import axiosInstance from "../../../api/interceptor";
import { CustomInput } from 'reactstrap';
import TooltipWarning from "../components/Tooltip/Tooltip";

//API utilizzate
const apis = {
  categorie: '/categorie/elenco',
  modelliLibro: '/modellilibro',
  putModelliLibro: id => `/modellilibro/${id}`
}

const FormModello = props => {

  FormModello.propTypes = {
    selectedModello: PropTypes.shape({}),
    removeSelectedModello: PropTypes.func.isRequired,
    toggleForm: PropTypes.func.isRequired,
    updateTabella: PropTypes.func.isRequired,
    copertine: PropTypes.array
  }

  const {
    selectedModello,
    removeSelectedModello,
    templateModelli,
    toggleForm,
    updateTabella,
    copertine, //Copertine gia presenti in GestioneModelliLibro
    toggleSpinner,
    toggleToast
  } = props;

  const handleSetCategorieData = categorie => {
    if (!selectedModello) {
      setCategorieData(categorie.filter(c => !c.categoriaConModello))
    } else {
      setCategorieData([
        ...categorie.filter(c => !c.categoriaConModello),
        ...selectedModello.categorie
      ])
    }
  }

  const getCategorieData = async () => {
    toggleSpinner();
    await axiosInstance.get(apis.categorie)
      .then((r) => {
        if (r && r.status === 200) handleSetCategorieData(r.data)
      })
      .catch(({ messaggio }) => toggleToast('e', messaggio))
      .finally(() => toggleSpinner())
  }

  const setInitialState = () => {
    const { nomeModelloLibro, categorie, copertina: { id }, modelloPrincipale, template } = selectedModello;
    setNomeModello({ value: nomeModelloLibro, error: false });
    setCategorie(categorie.map(({ id }) => id))
    setCopertina(id);
    setModelloPrincipale(modelloPrincipale);
    handleRadioButtons(template)
  }

  React.useEffect(() => {
    getCategorieData();
    if (selectedModello) setInitialState()
  }, []) // eslint-disable-line

  //  Categorie selezionabili prese dal db
  const [categorieData, setCategorieData] = React.useState([]);

  //  Dati inseriti dall'utente
  const [nomeModello, setNomeModello] = React.useState({
    value: '',
    error: false
  });
  const [categorie, setCategorie] = React.useState([]);
  const [copertina, setCopertina] = React.useState(null);
  const [modelloPrincipale, setModelloPrincipale] = React.useState(false);
  //  Dati inseriti dall'utente

  // Gestisce le checkbox
  const handleCategorieSelected = e => {
    const { value, checked } = e.target;
    if (checked) {
      setCategorie([...categorie, value])
    } else {
      const newCategorie = categorie.filter(cat => cat !== value);
      setCategorie(newCategorie)
    }
  };

  // Radio buttons per scelta template

  const [template, setTemplate] = React.useState(templateModelli)

  function handleRadioButtons(id) {
    return (id
      ? setTemplate(template.map((el) => el.id === id ? { ...el, checked: true } : { ...el, checked: false }))
      : setTemplate((templateModelli))
    )
  }

  const submitPut = () => {
    toggleSpinner();
    axiosInstance.put(apis.putModelliLibro(selectedModello.id), {
      nomeModelloLibro: nomeModello.value,
      idCategorie: categorie,
      idCopertina: copertina,
      modelloPrincipale,
      template: template.filter(el => el.checked)[0].id,
      titoloQuartaCopertina: ''
    }).then(({ status, data: { nomeModelloLibro } }) => {
      if (status && status === 200) {
        toggleForm();
        updateTabella();
        toggleToast('s', `Modello di libro "${nomeModelloLibro}" modificato con successo.`)
      }
    })
      .catch(e => toggleToast('e', e))
      .finally(() => toggleSpinner())
  }

  const submitPost = async () => {
    toggleSpinner();
    await axiosInstance.post(apis.modelliLibro, {
      nomeModelloLibro: nomeModello.value,
      idCategorie: categorie,
      idCopertina: copertina,
      modelloPrincipale,
      template: template.filter(el => el.checked)[0].id,
      titoloQuartaCopertina: ''
    }).then((r) => {
      if (r && r.status === 200) {
        toggleForm();
        updateTabella();
        toggleToast('s', `Modello di libro "${r.data.nomeModelloLibro}" creato con successo.`)
      }
    })
      .catch(e => toggleToast('e', e))
      .finally(() => toggleSpinner())
  }

  const handleSubmit = () => {
    selectedModello ? submitPut() : submitPost();
  }

  //Form validations
  const checkValidity = () => {
    let errors = [];
    if (nomeModello.value === '') {
      errors.push('Nome modello è obbligatorio');
    }
    // Aggiungere se obbligatorio
    // if (categorie.length === 0) {
    //   errors.push(`E' obbligatorio attribuire una categoria al modello`);
    // }
    if (!copertina) {
      errors.push(`Per favore, scegli una copertina`);
    }
    if (errors.length >= 1) {
      errors.forEach(e => toggleToast('e', e))
    } else handleSubmit()
  }

  const removeModelloAndCloseForm = () => {
    toggleForm();
    removeSelectedModello();
  }

  const handleClickCopertina = (id, copertinaAssociata) => {
    if (copertinaAssociata) return;
    copertina === id ?
      setCopertina(null) :
      setCopertina(id)
  }

  //  TOOLTIP
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const handleTooltip = () => setTooltipOpen(!tooltipOpen);
  const tooltipIconID = 'idUsedByTooltipQuestion';
  //  TOOLTIP

  const handlePagetitle = () => selectedModello ? "modifica modello" : "crea modello";

  return (
    <>
      <div className="title-gestione-modelli">
        <div className="title mb-5">
          <i className="fas fa-th" />
          {handlePagetitle()}
        </div>
        {
          !selectedModello ? null :
            <div>
              <h6 className="color-main-color">
                Modello selezionato:
                <span className="color-main-text ml-1">{selectedModello.nomeModelloLibro}</span>
              </h6>
            </div>
        }
      </div>

      <div id="form-modello" className="container mt-5">
        {/*COPERTINE*/}
        <div className="form-box">
          <label htmlFor="Copertina">Copertina</label>
          <div>
            {React.Children.toArray(copertine.map(({ id, immagine, copertinaAssociata }) => {
              return (
                <img
                  className={copertina === id ? 'immagine-copertina highlight-selected' : 'immagine-copertina'}
                  style={copertinaAssociata ? { opacity: '0.3', cursor: 'not-allowed' } : null}
                  src={`data:image/png;base64, ${immagine}`}
                  alt={`Immagine di copertina ${id}`}
                  onClick={() => handleClickCopertina(id, copertinaAssociata)}
                />
              )
            }))}
          </div>
        </div>

        <div className="d-flex flex-wrap" style={{ height: 'fit-content' }}>
          {/*CATEGORIE*/}
          <div className="form-box w-50">
            <label htmlFor="CategorieAssegnate">Categorie</label>
            <div>
              {
                categorieData && categorieData.length > 0 ?
                  React.Children.toArray(categorieData.map(cat => {
                    return (
                      <div>
                        <input
                          type="checkbox"
                          className="mr-1"
                          checked={categorie.includes(cat.id)}
                          onChange={e => handleCategorieSelected(e)}
                          value={cat.id}
                          disabled={cat.categoriaConArticoli}
                        /> {cat.titoloCategoria ? cat.titoloCategoria : cat.nomeCategoria}
                      </div>
                    )

                  })) : <span className="color-main-text">Nessuna categoria assegnabile</span>}
            </div>
          </div>

          {/*MODELLO PRINCIPALE*/}
          <div className="form-box w-50">
            <label htmlFor="ModelloPrincipale">In primo piano</label>
            <div>
              <CustomInput
                type="switch"
                id="exampleCustomSwitch"
                className="d-inline mr-2"
                name="customSwitch"
                checked={modelloPrincipale}
                onChange={e => setModelloPrincipale(e.target.checked)}
                value={modelloPrincipale}
              />
              <i id={tooltipIconID}
                className="far fa-question-circle fa-lg color-azure"
              />
              <TooltipWarning
                targetId={tooltipIconID}
                open={tooltipOpen}
                toggle={handleTooltip}
                text={`Gli ultimi due libri di questo modello verranno visualizzati nella sezione 'Primo Piano" della home.`}
                placement="bottom"
              />
            </div>
          </div>

          {/*Template*/}
          <div className="form-box w-50 mt-4">
            <label>Seleziona il template</label>

            {
              template.map((el, idx) => (
                <div key={idx}>
                  <input className="mr-1" type="radio" name={el.name} value={el.id}
                    checked={el.checked} onChange={() => handleRadioButtons(el.id)} 
                    disabled={selectedModello && selectedModello.categorie.some(el => el.categoriaConArticoli)} />
                  {el.label}
                </div>
              ))
            }

          </div>

        </div>

        {/*NOME*/}
        <div className="form-box mx-auto">
          <label htmlFor="NomeModello">Nome modello</label>
          <input
            type="text"
            className="form-control form-control-sm"
            style={nomeModello.error ? { border: '1px solid red' } : null}
            onChange={e => setNomeModello({
              value: e.target.value,
              error: e.target.value === ''
            })}
            value={nomeModello.value}
          />
          {nomeModello.error
            ? <small
              style={{
                color: 'red',
                fontSize: '12px',
                position: 'absolute'
              }}>
              Campo obbligatorio
            </small> : null}
          <button className="btn btn-azure btn-block btn-sm mt-5"
            onClick={checkValidity}
          >
            {selectedModello ? 'MODIFICA MODELLO' : 'CREA MODELLO'}
          </button>
          <button className="btn outline-azure btn-sm btn-block"
            onClick={removeModelloAndCloseForm}
          >
            ANNULLA
          </button>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    templateModelli: state.staticData.templateModelli
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleToast: (s, m) => dispatch(toggleToast(s, m)),
    toggleSpinner: () => dispatch(toggleSpinner())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormModello);
