import React from 'react';
import Pagination from "../../components/Pagination/Pagination";
import axiosInstance from "../../api/interceptor";
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import { connect } from "react-redux";
import FormUtente from "./form/FormUtente";
import Swal from "sweetalert2";
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';

const bootstrapSpinner = (<div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>)

export const putStatoURL = (id, stato) => `/utenti/${id}/stato/${stato}`;

export const statiUtente = {
  attesaApprovazione: "IN_APPROVAZIONE",
  attivo: "ATTIVO",
  nonAttivo: "NON_ATTIVO"
}

const header = [// i campi value
  { value: "nome", displayValue: "NOME" },
  { value: "cognome", displayValue: "COGNOME" },
  { value: "email", displayValue: "E-MAIL" },
  { value: "ruoloUtente", displayValue: "RUOLO" },
  { value: "dataRegistrazione", displayValue: "DATA REGISTRAZIONE" },
  { value: "azioni", displayValue: "AZIONI" }
]

const actions = {
  setState: 'setState',
  setUsers: 'setUsers',
  setCurrentPage: 'setCurrentPage',
  setTotalItems: 'setTotalItems',
  setTotalPages: 'setTotalPages',
  setItemsPerPage: 'setItemsPerPage',
  setSortField: 'setSortField',
}

const initialState = {
  users: [],
  usersPerPage: 10,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  sortField: 'dataRegistrazione',
  sortDirection: 'desc'
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.setState: {
      const { users, totalItems, totalPages } = action.value;
      return {
        ...state,
        users,
        totalItems,
        totalPages
      }
    }
    case actions.setItemsPerPage: {
      return {
        ...state,
        usersPerPage: action.value,
        currentPage: 0
      }
    }
    case actions.setCurrentPage: {
      return {
        ...state, currentPage: action.value
      }
    }
    case actions.setSortField: {
      return {
        ...state,
        sortField: action.field,
        sortDirection: action.direction
      }
    }
    default: return state;
  }
}

const GestioneUtenti = (props) => {
  const [collapsed, setCollapse] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  // FETCH DATI UTENTE DA SESSION STORAGE
  const currentUser = JSON.parse(atob(sessionStorage.getItem('token')));

  // Gestione visiva del loading di utenti
  const [msgGetUtenti, setMsgGetUtenti] = React.useState(bootstrapSpinner);

  const { toggleToast, toggleSpinner } = props;

  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState)

  const paginate = pageNumber => dispatch({ type: actions.setCurrentPage, value: pageNumber });
  const setUsersPerPage = upp => dispatch({ type: actions.setItemsPerPage, value: upp });

  const getSortParams = () => {
    let params = `/utenti`;
    const { usersPerPage, currentPage, sortField, sortDirection } = state;
    params += `?size=${usersPerPage}&page=${currentPage}&sort=${sortField},${sortDirection}`;
    return params;
  }

  const setState = values => {
    const { content, totalElements, totalPages } = values;
    dispatch({
      type: actions.setState,
      value: {
        users: content,
        totalItems: totalElements,
        totalPages: totalPages,
      }
    })
  }

  /**
   * Function che modifica lo statoUtente
   * @param utente
   */
  const changeStatoAccountUtente = (utente, title, active) => {
    Swal.fire({
      title: title,
      text: `${utente.email}`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText:
        `<i class="fas fa-check" style="font-family: 'Font Awesome 5 Free'" aria-label="Conferma"/>
            <span style="font-family: 'OpenSans-Regular'">Conferma</span>`,
      confirmButtonAriaLabel: 'Conferma',
      cancelButtonText:
        `<i class="fa fa-times" style="font-family: 'Font Awesome 5 Free'" aria-label="Annulla"/>
            <span style="font-family: OpenSans-Regular">Annulla</span>`,
      cancelButtonAriaLabel: 'Annulla',
      preConfirm: () => {
        toggleSpinner();
        axiosInstance.put(putStatoURL(utente.id, active))
          .then(() => setUpdate(!update))
          .catch(({ messaggio }) => {
            toggleToast('e', messaggio);
          })
          .finally(() => toggleSpinner())
      }
    })
  }

  React.useEffect(() => {
    toggleSpinner();
    axiosInstance.get(getSortParams())
      .then(({ data }) => setState(data))
      .catch(({ messaggio }) => {
        toggleToast('e', messaggio);
      })
      .finally(() => {
        setMsgGetUtenti('Nessun utente trovato');
        toggleSpinner()
      })
  }, [state.usersPerPage, state.currentPage, state.sortField, state.sortDirection, update]); // eslint-disable-line

  /**
   * Pagination
   * @param field
   * @returns {*}
   */
  const sortIcon = field => {
    if (field === "azioni") return;
    return <i
      className={`fas ${
        field !== state.sortField ? 'fa-sort' :
          state.sortField && state.sortDirection === 'asc' ?
            'fa-caret-up' : 'fa-caret-down'
        } cursor-pointer`}
      onClick={() => {
        dispatch({
          type: actions.setSortField,
          field: field,
          direction: field !== state.sortField ? 'asc' :
            state.sortDirection === 'asc' ? 'desc' : 'asc'
        })
      }}
    />
  }

  const handlePagetitle = () => {
    return collapsed && selectedUser ? "modifica utente" :
      collapsed && !selectedUser ? "crea utente" : "gestione utenti"
  }

  return (
    <div id="gestione-utenti" className="container">
      <BackToPrevious goBack={() => props.history.goBack()} />
      <div>
        <div className="title-utenti">
          <div className="title">
            <i className="fas fa-th" />
            {handlePagetitle()}
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => {
          if (selectedUser) setSelectedUser(null);
          setCollapse(!collapsed);
        }}>
          <i className="fas fa-user-plus color-rosso" />{' '}
          <span><u><i>Crea nuovo utente</i></u></span>
        </div>
      </div>
      {
        state.users && state.users.length > 0 ?
          <>
            {// DROPDOWN MENU
              !collapsed ? null :
                <FormUtente
                  selectedUser={selectedUser}
                  removeSelectedUser={() => setSelectedUser(null)}
                  toggleForm={() => setCollapse(!collapsed)}
                  updateTabella={() => setUpdate(!update)}
                />
            }

            <table className="table table-striped">
              <thead className="bg-main-color color-bianco">
                <tr>
                  {
                    header && header.map((h, i) => {
                      return <th key={i}>{h.displayValue.toUpperCase()} {sortIcon(h.value)}</th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  state.users && state.users.map((u, i) => {
                    return <tr key={i}>
                      <td className="color-main-text">{u.nome}</td>
                      <td className="color-main-text">{u.cognome}</td>
                      <td className="color-main-text">{u.email}</td>
                      <td className={u.ruoloUtente.toLowerCase() === 'admin' ? 'color-main-color' : 'color-azure'}>
                        <b>{u.ruoloUtente}</b></td>
                      <td className="color-main-text">{u.dataRegistrazione}</td>
                      <td className="align-middle">
                        <div className="row d-flex justify-content-center">
                          {
                            u.statoUtente === statiUtente.attesaApprovazione ?
                              <i className="far fa-hourglass color-main-color"
                                aria-label="In attesa di approvazione"
                              /> :
                              <>
                                <div className="col-3 padding-8">
                                  <i className="fas fa-pen color-main-color cursor-pointer"
                                    aria-label="Modifica"
                                    onClick={() => {
                                      if (collapsed) {
                                        setSelectedUser(u)
                                      } else {
                                        setSelectedUser(u)
                                        setCollapse(!collapsed)
                                      }
                                    }}
                                  /></div>
                                { // Per disattivare l'utenza è necessario che l'utente sia attivo
                                  // Intolre, c' un filtro per impedire ad un utente admin di disattivare se stesso che non sia attualmente quello collegato
                                  u.statoUtente === statiUtente.attivo && u.id !== currentUser.id
                                    ? <div className="col-3 padding-8">
                                      <i className="fas fa-times color-rosso cursor-pointer"
                                        aria-label="Disattiva utenza"
                                        onClick={() => changeStatoAccountUtente(
                                          u, "Disattivazione utenza", false
                                        )} />
                                    </div>
                                    :
                                    u.statoUtente === statiUtente.nonAttivo
                                      ? <div className="col-3 padding-8">
                                        <i className="fas fa-check color-verde cursor-pointer"
                                          aria-label="Attiva utenza"
                                          onClick={() => changeStatoAccountUtente(
                                            u, "Attivazione utenza", true
                                          )} />
                                      </div>
                                      : <div className="col-3 padding-8" />
                                }
                              </>
                          }
                        </div>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
            <Pagination
              itemsPerPage={state.usersPerPage}
              totalItems={state.totalItems}
              currentPage={state.currentPage}
              totalPages={state.totalPages}
              setItemsPerPage={setUsersPerPage}
              paginate={paginate}
            />
          </> : <div className="d-flex justify-content-center align-items-center my-5">
            {msgGetUtenti}
          </div>
      }
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  {
    toggleToast: (s, m) => dispatch(toggleToast(s, m)),
    toggleSpinner: () => dispatch(toggleSpinner())
  }
);

export default connect(null, mapDispatchToProps)(GestioneUtenti);
