import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Route} from 'react-router-dom';
import App from './components/App/App';
import auth from "./store/reducers/auth";
import libro from "./store/reducers/Libro";
import general from "./store/reducers/general";
import categorie from "./store/reducers/categorie";
import filterArticoliParams from "./store/reducers/filterArticoliParams";
import { staticData } from './store/reducers/staticData';

import {createStore, applyMiddleware, combineReducers} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/style/main.css';

import * as serviceWorker from './serviceWorker';

const rootReducer = combineReducers({
  auth,
  general,
  libro,
  categorie,
  filterArticoliParams,
  staticData
});
const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Route>
        <App/>
      </Route>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
