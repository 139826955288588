import React from 'react';
import download from '../../assets/img/down-arrow.png';
import view from '../../assets/img/view.png';
import files from '../../assets/img/files.png';
import PubblicaLibro from "../PubblicaLibro/PubblicaLibro";
import axiosInstance from "../../api/interceptor";
import { saveAs } from 'file-saver';
import { connect } from "react-redux";
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";

import { printAndDownloadLibro } from "../PdfGeneration/PDFPrint"

function Libro(props) {

  const {
    id,
    // idModelloLibro,
    nomeLibro,
    articoliLibro,
    dataPubblicazione,
    // statoLibro,
    modelloLibroRes: { copertina: { immagine }, nomeModelloLibro },
    toggleSpinner,
    toggleToast
  } = props;

  const [showModal, toggleModal] = React.useState(false);

  async function printLibro() {
    toggleSpinner();
    await axiosInstance.get(`/print/libro/${id}`, { responseType: 'blob' })
      .then(res => {
        let title = nomeLibro.toLowerCase().replace(/ /g, '_'); // creiamo il titolo per il file docx
        saveAs(res.data, `${title}.docx`) // salviamo il file
      })
      .catch(messaggio => {
        toggleToast('e', messaggio);
      })
      .finally(() => {
        toggleSpinner()
      })
  }

  async function printLibroPDF() {
    toggleSpinner()
    await printAndDownloadLibro(props)
      .then(() => {toggleSpinner()})
  }

  function convertDate(date) {
    return date.split('-').reverse().join('/');
  }

  return (
    <div className="libro"
      style={{ backgroundImage: `url("data:image/png;base64, ${immagine}")`, backgroundSize: "100% 100%" }}>
      <div className="title-container">
        <div className="small-title-container">
          <span className="small-title" />
        </div>
        <div className="main-title-container">
          <span className="modello-libro">{nomeModelloLibro}</span>
          <p className="titolo-libro mt-2">{nomeLibro}</p>
        </div>
      </div>
      <div className="actions-libro">
        <div className="details">
          <span><img src={files} alt="files" /> {articoliLibro.length}</span>
          <span>{convertDate(dataPubblicazione)}</span>
        </div>
        <div className="actions">
          <div className="action-container">
            {props.modelloLibroRes.template === "IVASS" ? (
              <img
                src={download}
                alt="download"
                id="Download"
                onClick={printLibroPDF}
              />
            ) : (
                <img
                  src={download}
                  alt="download"
                  id="Download"
                  onClick={printLibro}
                />
              )}

          </div>
          {/* <div className="action-container">
            <img
              src={print}
              alt="print"
              id="Print"
            />
          </div> */}
          <div className="action-container">
            <img
              src={view}
              alt="view"
              onClick={() => toggleModal(!showModal)} id="View"
            />
          </div>
        </div>
      </div>
      {
        showModal && (
          <PubblicaLibro
            open={showModal}
            toggle={() => toggleModal(!showModal)}
            selectedLibro={props}
          />
        )
      }
    </div>
  );
}

export default connect(null, { toggleSpinner, toggleToast })(Libro);
