import React from 'react';
import axiosInstance from "../../api/interceptor";
import { connect } from 'react-redux';
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import FormCategoria from "./form/FormCategoria";
import Pagination from "../../components/Pagination/Pagination";
import { scrollTop } from '../../routing/utils';
import Swal from "sweetalert2";
import layout1 from '../../assets/img/pdfLayout1.png';
import layout2 from '../../assets/img/pdfLayout2.png';
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';

const bootstrapSpinner = (<div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>)

const header = [
  { value: 'palette', displayValue: 'Palette' },
  { value: 'categoriaPadre.titoloCategoria', displayValue: 'Categoria madre' },
  { value: 'titoloCategoria', displayValue: 'Nome' },
  { value: 'icona', displayValue: 'Icona' },
  { value: 'layout', displayValue: 'Layout' },
  { value: 'azioni', displayValue: 'Azioni' }
]

const actions = {
  setState: 'setState',
  setCategorie: 'setCategorie',
  setCurrentPage: 'setCurrentPage',
  setTotalItems: 'setTotalItems',
  setTotalPages: 'setTotalPages',
  setItemsPerPage: 'setItemsPerPage',
  setSortField: 'setSortField',
}

const initialState = {
  categorie: [],
  categoriePerPage: 10,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  sortField: 'titoloCategoria',
  sortDirection: 'desc'
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.setState: {
      const { categorie, totalItems, totalPages } = action.value;
      return {
        ...state,
        categorie,
        totalItems,
        totalPages
      }
    }
    case actions.setItemsPerPage: {
      return {
        ...state,
        categoriePerPage:
          action.value,
        currentPage: 0
      }
    }
    case actions.setCurrentPage: {
      return {
        ...state, currentPage: action.value
      }
    }
    case actions.setSortField: {
      return {
        ...state,
        sortField: action.field,
        sortDirection: action.direction
      }
    }
    default:
      return state;
  }
}

function GestioneCategoria(props) {

  const { toggleToast, toggleSpinner } = props;

  const [collapsed, setCollapse] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [selectedCategoria, setSelectedCategoria] = React.useState(null);

  // Gestione visiva del loading delle tabella di categorie
  const [msgCategorie, setMsgCategorie] = React.useState(bootstrapSpinner);

  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState)

  const paginate = pageNumber => dispatch({ type: actions.setCurrentPage, value: pageNumber });
  const setCategoriePerPage = upp => dispatch({ type: actions.setItemsPerPage, value: upp });

  const getSortParams = () => {
    let params = `/categorie`;
    const {
      categoriePerPage, currentPage, sortField, sortDirection
    } = state;
    params += `?size=${categoriePerPage}&page=${currentPage}&sort=${sortField},${sortDirection}`;
    return params;
  }

  const setState = values => {
    const { content, totalElements, totalPages } = values;
    dispatch({
      type: actions.setState,
      value: {
        categorie: content,
        totalItems: totalElements,
        totalPages: totalPages,
      }
    })
  }

  React.useEffect(() => {
    toggleSpinner();
    axiosInstance.get(getSortParams())
      .then(({ data }) => setState(data))
      .catch(({ messaggio }) => {
        toggleToast('e', messaggio);
      })
      .finally(() => {
        setMsgCategorie('Nessuna categoria');
        toggleSpinner()
      })
  }, [state.categoriePerPage, state.currentPage, state.sortField, state.sortDirection, update]); // eslint-disable-line

  React.useEffect(() => {
    scrollTop()
  }, [collapsed])

  const sortIcon = field => {
    return (field === "categoriaPadre.titoloCategoria") ||
      (field === "titoloCategoria") ?
      <i className={`fas ${
        field !== state.sortField ? 'fa-sort' :
          state.sortField && state.sortDirection === 'asc' ?
            'fa-caret-up' : 'fa-caret-down'
        } cursor-pointer`}
        onClick={() => {
          dispatch({
            type: actions.setSortField,
            field: field,
            direction: field !== state.sortField ? 'asc' :
              state.sortDirection === 'asc' ? 'desc' : 'asc'
          })
        }}
      /> : null
  }

  const setSelectedCategoriaAndCollapse = (categoria, collapseMenu) => {
    setSelectedCategoria(categoria);
    if (collapseMenu) setCollapse(!collapsed);
  }

  const deleteCategoriaById = async (id, titolo) => {
    toggleSpinner();
    await axiosInstance.delete(`/categorie/${id}`)
      .then(r => {
        if (r && r.status === 200) {
          setUpdate(!update)
          toggleToast('s', `Categoria "${titolo}" eliminata`)
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => toggleSpinner())
  }

  const confirmDelete = (id, titolo) => {
    Swal.fire({
      title: "Attenzione! Stai cancellando la Categoria",
      text: `${titolo}`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText:
        `<i class="fas fa-check" style="font-family: 'Font Awesome 5 Free'" aria-label="Conferma"/>
            <span style="font-family: 'OpenSans-Regular'">Conferma</span>`,
      confirmButtonAriaLabel: 'Conferma',
      cancelButtonText:
        `<i class="fa fa-times" style="font-family: 'Font Awesome 5 Free'" aria-label="Annulla"/>
            <span style="font-family: OpenSans-Regular">Annulla</span>`,
      cancelButtonAriaLabel: 'Annulla',
      preConfirm: () => deleteCategoriaById(id, titolo)
    })
  }

  return (
    <div id="gestione-categoria" className="container">
      <BackToPrevious goBack={() => props.history.goBack()} />
      {
        collapsed
          ? <FormCategoria
            categorie={state.categorie}
            selectedCategoria={selectedCategoria}
            removeSelectedCategoria={() => setSelectedCategoria(null)}
            toggleForm={() => setCollapse(!collapsed)}
            toggleSpinner={() => toggleSpinner()}
            toggleToast={toggleToast}
            updateTabella={() => setUpdate(!update)}
          />

          : <>
            <div className="title-gestione-categoria">
              <div className="title">
                <i className="fas fa-th" />
                gestione categoria
                <div className="btn btn-sm btn-azure float-right padding-right-40 padding-right-40" onClick={() => {
                  if (selectedCategoria) setSelectedCategoria(null);
                  setCollapse(!collapsed);
                }}>
                  <i className="fas fa-plus" /> Crea nuova categoria</div>
              </div>
            </div>
            {
              state.categorie && state.categorie.length > 0 ?
                <>
                  <table
                    id="tabella-categorie"
                    className="table table-sm table-striped margin-top-30">
                    <thead className="bg-main-color color-bianco">
                      <tr>
                        {header && header.map((h, i) => {
                          return <th key={i}>{h.displayValue.toUpperCase()} {sortIcon(h.value)}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        state.categorie && state.categorie.map((c, i) => {
                          const { nomeCategoriaMadre, nomeCategoria, icona, layoutCategoria, paletteRes } = c;
                          return <tr key={i}>
                            {/* PALETTE */}
                            <td className="color-main-text align-middle" style={!paletteRes ? { height: '59px' } : null}>
                              {paletteRes ?
                                <div className="paletteTable">
                                  <div className="coloriTable">
                                    {
                                      React.Children.toArray(paletteRes.colori.map(color => {
                                        return <div className="coloreTable" style={{ backgroundColor: color }} />
                                      }))}
                                  </div>
                                  <div className="labelTable">
                                    {`# ${paletteRes.id}`}
                                  </div>
                                </div> : 'Nessuna'
                              }
                            </td>
                            {/* NOME CATEGORIA MADRE */}
                            <td className="color-main-text align-middle mw-250px">
                              {nomeCategoriaMadre ? nomeCategoriaMadre : "Nessuna"}
                            </td>
                            {/* NOME */}
                            <td className="color-main-text align-middle mw-250px">{nomeCategoria}</td>
                            {/* ICONA */}
                            <td className="color-main-text align-middle">
                              {
                                icona ? <i className={`fas fa-${icona}`} aria-label="Icona categoria" /> : 'Nessuna'
                              }
                            </td>
                            {/* LAYOUT */}
                            <td className="color-main-text align-middle">
                              <img src={layoutCategoria === "DUE_COLONNE" ? layout2 : layout1}
                                alt={layoutCategoria === "DUE_COLONNE" ? "Layout a due colonne" : "Layout a una colonna"} />
                            </td>
                            {/* AZIONI */}
                            <td className="align-middle">
                              <div className="row d-flex justify-content-center">
                                <div className="col-3 padding-8">
                                  <i className="fas fa-pen color-main-color cursor-pointer"
                                    aria-label="Modifica"
                                    onClick={() => {
                                      if (collapsed) {
                                        setSelectedCategoria(c)
                                      } else setSelectedCategoriaAndCollapse(c, true)
                                    }}
                                  />
                                </div>
                                <div className="col-3 padding-8">
                                  <i className="fas fa-times color-rosso cursor-pointer"
                                    aria-label="Cancella"
                                    onClick={() => confirmDelete(c.id, c.nomeCategoria)}
                                  />
                                </div>
                              </div>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                    <Pagination
                      itemsPerPage={state.categoriePerPage}
                      totalItems={state.totalItems}
                      currentPage={state.currentPage}
                      totalPages={state.totalPages}
                      setItemsPerPage={setCategoriePerPage}
                      paginate={paginate}
                    />
                </> : <div className="d-flex justify-content-center align-items-center my-5">
                    {msgCategorie}
                  </div>
            }
                </>
      }
          </div>
  );
}

function mdtp(dispatch) {
  return {
        toggleToast: (s, m) => dispatch(toggleToast(s, m)),
    toggleSpinner: () => dispatch(toggleSpinner())
  }
}

export default connect(null, mdtp)(GestioneCategoria);
