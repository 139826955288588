import { GET_CATEGORIE, ADD_CATEGORIE } from "../actions/categorie";
import axiosInstance from '../../api/interceptor';

export const getCategorie = () => async (dispatch) => {
  const res = await axiosInstance.get("/categorie/elenco");
  return dispatch({ type: GET_CATEGORIE, payload: res.data })
}

export const createCategorie = (body) => async (dispatch) => {
  const res = await axiosInstance.post("/categorie", body);
  if(res.status === 200) {
    dispatch({ type: ADD_CATEGORIE, payload: res.data })
  }
  // prevedere il messaggio di errore da far vedere all'utente
}

export const updateCategorie = (body, id) => async (dispatch) => {
  const res = await axiosInstance.put(`/categorie/${id}`, body);
  if(res.status === 200) {
    dispatch({ type: ADD_CATEGORIE, payload: res.data })
  }
  // prevedere il messaggio di errore da far vedere all'utente
}