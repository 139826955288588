import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import axiosInstance from "../../api/interceptor";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import Libro from "../../components/Libro/Libro";
import Pagination from "../../components/Pagination/Pagination";
import BackToPrevious from '../../components/BackToPrevious/BackToPrevious';

const actions = {
  setState: 'setState',
  setLibri: 'setLibri',
  setCurrentPage: 'setCurrentPage',
  setTotalItems: 'setTotalItems',
  setTotalPages: 'setTotalPages',
  setItemsPerPage: 'setItemsPerPage',
  setSortField: 'setSortField',
}

const initialState = {
  libri: [],
  libriPerPage: 10,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  sortField: 'nomeLibro',
  sortDirection: 'asc'
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.setState: {
      const {libri, totalItems, totalPages} = action.value;
      return {
        ...state,
        libri,
        totalItems,
        totalPages
      }
    }
    case actions.setItemsPerPage: {
      return {
        ...state,
        libriPerPage:
        action.value,
        currentPage: 0
      }
    }
    case actions.setCurrentPage: {
      return {
        ...state, currentPage: action.value
      }
    }
    case actions.setSortField: {
      return {
        ...state,
        sortField: action.field,
        sortDirection: action.direction
      }
    }
    default:
      return state;
  }
}

const Libri = props => {

  const {toggleToast, toggleSpinner} = props;

  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState)
  const paginate = pageNumber => dispatch({type: actions.setCurrentPage, value: pageNumber});
  const setLibriPerPage = upp => dispatch({type: actions.setItemsPerPage, value: upp});

  const setState = values => {
    const {content, totalElements, totalPages} = values;
    dispatch({
      type: actions.setState,
      value: {
        libri: content,
        totalItems: totalElements,
        totalPages: totalPages,
      }
    })
  }

  const getSortParams = () => {
    let params = `/libri/pubblicati`;
    const {
      libriPerPage, currentPage, sortField, sortDirection
    } = state;
    params += `?size=${libriPerPage}&page=${currentPage}&sort=${sortField},${sortDirection}`;
    return params;
  }

  const getLibri = async () => {
    toggleSpinner();
    await axiosInstance.get(getSortParams())
      .then(r => {
        if (r && r.status === 200) setState(r.data);
      })
      .catch((e) => e && e.message ?
        toggleToast('e', e.message) : console.log(e))
      .finally(() => toggleSpinner())
  }

  React.useEffect(() => {
    getLibri()
  }, [ state.libriPerPage, state.currentPage, state.sortField, state.sortDirection]) // eslint-disable-line

  return (
    <div id="tutti-libri" className="container">
      <BackToPrevious goBack ={() => props.history.goBack()} />
      <div id="libri-container">
        <div className="row">
          <div className="title col-md-6">
            <i className="fas fa-th" />
            tutti i libri
          </div>
        </div>
      </div>

      {
        state.libri && state.libri.length > 0 ?
          <>
            <div className="libri-container">
              {React.Children.toArray(state.libri.map(l => <Libro {...l}/>))}
            </div>
            <Pagination
              itemsPerPage={state.libriPerPage}
              totalItems={state.totalItems}
              currentPage={state.currentPage}
              totalPages={state.totalPages}
              setItemsPerPage={setLibriPerPage}
              paginate={paginate}
            />
          </>
          :
          <div className="nessuna-pubblicazione justify-content-center align-items-center color-main-color">
            Nessun libro pubblicato
          </div>
      }
    </div>
  );
};

Libri.propTypes = {
  toggleSpinner: PropTypes.func.isRequired,
  toggleToast: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  toggleToast: (s, m) => dispatch(toggleToast(s, m)),
  toggleSpinner: () => dispatch(toggleSpinner())
})

export default connect(null, mapDispatchToProps)(Libri);
