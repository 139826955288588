import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../../api/interceptor';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Anteprima from "../../../components/Pdf/Anteprima";
import Template from "../../../components/Pdf/Template";
import { PDFViewer } from "@react-pdf/renderer";
import layout1 from '../../../assets/img/pdfLayout1.png';
import layout2 from '../../../assets/img/pdfLayout2.png';
import FontawesomeGallery from "../../../components/FontawesomeGallery/FontawesomeGallery";
import Swal from "sweetalert2";

function FormCategoria(props) {
  FormCategoria.propTypes = {
    selectedCategoria: PropTypes.shape({}),
    removeSelectedCategoria: PropTypes.func.isRequired,
    toggleForm: PropTypes.func.isRequired,
    updateTabella: PropTypes.func.isRequired
  }

  const {
    selectedCategoria,
    toggleForm,
    updateTabella,
    toggleSpinner,
    toggleToast,
    categorie
  } = props;

  // Inizializzazione dei campi della form in base a CREA o MODIFICA categoria
  const [file, setFile] = useState(null);
  const [nomeCategoria, setnomeCategoria] = useState(selectedCategoria ? selectedCategoria.nomeCategoria : '');
  const [categoriaMadreArray, setCategoriaMadreArray] = useState([]);
  const [categoriaMadreId, setCategoriaMadreId] = useState(selectedCategoria ? selectedCategoria.idCategoriaMadre : '');
  const [hasCategoriaMadre, setHasCategoriaMadre] = useState(!selectedCategoria ? false : selectedCategoria.secondLevelCategory);
  const [openFA, setOpenFA] = useState(false);
  const [faIcon, setFaIcon] = useState(selectedCategoria ? selectedCategoria.icona : '');
  const [palette, setPalette] = useState(null);
  const [selectedPalette, setSelectedPalette] = useState(selectedCategoria ? selectedCategoria.paletteRes : null);
  const [selectedLayout, setSelectedLayout] = useState(selectedCategoria ? selectedCategoria.layoutCategoria : 'UNA_COLONNA');
  const [boxArray, setBoxArray] = useState(selectedCategoria ? (selectedCategoria.boxTesto.map(el => ({ idBoxTesto: el.idBoxTesto, titoloBoxTesto: el.titoloBoxTesto, posizione: el.posizione }))) : []);
  const [boxTitle, setBoxTitle] = useState('');

  // Validators dei campi obbligatori
  const [titoloValidation, setTitoloValidation] = useState({ isValid: false, errorMsg: 'Campo obbligatorio' });
  const [iconaValidation, setIconaValidation] = useState({ isValid: false, errorMsg: 'Seleziona una icona' });
  const [paletteValidation, setPaletteValidation] = useState({ isValid: false, errorMsg: 'Seleziona una palette di colori' });
  const [boxValidation, setBoxValidation] = useState({ isValid: false, errorMsg: 'Compila almeno 1 box' });
  const [formSubmitted, setformSubmitted] = useState(false);

  // Al primo loading del componente fetchiamo la palette dei colori e estrapoliamo le categorie madre
  useEffect(() => {
    getPalette();
    getArrayCategoriaMadre();
  }, []); // eslint-disable-line

  // Verifica del valore di categoriaMadre per adeguare i campi della form che saranno visibili
  useEffect(() => {
    !categoriaMadreId || categoriaMadreId === '' ? setHasCategoriaMadre(false) : setHasCategoriaMadre(true)
  }, [categoriaMadreId])

  useEffect(() => {
    if (selectedCategoria && selectedCategoria.idCategoriaMadre && !hasCategoriaMadre) {
      setPalette(palette.filter(p => !p.categoriaAssociata));
      setFaIcon('');
      setBoxArray([]);
    }
  }, [selectedCategoria, hasCategoriaMadre]) // eslint-disable-line

  // Controllo di validazione della form
  useEffect(() => {
    checkValidation();
  }, [nomeCategoria, selectedPalette, faIcon, boxArray]); // eslint-disable-line

  function checkValidation() {
    !nomeCategoria ? setTitoloValidation({ ...titoloValidation, isValid: false }) : setTitoloValidation({ ...titoloValidation, isValid: true });

    // Verifica dei seguenti campi solo se non c'è una categoria madre
    if (!hasCategoriaMadre) {
      !faIcon ? setIconaValidation({ ...iconaValidation, isValid: false }) : setIconaValidation({ ...iconaValidation, isValid: true });
      !selectedPalette ? setPaletteValidation({ ...paletteValidation, isValid: false }) : setPaletteValidation({ ...paletteValidation, isValid: true });
      (boxArray.length === 0 || boxArray.some(el => !el.titoloBoxTesto)) ? setBoxValidation({ ...boxValidation, isValid: false }) : setBoxValidation({ ...boxValidation, isValid: true });
    }
  }

  // Messaggio di alert se la validazione non va a buon fine
  function warning() {
    Swal.fire({
      title: "Attenzione!",
      text: 'Si prega di compilare tutti i campi obbligatori',
      showCloseButton: true,
      focusConfirm: true
    })
  }

  // Fetch della palette dei colori dal DB
  async function getPalette() {
    const res = await axiosInstance.get("/palette");
    setPalette(res.data)
  }

  // Metodo per disabilitare le palette già utilizzate
  function disablePalette(p) {
    if (selectedCategoria) { // Se sono in modifica non devo disabilitare la palette associata
      return p.categoriaAssociata && (p.id !== selectedCategoria.paletteRes.id) ? 'disable-palette' : null
    } else {
      return p.categoriaAssociata ? 'disable-palette' : null
    }
  }

  // Crea una categoria
  async function createCategoria(body) {
    toggleSpinner();
    await axiosInstance.post("/categorie", body)
      .then(r => {
        if (r && r.status === 200) {
          updateTabella();
          toggleToast('s', "Categoria creata con successo!");
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => {
        toggleForm();
        toggleSpinner()
      })
  }

  // Modifica una categoria
  async function updateCategoria(body, id) {
    toggleSpinner();
    await axiosInstance.put(`/categorie/${id}`, body)
      .then(r => {
        if (r && r.status === 200) {
          updateTabella();
          toggleToast('s', "Categoria modificata con successo!");
        }
      })
      .catch(e => toggleToast('e', e))
      .finally(() => {
        toggleForm();
        toggleSpinner()
      })
  }

  // Submit della form
  function handleSubmit() {
    let body = {};

    // Flag che serve per visualizzare i messagi di form invalid ma solo dopo una prima submission
    if (!formSubmitted) setformSubmitted(true);

    if (!hasCategoriaMadre) { // Creazione o modifica di una categoria madre
      body = {
        nomeCategoria,
        categoriaMadreId: null,
        paletteId: selectedPalette ? selectedPalette.id : null,
        icona: !faIcon.name ? faIcon : faIcon.name,
        tipoLayout: selectedLayout,
        boxTestoCategoria: boxArray
      };
      if (titoloValidation.isValid && iconaValidation.isValid && paletteValidation.isValid && boxValidation.isValid) {
        // Chiamata post o put
        !selectedCategoria ? createCategoria(body) : updateCategoria(body, selectedCategoria.id)
      } else warning()
    } else { // Creazione o modifica di una categoria figlia
      body = {
        nomeCategoria,
        categoriaMadreId
      };
      if (titoloValidation.isValid) {
        // Chiamata post o put
        !selectedCategoria ? createCategoria(body) : updateCategoria(body, selectedCategoria.id)
      } else warning()
    }
  }

  function getArrayCategoriaMadre() {
    let temp = categorie.filter(c => !c.secondLevelCategory);
    if (!selectedCategoria || (selectedCategoria && !selectedCategoria.secondLevelCategory)) {
      temp.unshift({ id: '', nomeCategoria: 'Nessuna categoria madre' }); // inserimento dell'option di default
    }
    setCategoriaMadreArray(temp)
  }

  function refreshFile(blob) {
    setFile(blob)
  }

  const addBox = () => {
    if (boxTitle !== '') {
      setBoxArray(arr => [...arr, { titoloBoxTesto: boxTitle, posizione: arr.length + 1 }]);
      setBoxTitle('');
    }
  };

  const removeBox = idx => {
    let temp = boxArray.filter((a, i) => i !== idx);
    setBoxArray(temp.map((a, i) => ({ ...a, posizione: i + 1 })));
  };

  const modifyBoxTitle = (e, id) => {
    setBoxArray(boxArray.map(el => el.idBoxTesto === id ? { ...el, titoloBoxTesto: e.target.value } : el))
  }

  const changeBoxPosition = (idx, num) => {
    let temp = boxArray[idx];
    boxArray[idx] = boxArray[idx + num];
    boxArray[idx + num] = temp;
    setBoxArray(boxArray.map((a, i) => ({ ...a, posizione: i + 1 })));
  }

  const toggleModal = () => setOpenFA(show => !show);

  const toggleIcon = icon => setFaIcon(icon)

  return (
    <>
      <div className="title-gestione-categoria">
        <div className="title">
          <i className="fas fa-th" />
          {!selectedCategoria ? 'crea' : 'modifica'}  categoria
        </div>
      </div>
      <div className="row margin-top-30">
        <div className="col-md-7" id="configuratore">
          <div id="scelta-categoria" className="margin-top-30">
            <div className="row">
              <div className="col-md-6">

                <h6 className="color-main-color">Titolo categoria</h6>
                <input type="text" className={`form-control form-control-sm ${!titoloValidation.isValid && formSubmitted ? "is-invalid" : null}`}
                  name="nomeCategoria" value={nomeCategoria} onChange={e => setnomeCategoria(e.target.value)} />
                <div className="invalid-feedback">{!titoloValidation.isValid && formSubmitted ? titoloValidation.errorMsg : null}</div>
              </div>

              <div className={`col-md-6 color-main-color ${selectedCategoria && !selectedCategoria.secondLevelCategory && !hasCategoriaMadre ? 'd-none' : null}`}>
                <h6 htmlFor="">Sottocategoria di</h6>
                <select className="form-control form-control-sm"
                  value={!categoriaMadreId ? '' : categoriaMadreId}
                  onChange={e => { setCategoriaMadreId(e.target.value) }}
                  disabled={selectedCategoria && selectedCategoria.categoriaConArticoli && hasCategoriaMadre}>
                  {React.Children.toArray(categoriaMadreArray.map(c => <option value={c.id}>{c.nomeCategoria}</option>))}
                </select>
              </div>
            </div>
          </div>

          { /* Selezionando una categoria madre non si vedono i campi icona/palette/box in quanto ereditati dalla categoria madre */
            hasCategoriaMadre

              ? <div className="font-weight-bold text-secondary text-center margin-top-35">
                L'icona, i colori ed i box vengono ereditati dalla categoria madre
              </div>

              : (<>
                <div>
                  <h6 className={`color-main-color w-100 margin-top-30 ${!iconaValidation.isValid && formSubmitted ? "form-control form-control-sm is-invalid" : null}`}>Icona</h6>
                  <div className="invalid-feedback mt-0 mb-3">{!iconaValidation.isValid && formSubmitted ? iconaValidation.errorMsg : null}</div>
                </div>
                <div id="scelta-icona" className="margin-top-10">
                  <button className="btn btn-main-color btn-sm" onClick={toggleModal}>Scegli icona</button>
                  {faIcon &&
                    <>
                      <i className={`fa-2x fas fa-${!faIcon.name ? faIcon : faIcon.name} color-main-color margin-left-15 margin-right-15`} />
                      <span className="color-main-text"><i>Usa questa icona</i></span>
                    </>}
                  {openFA && <FontawesomeGallery
                    selectIcon={toggleIcon}
                    toggle={toggleModal} />}
                </div>

                <div id="palette-container" className="margin-top-30">
                  <h6 className={`color-main-color w-100 ${!paletteValidation.isValid && formSubmitted ? "form-control form-control-sm is-invalid" : null}`}>Palette colore</h6>
                  <div className="invalid-feedback mt-0 mb-3">{!paletteValidation.isValid && formSubmitted ? paletteValidation.errorMsg : null}</div>
                  {!palette ? <LoadingSpinner /> :
                    React.Children.toArray(palette.map(p => (
                      <div className={`palette mt-2 mr-1 ${disablePalette(p)} ${selectedPalette && p.id === selectedPalette.id ? 'highlight-selected' : null}`}
                        style={{ height: '72px', width: '72px' }} onClick={() => setSelectedPalette(p)}>
                        <div className="colori">
                          {
                            React.Children.toArray(p.colori.map(c => <div className="colore" style={{ backgroundColor: c }} />))
                          }
                        </div>
                        <div className="label">
                          {`# ${p.id}`}
                        </div>
                      </div>
                    )))}
                </div>
                <div className="margin-top-15"><i>Possono essere selezionate solo palette che non sono già in uso per altre categorie</i></div>

                <div id="layout-container" className="margin-top-30">
                  <h6 className="color-main-color w-100">Layout</h6>
                  <div className="layout-wrapper ml-1">
                    <img onClick={() => setSelectedLayout('UNA_COLONNA')} src={layout1} alt="Layout a una colonna"
                      className={`${selectedLayout === 'UNA_COLONNA' ? 'highlight-selected' : null}`} />
                    <p><i>1 colonna</i></p>
                  </div>
                  <div className="layout-wrapper">
                    <img onClick={() => setSelectedLayout('DUE_COLONNE')} src={layout2} alt="Layout a due colonne"
                      className={`${selectedLayout === 'DUE_COLONNE' ? 'highlight-selected' : null}`} />
                    <p><i>2 colonne</i></p>
                  </div>
                </div>

                <div id="box-container" className="row margin-top-30">
                  <div className="col-md-10">
                    <p className={`mb-0 ${!boxValidation.isValid && formSubmitted ? "form-control form-control-sm is-invalid" : null}`}>
                      <i>Specifica il numero ed il titolo dei box di testo appartenenti alla categoria</i></p>
                    <small className="text-danger mt-0">{!boxValidation.isValid && formSubmitted ? boxValidation.errorMsg : null}</small>
                  </div>

                  <div className={`col-md-10 mt-3 mb-0 ${selectedCategoria && selectedCategoria.categoriaConArticoli ? 'd-flex' : 'd-none'}`}>
                    <div><i className="fas fa-exclamation-triangle text-danger mr-2" /></div>
                    <div>
                      <small className="text-success font-weight-bold font-italic">
                        Essendo questa categoria associata ad un articolo si possono modificare <br /> le posizioni dei box preesistenti <u>solo tramite le frecce</u>
                      </small>
                    </div>
                  </div>

                  <div className="col-md-8 margin-top-15">
                    {
                      React.Children.toArray(boxArray.map((b, i) => (
                        <div className="w-100 padding-right-15 margin-top-15 box">
                          <h6 className="color-main-color">{`Titolo box ${i + 1}/${boxArray.length}`}
                            {!(selectedCategoria && selectedCategoria.categoriaConArticoli) ? null :
                              (<div className="float-right">
                                <small className="color-main-color font-italic font-weight-bold mr-2">Sposta il box</small>
                                <i className={`fas fa-long-arrow-alt-up cursor-pointer ml-1 mr-1 ${i === 0 ? 'd-none' : ''}`}
                                  onClick={() => changeBoxPosition(i, -1)} />

                                <i className={`fas fa-long-arrow-alt-down cursor-pointer mr-1 ${i === boxArray.length - 1 ? 'd-none' : ''}`}
                                  onClick={() => changeBoxPosition(i, 1)} />
                              </div>)
                            }
                          </h6>
                          <input type="text" className="form-control form-control-sm" value={b.titoloBoxTesto}
                            readOnly={!selectedCategoria} onChange={e => modifyBoxTitle(e, b.idBoxTesto)} />
                          <i className={`fas fa-times color-rosso ${selectedCategoria && selectedCategoria.categoriaConArticoli && b.idBoxTesto ? 'd-none' : null}`}
                            onClick={() => removeBox(i)} />
                        </div>
                      )))
                    }

                    <div className="w-100 padding-right-15 margin-top-15 box">
                      <span onClick={addBox}><i className="fas fa-plus" /> Aggiungi</span>
                      <h6 className="color-main-color">Titolo box</h6>
                      <input type="text"
                        className="form-control form-control-sm"
                        onChange={e => setBoxTitle(e.target.value)}
                        value={boxTitle} />
                    </div>

                  </div>
                </div>
              </>)
          }

          <div className={`row margin-top-35 ${hasCategoriaMadre ? 'justify-content-center' : null}`}>
            <div className="col-md-4">
              <div className="btn outline-azure btn-sm btn-block text-uppercase mr-3" onClick={toggleForm}>annulla</div>
            </div>
            <div className="col-md-4">
              <div className="btn btn-azure btn-sm btn-block text-uppercase" onClick={handleSubmit}>{!selectedCategoria ? 'crea' : 'modifica'} categoria</div>
            </div>
          </div>
        </div>

        <div id="anteprima" className="col-md-5">
          <PDFViewer style={{ display: 'none' }}>
            <Template refreshFile={refreshFile}
              image1={'image1'}
              layout={selectedLayout}
              palette={selectedPalette}
              testo={'testo'} />
          </PDFViewer>
          <Anteprima file={file} />
        </div>
      </div>
    </>
  );
}

export default FormCategoria;
